import React, { useEffect, useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import {
  Evidence,
  GetLatestEvidenceRequest,
  LatestEvidence,
  ListEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { ExpandedEvidenceActionsRow } from 'src/pages/Controls/ControlsShowPage/EvidenceGrid/evidence.components'
import { useEvidenceResults } from '../../async/suggestions/useEvidenceResults'
import { useLatestEvidenceResults } from '../../async/suggestions/useLatestEvidenceResults'
import { EvidenceGridHeader } from '../../../pages/Controls/ControlsShowPage/EvidenceGrid/EvidenceGridHeader'
import { Grid, GridRow } from '../Grid'
import { AccordionGridBody } from '../Grid/Accordion'
import { evidenceAsRowItemElement } from '../../../pages/Controls/ControlsShowPage/EvidenceGrid/evidence'
import { RowItemExpandableElement } from '../Grid/Accordion/RowItemProp'
import { NoEvidence } from '../../../pages/Controls/ControlsShowPage/EvidenceGrid/NoEvidence'

const evidenceTabGridTemplate =
  'min-content auto min-content repeat(2, 12em) repeat(2, min-content)'

interface EvidenceTabProps {
  modelId?: string
  controlId?: string
  requestId?: string
  isDismissed: boolean
  gridTemplateColumns?: string
  showLatest: boolean
  setEvidenceCount: React.Dispatch<React.SetStateAction<number>>
  timeRange?: TimeRange
}

// TODO: Clean up here. This is rerendering a TON!
const ExpandedItems = ({
  modelId,
  isDismissed,
  request,
  timeRange,
  gridRow,
  requestId,
  controlId,
  total,
}: {
  request: ListEvidenceRequest
  timeRange?: TimeRange
  modelId: string
  requestId: string
  isDismissed: boolean
  gridRow: number
  controlId: string
  total: number
}): JSX.Element => {
  const response = useEvidenceResults(request).data
  return (
    <>
      {response
        ?.getItemsList()
        .slice(1)
        .map((elem, idx) => (
          <GridRow key={`${idx}`}>
            {evidenceAsRowItemElement(
              elem,
              isDismissed,
              controlId,
              modelId,
              timeRange,
              requestId,
              total,
            ).RowItem({
              gridRow: gridRow ? gridRow + idx : undefined,
            })}
          </GridRow>
        ))}
    </>
  )
}

const latestEvidenceRowItems = (
  latestEvidence: LatestEvidence[],
  props: EvidenceTabProps,
  timeRange?: TimeRange,
): RowItemExpandableElement[] => {
  const modelId = props.modelId || ''
  const controlId = props.controlId || ''
  const requestId = props.requestId || ''

  return latestEvidence
    .filter((item) => item.getEvidence())
    .map((item) => {
      const evidence = item.getEvidence() as Evidence
      const request = new ListEvidenceRequest()
        .setControlId(controlId)
        .setTimeRange(timeRange)
        .setDocumentRequestId(requestId)
        .setCaption(new StringValue().setValue(evidence.getCaption()))

      return {
        ...evidenceAsRowItemElement(
          evidence,
          props.isDismissed,
          controlId,
          modelId,
          timeRange,
          requestId,
          item.getTotal(),
        ),
        total: item.getTotal(),
        expandedItems: {
          RowItem: ({ gridRow }: { gridRow: number }) => (
            <ExpandedItems
              {...props}
              modelId={modelId}
              requestId={requestId}
              timeRange={timeRange}
              request={request}
              gridRow={gridRow}
              controlId={controlId}
              total={item.getTotal()}
            />
          ),
        },
        actionsRow: (
          <ExpandedEvidenceActionsRow
            controlId={controlId}
            caption={evidence.getCaption()}
            totalEvidenceCount={item.getTotal()}
          />
        ),
      } as RowItemExpandableElement
    })
}

export const EvidenceTab = (props: EvidenceTabProps): JSX.Element => {
  const { setEvidenceCount, timeRange, showLatest } = props
  // TODO: Update this to require either control id or request id
  const requestId = props.requestId || ''
  const modelId = props.modelId || ''
  const controlId = props.controlId || ''
  const getLatestEvidenceRequest = useMemo(
    () =>
      new GetLatestEvidenceRequest()
        .setControlId(controlId)
        .setTimeRange(timeRange)
        .setDocumentRequestId(requestId),
    [controlId, requestId, timeRange],
  )
  const listEvidenceRequest = useMemo(
    () =>
      new ListEvidenceRequest()
        .setControlId(controlId)
        .setDocumentRequestId(requestId)
        .setTimeRange(timeRange),
    [controlId, requestId, timeRange],
  )

  const { data: latestData, isLoading: isLatestLoading } =
    useLatestEvidenceResults(getLatestEvidenceRequest)
  const latestEvidenceItems =
    latestData?.getItemsList() || ([] as LatestEvidence[])

  const { data, isLoading } = useEvidenceResults(listEvidenceRequest)
  const evidenceItems = data?.getItemsList() || ([] as Evidence[])

  useEffect(() => {
    setEvidenceCount(
      showLatest ? latestEvidenceItems.length : evidenceItems.length,
    )
  }, [
    evidenceItems.length,
    latestEvidenceItems.length,
    setEvidenceCount,
    showLatest,
  ])

  return isLoading || isLatestLoading ? (
    <ContentLoader />
  ) : (
    <Grid
      gridTemplateColumns={evidenceTabGridTemplate}
      style={{ cursor: 'pointer' }}
    >
      <EvidenceGridHeader />
      {showLatest ? (
        <AccordionGridBody
          gridTemplateColumns={evidenceTabGridTemplate}
          items={latestEvidenceRowItems(latestEvidenceItems, props, timeRange)}
          nothingToShow={
            <GridRow>
              <NoEvidence isDismissed={props.isDismissed} />
            </GridRow>
          }
        />
      ) : (
        <AccordionGridBody
          items={
            evidenceItems.map((evidence: Evidence) =>
              evidenceAsRowItemElement(
                evidence,
                props.isDismissed,
                controlId,
                modelId,
                timeRange,
                requestId,
              ),
            ) as RowItemExpandableElement[]
          }
          nothingToShow={
            <GridRow>
              <NoEvidence isDismissed={props.isDismissed} />
            </GridRow>
          }
        />
      )}
    </Grid>
  )
}
