import styled from 'styled-components/macro'
import palette from 'src/designSystem/variables/palette'
import { RouteTitle } from './BaseComponents'

export const ShowPageHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`

export const ShowPageRouteTitle = styled(RouteTitle)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0;
  width: 100%;
`

export const ShowPageHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

export const AIShowPageDividerLine = styled.rect`
  height: 6px;
  width: 100%;
  border-radius: 6px;
  background: linear-gradient(
    270deg,
    ${palette.purple[200]} 0%,
    rgba(180, 161, 231, 0) 100%
  );
  margin: 8px 0;
`

export const ShowPageHeaderNavLinks = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-right: 12px;
`

export const PrevNextContainer = styled.div`
  display: flex;
`

export const ShowPageHeaderTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const StyledSectionHeader = styled.h6`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  align-items: center;
`

export const StyledSectionContainer = styled.div`
  padding-bottom: 12px;
  width: 100%;
`

export const NotApplicableImage = styled.img`
  width: 100%;
`
export const NotApplicableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const NotApplicableBoldText = styled.span`
  font-weight: 600;
`
