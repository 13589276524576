import { useCallback } from 'react'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import {
  ListPoliciesRequest,
  PolicyRecord,
  PolicyRecords,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { useInAudit } from 'src/context/AuditContext'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { useGrpcRevalidateByMethod } from '../index'
import { mutateControlDependencies } from '../model'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useListMember } from '../hooks/useListMember'

/**
 *
 * @param id rowId
 * @param shouldFetch
 */
export const usePolicy = (
  id: string,
  shouldFetch = true,
  request = new ListPoliciesRequest(),
): GrpcResponse<PolicyRecord> => {
  const { auditId } = useInAudit()
  if (auditId) {
    request.setAuditId(new StringValue().setValue(auditId))
  }

  const response = useListMember(
    {
      id,
      asyncCall: PolicyPromiseClient.prototype.list,
      request,
      newCollection: () => new PolicyRecords(),
    },
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useInvalidatePolicyCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()
  return useCallback(async () => {
    await Promise.all([
      mutateFunc(PolicyPromiseClient.prototype.list),
      mutateFunc(PolicyPromiseClient.prototype.listIds),
      mutateFunc(PolicyPromiseClient.prototype.getControls),
      mutateFunc(PolicyPromiseClient.prototype.find),
      mutateControlDependencies(mutateFunc),
      mutateFunc(ModelPromiseClient.prototype.listControls),
    ])
  }, [mutateFunc])
}
