import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { AUDIT_RESULT } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'

export type ControlsListItemProps = {
  controlModelId: string
  audit?: AuditRecord
  config?: ControlListItemConfig
}

export enum ControlListItemConfig {
  DEFAULT = 'default',
  AI_INDEX = 'ai-index',
  AI_DASHBOARD = 'ai-dashboard',
  AI_INDEX_WITH_STALE = 'ai-index-with-stale',
}

export type ControlListItemConfigMap = {
  showDepartmentColumn: boolean
  showModelIdColumn: boolean
  showControlNameColumn: boolean
  showFrameworkColumn: boolean
  showCheckColumns: boolean
  showEvidenceColumns: boolean
  showStatusColumn: boolean
  showAssigneeColumn: boolean
  showMenuColumn: boolean
  template: string
  $isPurple: boolean
  showScanStaleButton?: boolean
}

export const ControlListItemConfigurations: Record<
  ControlListItemConfig,
  ControlListItemConfigMap
> = {
  [ControlListItemConfig.DEFAULT]: {
    showDepartmentColumn: true,
    showModelIdColumn: true,
    showControlNameColumn: true,
    showFrameworkColumn: true,
    showCheckColumns: false,
    showEvidenceColumns: true,
    showStatusColumn: true,
    showAssigneeColumn: true,
    showMenuColumn: true,
    template: 'min-content min-content minmax(auto, 30%) repeat(5, auto)',
    $isPurple: false,
  },
  [ControlListItemConfig.AI_INDEX]: {
    showDepartmentColumn: true,
    showModelIdColumn: true,
    showControlNameColumn: true,
    showFrameworkColumn: true,
    showCheckColumns: true,
    showEvidenceColumns: false,
    showStatusColumn: false,
    showAssigneeColumn: true,
    showMenuColumn: true,
    template: 'min-content min-content 1fr repeat(6, auto)',
    $isPurple: true,
  },
  [ControlListItemConfig.AI_INDEX_WITH_STALE]: {
    showDepartmentColumn: true,
    showModelIdColumn: true,
    showControlNameColumn: true,
    showFrameworkColumn: true,
    showCheckColumns: true,
    showEvidenceColumns: false,
    showStatusColumn: false,
    showAssigneeColumn: true,
    showMenuColumn: true,
    template: 'min-content min-content 1fr repeat(6, auto)',
    $isPurple: true,
    showScanStaleButton: true,
  },
  [ControlListItemConfig.AI_DASHBOARD]: {
    showDepartmentColumn: true,
    showModelIdColumn: true,
    showControlNameColumn: true,
    showFrameworkColumn: false,
    showCheckColumns: true,
    showEvidenceColumns: false,
    showStatusColumn: false,
    showAssigneeColumn: false,
    showMenuColumn: false,
    template: 'min-content min-content 1fr repeat(3, auto)',
    $isPurple: false,
  },
}

export const CONTROL_CHECK_TOOLTIP_TEXT: Record<AUDIT_RESULT, string> = {
  [AUDIT_RESULT.PASS]: 'Result: Pass',
  [AUDIT_RESULT.FAIL]: 'Result: Had issues',
  [AUDIT_RESULT.NOT_RUN]: 'Not Scanned',
  [AUDIT_RESULT.NOT_APPLICABLE]: 'Not Applicable',
  [AUDIT_RESULT.LAMBDA_ERROR]: 'Could not be evaluated',
  [AUDIT_RESULT.UNKNOWN]: 'Could not be evaluated',
  [AUDIT_RESULT.MISSING_SUGGESTIONS]:
    'Missing required evidence or test prodedures',
}

export type ControlCheckConfigType = {
  tooltipId: string
  tooltipBody: JSX.Element | string
  icon: JSX.Element
  isStale: boolean
}
