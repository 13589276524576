import React from 'react'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components/macro'
import { useDeleteEvidence } from 'src/components/ModalForms/Evidence/useDeleteEvidence'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { useHasEditPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { GridColumn } from '../../../../components/Reusable/Grid'
import { Tooltip } from '../../../../components/Reusable/Tooltip'
import { Gravatar } from '../../../../components/Gravatar'
import { dateFormatter } from '../../../../Utils/formatDate'
import {
  IconButton,
  IconButtonVariant,
} from '../../../../components/Reusable/Buttons'
import { ReactComponent as DeleteIcon } from '../../../../components/Icons/assets/delete-icon.svg'
import { useFileTypeIconForEvidence } from '../../../../components/ModalForms/FileType/useFileTypeIcon'
import { useViewEvidence } from '../../../../components/ModalForms/Evidence/ViewEvidenceForm'

export type EvidenceRowProps = {
  gridRow?: number
  controlId?: string
  timeRange?: TimeRange
  evidenceId: string
  contentId: string
  mime: string
  caption: string
  createdAt: Date
  actorName?: string
  actorEmail: string
  isDeleteDisabled: boolean
  isAutomated: boolean
  leftMargin?: string
  relevantDate?: Timestamp
  isViaRequest?: boolean
  isControlShowPage?: boolean
  columnType: 'button' | 'a'
  requestId?: string
  controlOID: string
  total?: number
}

const EvidenceRowIcon = styled.p`
  margin-left: ${themeGet('space.m')}px;
`

export const EvidenceRow = (props: EvidenceRowProps): JSX.Element => {
  const { evidenceId, requestId, caption, controlOID, total } = props
  const deleteFunc = useDeleteEvidence({
    evidenceId,
    requestId,
    caption,
    controlId: controlOID,
    total,
  })
  const hasEditPermissions = useHasEditPermissions()
  const viewEvidence = useViewEvidence(props.evidenceId)
  const FileTypeIcon = useFileTypeIconForEvidence(
    props.isAutomated,
    props.actorEmail,
    props.mime,
  )
  const isViaRequest = props.isControlShowPage && props.isViaRequest
  const colCaption =
    formatEvidenceCaption(props.caption, props.isAutomated) +
    (isViaRequest ? ' - via Request' : '')
  const dataID = `evidenceDelete_${props.evidenceId}`

  const handleRowClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    viewEvidence(e)
  }

  return (
    <>
      <GridColumn
        gridRow={props.gridRow}
        {...(props.leftMargin ? { ml: props.leftMargin } : {})}
        px="0"
        pl="22px"
        pr="m"
        onClick={handleRowClick}
      >
        <EvidenceRowIcon>
          <FileTypeIcon width="24px" height="24px" />
        </EvidenceRowIcon>
      </GridColumn>
      <GridColumn gridRow={props.gridRow} onClick={handleRowClick}>
        {colCaption}
      </GridColumn>
      <Tooltip
        id={`${props.evidenceId}-${props.actorEmail}`}
        placement="top-end"
        tooltipBody={props.actorName ?? props.actorEmail}
      >
        <GridColumn
          gridRow={props.gridRow}
          justifyContent="center"
          onClick={handleRowClick}
        >
          <Gravatar
            email={props.actorEmail}
            emptyIsUnassigned={false}
            magicIcon
          />
        </GridColumn>
      </Tooltip>
      <GridColumn gridRow={props.gridRow} onClick={handleRowClick}>
        {dateFormatter(props?.relevantDate?.toDate(), false)}
      </GridColumn>
      <GridColumn gridRow={props.gridRow} onClick={handleRowClick}>
        {dateFormatter(props.createdAt)}
      </GridColumn>
      <GridColumn
        gridRow={props.gridRow}
        style={{ paddingLeft: 0, cursor: 'pointer' }}
      >
        {!isViaRequest && (
          <IconButton
            disabled={props.isDeleteDisabled || !hasEditPermissions}
            variant={IconButtonVariant.icon}
            onClick={deleteFunc}
            data-id={dataID}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </GridColumn>
    </>
  )
}
