import React from 'react'
import queryString from 'query-string'
import { RiskStatusLabels } from 'src/pages/Risks'
import {
  COMPANY_INFO_CHECKS,
  CONTROL_CHECKS,
  CompanyInfoCheck,
  CompanyInfoWidgetRequestConfig,
  ControlCheck,
  ControlWidgetRequestConfig,
  FRAMEWORK_CHECKS,
  FrameworkCheck,
  FrameworkWidgetRequestConfig,
  GetRoadmapWidgetCountsRequest,
  GetRoadmapWidgetCountsResponse,
  POLICY_CHECKS,
  PolicyCheck,
  PolicyWidgetRequestConfig,
  REQUEST_CHECKS,
  RISK_CHECKS,
  RequestCheck,
  RequestWidgetRequestConfig,
  RiskCheck,
  RiskWidgetRequestConfig,
  SERVICE_CHECKS,
  SERVICE_ROLE_CHECKS,
  ServiceCheck,
  ServiceRoleCheck,
  ServiceRoleWidgetRequestConfig,
  ServiceWidgetRequestConfig,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import {
  ControlsAbsoluteRoutes,
  DirectRoutes,
  PoliciesAbsoluteRoutes,
  RisksAbsoluteRoutes,
  ServicesAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { RequestsAbsoluteRoutes } from 'src/pages/Requests'
import {
  GridColumnSortControls,
  GridColumnSortParams,
  GridColumnSortPolicies,
  GridColumnSortRequests,
  GridColumnSortType,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import {
  FilterParam,
  getFilterValuesByFrameworkModelId,
} from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { ControlStatus } from 'src/lib/common/types'
import { NONE_ID } from 'src/Utils/globalConstants'
import { Assignee, FRAMEWORK_MODEL_IDS } from 'src/Utils/globalEnums'
import {
  DocumentationFilterValues,
  RiskFilterValues,
} from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.constants'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { DocumentRequestStatusLabels } from 'src/pages/Requests/requests.constants'
import { ModalFormId } from 'src/components/ModalForms'
import { isFullySupportedFramework } from 'src/Utils/globalHelpers'
import { PERMISSIONS } from 'src/config/roleConfig'
import { FrameworksNotCoveredModal } from '../modals/FrameworksNotCoveredModal'
import { ServiceRolesNotFulfilledModal } from '../modals/ServiceRolesNotFulfilledModal'
import {
  COMPANY_INFO_REQUEST_CONFIG,
  CONTROL_REQUEST_CONFIG,
  FRAMEWORK_REQUEST_CONFIG,
  POLICY_REQUEST_CONFIG,
  RISK_REQUEST_CONFIG,
  REQUEST_REQUEST_CONFIG,
  ROADMAP_CHECK_CONFIG,
  ROADMAP_WIDGET_DATA_TYPE,
  ROADMAP_WIDGET_LOCATION,
  ROADMAP_WIDGET_LOCATION_CONFIG_TYPE,
  ROADMAP_WIDGET_REQUEST_CONFIG,
  ROADMAP_WIDGET_SECTION,
  ROADMAP_WIDGET_SECTION_CHECK,
  SERVICE_REQUEST_CONFIG,
  SERVICE_ROLE_REQUEST_CONFIG,
} from './RoadmapWidgets.constants'
import {
  CompanyInfoCircleIcon,
  ControlCircleIcon,
  FrameworksCircleIcon,
  PolicyCircleIcon,
  RequestsCircleIcon,
  RisksCircleIcon,
  ServicesCircleIcon,
} from './RoadmapWidgets.styles'
import { ServiceRolesWidgetTooltip } from './RoadmapWidgets.components'

export const isPolicyOrCompanyInfo = (
  check: ROADMAP_WIDGET_SECTION_CHECK,
): boolean => {
  return 'getTotalCount' in check
}

export const getShouldUseDocumentedTotal = (
  check: ROADMAP_WIDGET_SECTION_CHECK,
): boolean => {
  const isModifiedCheck =
    check.getCheckType() === POLICY_CHECKS.POLICY_IS_FILLED_IN ||
    check.getCheckType() === COMPANY_INFO_CHECKS.COMPANY_INFO_IS_FILLED_IN
  return isModifiedCheck && isPolicyOrCompanyInfo(check)
}

export const getCorrectTotalFromCheck = (
  check: ROADMAP_WIDGET_SECTION_CHECK,
  total: number,
): number => {
  return getShouldUseDocumentedTotal(check)
    ? (check as unknown as PolicyCheck | CompanyInfoCheck).getTotalCount()
    : total
}

export const getServiceCheckConfig = (
  check: ServiceCheck,
  pageContext: string,
): ROADMAP_CHECK_CONFIG => {
  switch (check.getCheckType()) {
    case SERVICE_CHECKS.SERVICE_SELECTED:
      return {
        text: 'Documented',
        hoverText: 'Documented',
        link: `/${pageContext}/${ServicesAbsoluteRoutes.SERVICES}`,
        useFinishedCount: true,
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getServiceRoleCheckConfig = (
  check: ServiceRoleCheck,
): ROADMAP_CHECK_CONFIG => {
  const unfulfilledRoles = check.getUnfulfilledRolesList()
  switch (check.getCheckType()) {
    case SERVICE_ROLE_CHECKS.ROLE_FULFILLED:
      return {
        text: 'Fulfilled',
        hoverText: 'Not Fulfilled',
        modalId: ModalFormId.ROLES_NOT_FULFILLED,
        modal: <ServiceRolesNotFulfilledModal roles={unfulfilledRoles} />,
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getPolicyCheckConfig = (
  check: PolicyCheck,
  pageContext: string,
  ownerEmail?: string,
): ROADMAP_CHECK_CONFIG => {
  switch (check.getCheckType()) {
    case POLICY_CHECKS.POLICY_HAS_ASSIGNEE:
      return {
        text: 'Assigned',
        hoverText: 'Not Assigned',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${PoliciesAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [Assignee.UNASSIGNED_BE],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case POLICY_CHECKS.POLICY_HAS_LINKED_CONTROLS:
      return {
        text: 'Linked Controls',
        hoverText: 'No Controls',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${PoliciesAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [GridColumnSortParams.SORT_BY]: [GridColumnSortType.ASCENDING],
              [GridColumnSortParams.SORT_COL]: [
                GridColumnSortPolicies.CONTROLS,
              ],
              [FilterParam.OWNER]: [ownerEmail],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case POLICY_CHECKS.POLICY_HAS_DOCUMENTATION:
      return {
        text: 'Documented',
        hoverText: 'No Document',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${PoliciesAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.HAS_DOCUMENTATION]: ['false'],
              [FilterParam.OWNER]: [ownerEmail],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case POLICY_CHECKS.POLICY_IS_FILLED_IN: {
      return {
        text: 'Customized',
        hoverText: 'Not Customized',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${PoliciesAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [ownerEmail],
              [FilterParam.IS_CUSTOMIZED]: [DocumentationFilterValues.FALSE],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getControlCheckConfig = (
  check: ControlCheck,
  pageContext: string,
  frameworkModelIds: string[],
  ownerEmail?: string,
): ROADMAP_CHECK_CONFIG => {
  switch (check.getCheckType()) {
    case CONTROL_CHECKS.CONTROL_HAS_ASSIGNEE:
      return {
        text: 'Assigned',
        hoverText: 'Not Assigned',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${ControlsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [Assignee.UNASSIGNED_BE],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case CONTROL_CHECKS.CONTROL_HAS_EVIDENCE:
      return {
        text: 'Evidence',
        hoverText: 'No Evidence',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${ControlsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [GridColumnSortParams.SORT_BY]: [GridColumnSortType.ASCENDING],
              [GridColumnSortParams.SORT_COL]: [
                GridColumnSortControls.MANUAL_EVIDENCE,
              ],
              [FilterParam.OWNER]: [ownerEmail],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case CONTROL_CHECKS.CONTROL_HAS_FRAMEWORK: {
      const frameworkFilters = frameworkModelIds.reduce(
        (acc: Record<string, string[]>, ele) => {
          if (isFullySupportedFramework(ele as FRAMEWORK_MODEL_IDS)) {
            const filters = getFilterValuesByFrameworkModelId(ele)
            acc[filters.filterParam] = [NONE_ID]
          }
          return acc
        },
        {},
      )

      return {
        text: 'Framework',
        hoverText: 'No Framework',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${ControlsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [ownerEmail],
              ...frameworkFilters,
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    }
    case CONTROL_CHECKS.CONTROL_HAS_PASSING_STATUS:
      return {
        text: 'Ready Status',
        hoverText: 'Not Ready',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${ControlsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.STATUS]: [ControlStatus.NEEDS_ATTENTION],
              [FilterParam.OWNER]: [ownerEmail],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case CONTROL_CHECKS.CONTROL_HAS_POLICY:
      return {
        text: 'Policy',
        hoverText: 'No Policy',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${ControlsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.POLICY]: [NONE_ID],
              [FilterParam.OWNER]: [ownerEmail],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getFrameworkCheckConfig = (
  check: FrameworkCheck,
): ROADMAP_CHECK_CONFIG => {
  const notCoveredFrameworks = check.getNotCoveredFrameworksList()
  switch (check.getCheckType()) {
    case FRAMEWORK_CHECKS.FRAMEWORK_HAS_LINKED_CONTROLS:
      return {
        text: 'Linked Controls',
        hoverText: 'No Controls',
        modalId: ModalFormId.FRAMEWORKS_NOT_COVERED,
        modal: <FrameworksNotCoveredModal frameworks={notCoveredFrameworks} />,
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getCompanyInfoCheckConfig = (
  check: CompanyInfoCheck,
  pageContext: string,
): ROADMAP_CHECK_CONFIG => {
  switch (check.getCheckType()) {
    case COMPANY_INFO_CHECKS.COMPANY_INFO_HAS_DOCUMENTATION:
      return {
        text: 'Documented',
        hoverText: 'Not Documented',
        link: `/${pageContext}/company_info`,
        requiredPermissions: [PERMISSIONS.READ],
      }
    case COMPANY_INFO_CHECKS.COMPANY_INFO_IS_FILLED_IN:
      return {
        text: 'Customized ',
        hoverText: 'Not Customized',
        link: `/${pageContext}/company_info`,
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getRiskCheckConfig = (
  check: RiskCheck,
  pageContext: string,
  ownerEmail?: string,
): ROADMAP_CHECK_CONFIG => {
  switch (check.getCheckType()) {
    case RISK_CHECKS.RISK_HAS_ASSIGNEE:
      return {
        text: 'Assigned',
        hoverText: 'Not Assigned',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RisksAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [Assignee.UNASSIGNED_BE],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case RISK_CHECKS.RISK_INHERENT_RISK_DETERMINED:
      return {
        text: 'Inherent Risk',
        hoverText: 'No Inherent Risk',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RisksAbsoluteRoutes.INDEX}`,
            query: {
              [FilterParam.OWNER]: [ownerEmail],
              [FilterParam.INHERENT_RISK]: [RiskFilterValues.NOT_SET],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case RISK_CHECKS.RISK_RESIDUAL_RISK_DETERMINED:
      return {
        text: 'Residual Risk',
        hoverText: 'No Residual Risk',
        // TODO: When we add sorting to the risks index page, we should link to a sorted view
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RisksAbsoluteRoutes.INDEX}`,
            query: {
              [FilterParam.OWNER]: [ownerEmail],
              [FilterParam.RESIDUAL_RISK]: [RiskFilterValues.NOT_SET],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case RISK_CHECKS.RISK_HAS_PASSING_STATUS:
      return {
        text: 'Ready Status',
        hoverText: 'Not Ready',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RisksAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [ownerEmail],
              [FilterParam.STATUS]: [RiskStatusLabels.TODO],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getRequestCheckConfig = (
  check: RequestCheck,
  pageContext: string,
  ownerId?: string,
): ROADMAP_CHECK_CONFIG => {
  switch (check.getCheckType()) {
    case REQUEST_CHECKS.REQUEST_HAS_ASSIGNEE:
      return {
        text: 'Assigned',
        hoverText: 'Not Assigned',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RequestsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [Assignee.UNASSIGNED_BE],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case REQUEST_CHECKS.REQUEST_HAS_EVIDENCE:
      return {
        text: 'Evidence',
        hoverText: 'No Evidence',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RequestsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [ownerId],
              [GridColumnSortParams.SORT_BY]: [GridColumnSortType.ASCENDING],
              [GridColumnSortParams.SORT_COL]: [
                GridColumnSortRequests.EVIDENCE,
              ],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case REQUEST_CHECKS.REQUEST_HAS_LINKED_CONTROLS:
      return {
        text: 'Linked Controls',
        hoverText: 'No Controls',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RequestsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.OWNER]: [ownerId],
              [GridColumnSortParams.SORT_BY]: [GridColumnSortType.ASCENDING],
              [GridColumnSortParams.SORT_COL]: [
                GridColumnSortRequests.CONTROLS,
              ],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    case REQUEST_CHECKS.REQUEST_HAS_PASSING_STATUS:
      return {
        text: 'Ready Status',
        hoverText: 'Not Ready',
        link: queryString.stringifyUrl(
          {
            url: `/${pageContext}/${RequestsAbsoluteRoutes.INDEX}`,
            query: {
              dismissed: ['false'],
              [FilterParam.STATUS]: [
                DocumentRequestStatusLabels.OPEN,
                DocumentRequestStatusLabels.RETURNED,
              ],
              [FilterParam.OWNER]: [ownerId],
            },
          },
          { arrayFormat: 'bracket' },
        ),
        requiredPermissions: [PERMISSIONS.READ],
      }
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const getSectionCheckConfig = (
  section: ROADMAP_WIDGET_SECTION,
  check: ROADMAP_WIDGET_SECTION_CHECK,
  pageContext: string,
  frameworkModelIds: string[],
  ownerId?: string,
  ownerEmail?: string,
): ROADMAP_CHECK_CONFIG => {
  switch (section) {
    case ROADMAP_WIDGET_SECTION.SERVICE:
      return getServiceCheckConfig(check as ServiceCheck, pageContext)
    case ROADMAP_WIDGET_SECTION.SERVICE_ROLE:
      return getServiceRoleCheckConfig(check as ServiceRoleCheck)
    case ROADMAP_WIDGET_SECTION.POLICY:
      return getPolicyCheckConfig(check as PolicyCheck, pageContext, ownerEmail)
    case ROADMAP_WIDGET_SECTION.CONTROL:
      return getControlCheckConfig(
        check as ControlCheck,
        pageContext,
        frameworkModelIds,
        ownerEmail,
      )
    case ROADMAP_WIDGET_SECTION.FRAMEWORK:
      return getFrameworkCheckConfig(check as FrameworkCheck)
    case ROADMAP_WIDGET_SECTION.COMPANY_INFO:
      return getCompanyInfoCheckConfig(check as CompanyInfoCheck, pageContext)
    case ROADMAP_WIDGET_SECTION.RISK:
      return getRiskCheckConfig(check as RiskCheck, pageContext, ownerEmail)
    case ROADMAP_WIDGET_SECTION.REQUEST:
      return getRequestCheckConfig(check as RequestCheck, pageContext, ownerId)
    default:
      return {
        text: '',
        hoverText: '',
        link: '',
        requiredPermissions: [PERMISSIONS.READ],
      }
  }
}

export const formatWidgetData = (
  data: GetRoadmapWidgetCountsResponse,
): ROADMAP_WIDGET_DATA_TYPE => ({
  [ROADMAP_WIDGET_SECTION.SERVICE]: {
    finishedCount: data.getServices()?.getFinished() || 0,
    totalCount: data.getServices()?.getTotal() || 0,
    checks: data.getServices()?.getServiceChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.POLICY]: {
    finishedCount: data.getPolicies()?.getFinished() || 0,
    totalCount: data.getPolicies()?.getTotal() || 0,
    checks: data.getPolicies()?.getPolicyChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.CONTROL]: {
    finishedCount: data.getControls()?.getFinished() || 0,
    totalCount: data.getControls()?.getTotal() || 0,
    checks: data.getControls()?.getControlChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.FRAMEWORK]: {
    finishedCount: data.getFrameworks()?.getFinished() || 0,
    totalCount: data.getFrameworks()?.getTotal() || 0,
    checks: data.getFrameworks()?.getFrameworkChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: {
    finishedCount: data.getCompanyInfo()?.getFinished() || 0,
    totalCount: data.getCompanyInfo()?.getTotal() || 0,
    checks: data.getCompanyInfo()?.getCompanyInfoChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.RISK]: {
    finishedCount: data.getRisks()?.getFinished() || 0,
    totalCount: data.getRisks()?.getTotal() || 0,
    checks: data.getRisks()?.getRiskChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.REQUEST]: {
    finishedCount: data.getRequests()?.getFinished() || 0,
    totalCount: data.getRequests()?.getTotal() || 0,
    checks: data.getRequests()?.getRequestChecksList() || [],
  },
  [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: {
    finishedCount: data.getServiceRoles()?.getFinished() || 0,
    totalCount: data.getServiceRoles()?.getTotal() || 0,
    checks: data.getServiceRoles()?.getServiceRoleChecksList() || [],
  },
})

export const getRoadmapWidgetLocationConfig = ({
  appLocation,
  auditId,
  pageContext,
  ownerId,
  ownerEmail,
  isSoc2,
}: {
  appLocation: ROADMAP_WIDGET_LOCATION
  auditId?: string
  pageContext: string
  ownerId?: string
  ownerEmail?: string
  isSoc2: boolean
}): ROADMAP_WIDGET_LOCATION_CONFIG_TYPE | null => {
  switch (appLocation) {
    case ROADMAP_WIDGET_LOCATION.DASHBOARD:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: {
          title: 'Service Roles Fulfilled',
          icon: <ServicesCircleIcon />,
          path: DirectRoutes.SCOPE,
          pageContext,
          tooltip: <ServiceRolesWidgetTooltip />,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.POLICY]: {
          title: 'Policies Ready',
          icon: <PolicyCircleIcon />,
          ownerEmail,
          path: PoliciesAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.CONTROL]: {
          title: 'Controls Prepared',
          icon: <ControlCircleIcon />,
          ownerEmail,
          path: ControlsAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: {
          title: 'Company Info',
          icon: <CompanyInfoCircleIcon />,
          ownerEmail,
          path: DirectRoutes.COMPANY_INFO,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.RISK]: {
          title: 'Risks Registered',
          icon: <RisksCircleIcon />,
          ownerEmail,
          path: RisksAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.REQUEST]: !auditId
          ? null
          : {
              title: 'Requests Handled',
              icon: <RequestsCircleIcon />,
              ownerId,
              path: RequestsAbsoluteRoutes.INDEX,
              pageContext,
              requiredPermissions: [PERMISSIONS.READ],
            },
      }
    case ROADMAP_WIDGET_LOCATION.PREPARE_CONTENT:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: null,
        [ROADMAP_WIDGET_SECTION.POLICY]: {
          title: 'Policies',
          icon: <PolicyCircleIcon />,
          path: PoliciesAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.CONTROL]: {
          title: 'Controls',
          icon: <ControlCircleIcon />,
          path: ControlsAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: isSoc2
          ? {
              title: 'SOC 2 Coverage',
              icon: <FrameworksCircleIcon />,
              path: ControlsAbsoluteRoutes.INDEX,
              pageContext,
              requiredPermissions: [PERMISSIONS.READ],
            }
          : null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: isSoc2
          ? {
              title: 'Company Info',
              icon: <CompanyInfoCircleIcon />,
              path: DirectRoutes.COMPANY_INFO,
              pageContext,
              requiredPermissions: [PERMISSIONS.READ],
            }
          : null,
        [ROADMAP_WIDGET_SECTION.RISK]: null,
        [ROADMAP_WIDGET_SECTION.REQUEST]: null,
      }
    case ROADMAP_WIDGET_LOCATION.OPERATIONALIZE:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: null,
        [ROADMAP_WIDGET_SECTION.POLICY]: {
          title: 'Policies',
          icon: <PolicyCircleIcon />,
          path: PoliciesAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.CONTROL]: {
          title: 'Controls',
          icon: <ControlCircleIcon />,
          path: ControlsAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: null,
        [ROADMAP_WIDGET_SECTION.RISK]: {
          title: 'Risks',
          icon: <RisksCircleIcon />,
          path: RisksAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
        [ROADMAP_WIDGET_SECTION.REQUEST]: null,
      }
    case ROADMAP_WIDGET_LOCATION.HANDLE_REQUESTS:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: null,
        [ROADMAP_WIDGET_SECTION.POLICY]: null,
        [ROADMAP_WIDGET_SECTION.CONTROL]: null,
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: null,
        [ROADMAP_WIDGET_SECTION.RISK]: null,
        [ROADMAP_WIDGET_SECTION.REQUEST]: {
          title: 'Document Requests',
          icon: <RequestsCircleIcon />,
          path: RequestsAbsoluteRoutes.INDEX,
          pageContext,
          requiredPermissions: [PERMISSIONS.READ],
        },
      }
    default:
      return null
  }
}

export const getRoadmapWidgetRequestConfig = ({
  appLocation,
  auditId,
  isSoc2,
}: {
  appLocation: ROADMAP_WIDGET_LOCATION
  auditId?: string
  isSoc2: boolean
}): ROADMAP_WIDGET_REQUEST_CONFIG | null => {
  const isServicesOverhaul = IsGatekeeperEnabled(
    GatekeeperFlag.SERVICES_OVERHAUL,
  )
  switch (appLocation) {
    case ROADMAP_WIDGET_LOCATION.DASHBOARD: {
      const requestsConfig: REQUEST_REQUEST_CONFIG | null = auditId
        ? {
            [REQUEST_CHECKS.REQUEST_HAS_ASSIGNEE]: true,
            [REQUEST_CHECKS.REQUEST_HAS_EVIDENCE]: true,
            [REQUEST_CHECKS.REQUEST_HAS_LINKED_CONTROLS]: true,
            [REQUEST_CHECKS.REQUEST_HAS_PASSING_STATUS]: true,
          }
        : null

      const servicesConfig: SERVICE_REQUEST_CONFIG | null = !isServicesOverhaul
        ? {
            [SERVICE_CHECKS.SERVICE_SELECTED]: true,
          }
        : null

      const serviceRolesConfig: SERVICE_ROLE_REQUEST_CONFIG | null =
        isServicesOverhaul
          ? {
              [SERVICE_ROLE_CHECKS.ROLE_FULFILLED]: true,
            }
          : null

      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: servicesConfig,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: serviceRolesConfig,
        [ROADMAP_WIDGET_SECTION.POLICY]: {
          [POLICY_CHECKS.POLICY_HAS_ASSIGNEE]: true,
          [POLICY_CHECKS.POLICY_HAS_DOCUMENTATION]: true,
          [POLICY_CHECKS.POLICY_HAS_LINKED_CONTROLS]: true,
          [POLICY_CHECKS.POLICY_IS_FILLED_IN]: true,
        },
        [ROADMAP_WIDGET_SECTION.CONTROL]: {
          [CONTROL_CHECKS.CONTROL_HAS_ASSIGNEE]: true,
          [CONTROL_CHECKS.CONTROL_HAS_EVIDENCE]: true,
          [CONTROL_CHECKS.CONTROL_HAS_POLICY]: true,
          [CONTROL_CHECKS.CONTROL_HAS_FRAMEWORK]: true,
          [CONTROL_CHECKS.CONTROL_HAS_PASSING_STATUS]: true,
        },
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: {
          [COMPANY_INFO_CHECKS.COMPANY_INFO_HAS_DOCUMENTATION]: true,
          [COMPANY_INFO_CHECKS.COMPANY_INFO_IS_FILLED_IN]: true,
        },
        [ROADMAP_WIDGET_SECTION.RISK]: {
          [RISK_CHECKS.RISK_HAS_ASSIGNEE]: true,
          [RISK_CHECKS.RISK_HAS_PASSING_STATUS]: true,
          [RISK_CHECKS.RISK_INHERENT_RISK_DETERMINED]: true,
          [RISK_CHECKS.RISK_RESIDUAL_RISK_DETERMINED]: false,
        },
        [ROADMAP_WIDGET_SECTION.REQUEST]: requestsConfig,
      }
    }
    case ROADMAP_WIDGET_LOCATION.PREPARE_CONTENT:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: null,
        [ROADMAP_WIDGET_SECTION.POLICY]: {
          [POLICY_CHECKS.POLICY_HAS_ASSIGNEE]: false,
          [POLICY_CHECKS.POLICY_HAS_DOCUMENTATION]: true,
          [POLICY_CHECKS.POLICY_HAS_LINKED_CONTROLS]: true,
          [POLICY_CHECKS.POLICY_IS_FILLED_IN]: true,
        },
        [ROADMAP_WIDGET_SECTION.CONTROL]: {
          [CONTROL_CHECKS.CONTROL_HAS_ASSIGNEE]: false,
          [CONTROL_CHECKS.CONTROL_HAS_EVIDENCE]: false,
          [CONTROL_CHECKS.CONTROL_HAS_POLICY]: true,
          [CONTROL_CHECKS.CONTROL_HAS_FRAMEWORK]: true,
          [CONTROL_CHECKS.CONTROL_HAS_PASSING_STATUS]: false,
        },
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: isSoc2
          ? {
              [FRAMEWORK_CHECKS.FRAMEWORK_HAS_LINKED_CONTROLS]: true,
            }
          : null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: isSoc2
          ? {
              [COMPANY_INFO_CHECKS.COMPANY_INFO_HAS_DOCUMENTATION]: true,
              [COMPANY_INFO_CHECKS.COMPANY_INFO_IS_FILLED_IN]: true,
            }
          : null,
        [ROADMAP_WIDGET_SECTION.RISK]: null,
        [ROADMAP_WIDGET_SECTION.REQUEST]: null,
      }
    case ROADMAP_WIDGET_LOCATION.OPERATIONALIZE:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: null,
        [ROADMAP_WIDGET_SECTION.POLICY]: {
          [POLICY_CHECKS.POLICY_HAS_ASSIGNEE]: true,
          [POLICY_CHECKS.POLICY_HAS_DOCUMENTATION]: false,
          [POLICY_CHECKS.POLICY_HAS_LINKED_CONTROLS]: false,
          [POLICY_CHECKS.POLICY_IS_FILLED_IN]: false,
        },
        [ROADMAP_WIDGET_SECTION.CONTROL]: {
          [CONTROL_CHECKS.CONTROL_HAS_ASSIGNEE]: true,
          [CONTROL_CHECKS.CONTROL_HAS_EVIDENCE]: false,
          [CONTROL_CHECKS.CONTROL_HAS_POLICY]: false,
          [CONTROL_CHECKS.CONTROL_HAS_FRAMEWORK]: false,
          [CONTROL_CHECKS.CONTROL_HAS_PASSING_STATUS]: true,
        },
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: null,
        [ROADMAP_WIDGET_SECTION.RISK]: {
          [RISK_CHECKS.RISK_HAS_ASSIGNEE]: true,
          [RISK_CHECKS.RISK_HAS_PASSING_STATUS]: true,
          [RISK_CHECKS.RISK_INHERENT_RISK_DETERMINED]: true,
          [RISK_CHECKS.RISK_RESIDUAL_RISK_DETERMINED]: false,
        },
        [ROADMAP_WIDGET_SECTION.REQUEST]: null,
      }
    case ROADMAP_WIDGET_LOCATION.HANDLE_REQUESTS:
      return {
        [ROADMAP_WIDGET_SECTION.SERVICE]: null,
        [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: null,
        [ROADMAP_WIDGET_SECTION.POLICY]: null,
        [ROADMAP_WIDGET_SECTION.CONTROL]: null,
        [ROADMAP_WIDGET_SECTION.FRAMEWORK]: null,
        [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: null,
        [ROADMAP_WIDGET_SECTION.RISK]: null,
        [ROADMAP_WIDGET_SECTION.REQUEST]: {
          [REQUEST_CHECKS.REQUEST_HAS_ASSIGNEE]: true,
          [REQUEST_CHECKS.REQUEST_HAS_EVIDENCE]: true,
          [REQUEST_CHECKS.REQUEST_HAS_LINKED_CONTROLS]: true,
          [REQUEST_CHECKS.REQUEST_HAS_PASSING_STATUS]: true,
        },
      }
    default:
      return null
  }
}

const setRequestControlChecks = (
  request: GetRoadmapWidgetCountsRequest,
  controlConfig: CONTROL_REQUEST_CONFIG,
) => {
  request.setControlChecks(
    new ControlWidgetRequestConfig()
      .setIncludeHasAssignee(controlConfig[CONTROL_CHECKS.CONTROL_HAS_ASSIGNEE])
      .setIncludeHasEvidence(controlConfig[CONTROL_CHECKS.CONTROL_HAS_EVIDENCE])
      .setIncludeHasPolicy(controlConfig[CONTROL_CHECKS.CONTROL_HAS_POLICY])
      .setIncludeHasFramework(
        controlConfig[CONTROL_CHECKS.CONTROL_HAS_FRAMEWORK],
      )
      .setIncludeHasPassingStatus(
        controlConfig[CONTROL_CHECKS.CONTROL_HAS_PASSING_STATUS],
      ),
  )
}

const setRequestPolicyChecks = (
  request: GetRoadmapWidgetCountsRequest,
  policyConfig: POLICY_REQUEST_CONFIG,
) => {
  request.setPolicyChecks(
    new PolicyWidgetRequestConfig()
      .setIncludeHasAssignee(policyConfig[POLICY_CHECKS.POLICY_HAS_ASSIGNEE])
      .setIncludeHasDocumentation(
        policyConfig[POLICY_CHECKS.POLICY_HAS_DOCUMENTATION],
      )
      .setIncludeHasLinkedControls(
        policyConfig[POLICY_CHECKS.POLICY_HAS_LINKED_CONTROLS],
      )
      .setIncludeIsFilledIn(policyConfig[POLICY_CHECKS.POLICY_IS_FILLED_IN]),
  )
}

const setRequestSerivceChecks = (
  request: GetRoadmapWidgetCountsRequest,
  serviceConfig: SERVICE_REQUEST_CONFIG,
) => {
  request.setServiceChecks(
    new ServiceWidgetRequestConfig().setIncludeServiceSelected(
      serviceConfig[SERVICE_CHECKS.SERVICE_SELECTED],
    ),
  )
}

const setRequestFrameworkChecks = (
  request: GetRoadmapWidgetCountsRequest,
  frameworkConfig: FRAMEWORK_REQUEST_CONFIG,
) => {
  request.setFrameworkChecks(
    new FrameworkWidgetRequestConfig().setIncludeHasLinkedControls(
      frameworkConfig[FRAMEWORK_CHECKS.FRAMEWORK_HAS_LINKED_CONTROLS],
    ),
  )
}

const setRequestCompanyInfoChecks = (
  request: GetRoadmapWidgetCountsRequest,
  companyInfoConfig: COMPANY_INFO_REQUEST_CONFIG,
) => {
  request.setCompanyInfoChecks(
    new CompanyInfoWidgetRequestConfig()
      .setIncludeHasDocumentation(
        companyInfoConfig[COMPANY_INFO_CHECKS.COMPANY_INFO_HAS_DOCUMENTATION],
      )
      .setIncludeIsFilledIn(
        companyInfoConfig[COMPANY_INFO_CHECKS.COMPANY_INFO_IS_FILLED_IN],
      ),
  )
}

const setRequestRiskChecks = (
  request: GetRoadmapWidgetCountsRequest,
  riskConfig: RISK_REQUEST_CONFIG,
) => {
  request.setRiskChecks(
    new RiskWidgetRequestConfig()
      .setIncludeHasAssignee(riskConfig[RISK_CHECKS.RISK_HAS_ASSIGNEE])
      .setIncludeHasPassingStatus(
        riskConfig[RISK_CHECKS.RISK_HAS_PASSING_STATUS],
      )
      .setIncludeInherentRiskDetermined(
        riskConfig[RISK_CHECKS.RISK_INHERENT_RISK_DETERMINED],
      )
      .setIncludeResidualRiskDetermined(
        riskConfig[RISK_CHECKS.RISK_RESIDUAL_RISK_DETERMINED],
      ),
  )
}

const setRequestDocumentRequestChecks = (
  request: GetRoadmapWidgetCountsRequest,
  documentRequestConfig: REQUEST_REQUEST_CONFIG,
) => {
  request.setRequestChecks(
    new RequestWidgetRequestConfig()
      .setIncludeHasAssignee(
        documentRequestConfig[REQUEST_CHECKS.REQUEST_HAS_ASSIGNEE],
      )
      .setIncludeHasEvidence(
        documentRequestConfig[REQUEST_CHECKS.REQUEST_HAS_EVIDENCE],
      )
      .setIncludeHasLinkedControls(
        documentRequestConfig[REQUEST_CHECKS.REQUEST_HAS_LINKED_CONTROLS],
      )
      .setIncludeHasPassingStatus(
        documentRequestConfig[REQUEST_CHECKS.REQUEST_HAS_PASSING_STATUS],
      ),
  )
}

const setRequestServiceRoleChecks = (
  request: GetRoadmapWidgetCountsRequest,
  serviceRoleConfig: SERVICE_ROLE_REQUEST_CONFIG,
) => {
  request.setServiceRoleChecks(
    new ServiceRoleWidgetRequestConfig().setIncludeRoleFulfilled(
      serviceRoleConfig[SERVICE_ROLE_CHECKS.ROLE_FULFILLED],
    ),
  )
}

export const buildRoadmapWidgetRequest = (
  requestConfig: ROADMAP_WIDGET_REQUEST_CONFIG,
  request: GetRoadmapWidgetCountsRequest,
): GetRoadmapWidgetCountsRequest => {
  // set control checks on request
  const controlConfig = requestConfig[ROADMAP_WIDGET_SECTION.CONTROL]
  controlConfig && setRequestControlChecks(request, controlConfig)

  // set policy checks on request
  const policyConfig = requestConfig[ROADMAP_WIDGET_SECTION.POLICY]
  policyConfig && setRequestPolicyChecks(request, policyConfig)

  // set service checks on request
  const serviceConfig = requestConfig[ROADMAP_WIDGET_SECTION.SERVICE]
  serviceConfig && setRequestSerivceChecks(request, serviceConfig)

  // set framework checks on request
  const frameworkConfig = requestConfig[ROADMAP_WIDGET_SECTION.FRAMEWORK]
  frameworkConfig && setRequestFrameworkChecks(request, frameworkConfig)

  // set company info checks on request
  const companyInfoConfig = requestConfig[ROADMAP_WIDGET_SECTION.COMPANY_INFO]
  companyInfoConfig && setRequestCompanyInfoChecks(request, companyInfoConfig)

  // set risk checks on request
  const riskConfig = requestConfig[ROADMAP_WIDGET_SECTION.RISK]
  riskConfig && setRequestRiskChecks(request, riskConfig)

  // set request checks on request
  const documentRequestConfig = requestConfig[ROADMAP_WIDGET_SECTION.REQUEST]
  documentRequestConfig &&
    setRequestDocumentRequestChecks(request, documentRequestConfig)

  // set service role checks on request
  const serviceRoleConfig = requestConfig[ROADMAP_WIDGET_SECTION.SERVICE_ROLE]
  serviceRoleConfig && setRequestServiceRoleChecks(request, serviceRoleConfig)

  return request
}
