import React from 'react'
import ModalDialog from 'react-bootstrap/ModalDialog'
import styled, { css } from 'styled-components/macro'
import {
  border,
  color,
  compose,
  margin,
  space,
  textStyle,
  TextStyleProps,
} from 'styled-system'
import themeGet from '@styled-system/theme-get'
import palette from 'src/designSystem/variables/palette'
import { ChevronSVG } from '../Icons/Basic'
import { StandardButton } from '../Reusable/Buttons/StandardButton'
import { GridColumn } from '../Reusable/Grid'

export const FieldLabel = styled.label.attrs<{
  validateForm?: boolean
  isInvalid?: boolean
  message?: string
}>((props) => {
  return {
    mb: 'xxxs',
    textStyle: 'body.default',
    children: (
      <>
        {props.children}
        <FieldError show={props.validateForm && props.isInvalid}>
          {props.message}
        </FieldError>
      </>
    ),
  }
})<{
  validateForm?: boolean
  isInvalid?: boolean
  message?: string
}>`
  ${textStyle}
  display: block;
  > :first-child {
    ${margin};
  }
`

export const FieldError = styled.p.attrs({
  color: 'fill.status.error.dark',
})<{ show?: boolean }>`
  height: 24px;
  color: transparent;
  line-height: 14px;
  overflow: hidden;
  ${(props) => {
    return (
      props.show &&
      css`
        ${color}
      `
    )
  }}
`

export const ModalHeader = styled.header.attrs({
  mb: 'l',
})`
  ${space}
`

export const ModalTitle = styled.h2.attrs({
  m: 0,
  textStyle: 'header.2',
})`
  ${compose(space, textStyle)}
  color: black;
`

export const ModalDescription = styled.article.attrs({
  mt: 'm',
  textStyle: 'body.default',
})`
  ${compose(space, textStyle)}
`

export const ModalBody = styled.article.attrs({
  className: 'modal-body',
  mb: 'm',
})<TextStyleProps & { $showOverflow?: boolean }>`
  padding: 0;
  overflow: ${({ $showOverflow }) => ($showOverflow ? 'visible' : 'auto')};
  ${compose(space, border, textStyle)}
`

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`

export const ModalButtonGroup = styled.div.attrs({
  mr: 'm',
})`
  display: flex;

  &:last-child {
    margin-left: auto;
  }

  > *:not(:only-child):not(:last-child) {
    ${space}
  }
`

export const BackIcon = styled(ChevronSVG).attrs({
  fill: 'fill.primary.extraDark',
})`
  transform: rotate(180deg);
  margin-right: 8px;
  height: 16px;
`
export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const ModalButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 180px;
  width: 100%;

  border: 1px solid ${themeGet('colors.border.neutral.light')};
  border-radius: 6px;

  :hover {
    background-color: ${themeGet('colors.bg.neutral')};
  }
`

export const StyledModalDialog = styled(ModalDialog)`
  font-family: Poppins, sans-serif;

  .modal-content {
    padding: ${themeGet('space.xxl')}px ${themeGet('space.xxl')}px
      ${themeGet('space.xl')}px;
    box-shadow: ${themeGet('shadows.outer.high')};
    border: none;
    border-radius: 4px;
  }

  &.modal-dialog {
    @media (min-width: 576px) {
      &.modal-sm {
        max-width: 500px;
      }
    }
  }
`

export const StyledModalButton = styled(StandardButton)`
  ${(props) => {
    return props.disabled
      ? css`
          pointer-events: none;
        `
      : css`
          &:hover {
            cursor: pointer;
          }
        `
  }}
  white-space: nowrap;
`

export const StyledToggleDiv = styled.div`
  width: 100%;
  height: 100%;
`

export const SelectedCountSpan = styled.span`
  font-size: 0.875rem;
`

export const GridColumnFlexEnd = styled(GridColumn)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const WarningP = styled.p`
  color: ${palette.magenta[500]};
  margin-bottom: 1.125em;
`
