import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  GetLatestEvidenceRequest,
  GetLatestEvidenceResponse,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { useInAudit } from 'src/context/AuditContext'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useLatestEvidenceResults = (
  request: GetLatestEvidenceRequest,
  shouldFetch = true,
): GrpcResponse<GetLatestEvidenceResponse> => {
  const { auditId } = useInAudit()

  if (auditId) {
    request.setAuditId(new StringValue().setValue(auditId))
  }

  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getLatestEvidence,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}
