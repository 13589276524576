import { useCallback } from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  ListControlsRequest,
  Controls,
  Control,
  CreateUpdateControlRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import log from 'loglevel'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { GetHasNoControlsResponse } from '@trustero/trustero-api-web/lib/model/control_pb'
import { ControlServicePromiseClient } from '@trustero/trustero-api-web/lib/model/control_grpc_web_pb'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import { toast } from 'react-toastify'
import { BoolValue, StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  ControlStats,
  GetControlStatsRequest,
} from '@trustero/trustero-api-web/lib/model/model_pb'
import { useInAudit } from 'src/context/AuditContext'
import { useGrpcRevalidateByMethod } from '../../../../components/async/useGrpcMutate'
import { useSwrImmutableGrpc } from '../../../../components/async/useSwrImmutableGrpc'
import { SwrGrpcResponse } from '../../../../components/async/useSwrGrpc'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useInvalidateDocumentRequestsCache } from '../../DocumentRequest/useDocumentRequests'
import { ToastPrompts } from '../../../../Utils/helpers/toast'

// We can add to this hook's parameters as we start to use it elsewhere in the app
type UseAddOrUpdateControlParams = {
  modelId?: string
  requestIds?: string[]
  logEntryValue?: string
  controlId?: string
}

const mutators: Set<() => void> = new Set()

export const useControlsWithAuditId = (auditId?: string): GrpcResponse<Controls> => useControls(undefined, undefined, undefined, auditId)

export const useControls = (
  request?: ListControlsRequest,
  shouldFetch = true,
  config?: SwrGrpcResponse,
  auditId?: string,
  includeDismissed = false,
  skipAuditCheck = false,
): GrpcResponse<Controls> => {
  const { auditId: currentAuditId } = useInAudit()
  const listControlsRequest = request ?? new ListControlsRequest()
    .setIsDismissed(new BoolValue().setValue(includeDismissed))

  if (!skipAuditCheck && (auditId || currentAuditId)) {
    const requestAuditId = auditId || currentAuditId
    if (requestAuditId) {
      listControlsRequest.setAuditId(new StringValue().setValue(requestAuditId))
    }
  }

  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.listControls,
    listControlsRequest,
    shouldFetch,
    config,
  )
  mutators.add(response.mutate)
  return NewGrpcResponse(response)
}

// Returns a boolean indicating whether or not the account has no controls
export const useHasNoControls = (): GrpcResponse<GetHasNoControlsResponse> => {
  const { response } = useSwrImmutableGrpc(
    ControlServicePromiseClient.prototype.getHasNoControls,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

export const useInvalidateControlsCache = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    try {
      await Promise.all([
        mutateFunc(ModelPromiseClient.prototype.listControls),
        mutateFunc(ModelPromiseClient.prototype.listControlIds),
        mutateFunc(ControlServicePromiseClient.prototype.getHasNoControls),
      ])
    } catch (error) {
      log.error('Error when invalidating controls cache', error)
    }
  }, [mutateFunc])
}

export const useAddOrUpdateControl = (): (({
  controlId,
  modelId,
  requestIds,
  logEntryValue,
}: UseAddOrUpdateControlParams) => Promise<Control>) => {
  const mutator = useInvalidateControlsCache()
  const requestMutator = useInvalidateDocumentRequestsCache()
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)

  return async ({
    modelId,
    requestIds,
    logEntryValue,
    controlId,
  }: UseAddOrUpdateControlParams): Promise<Control> => {
    const createOrUpdateRequest = new CreateUpdateControlRequest()

    if (controlId) {
      createOrUpdateRequest.setId(controlId)
    }
    if (modelId) {
      createOrUpdateRequest.setModelId(new StringValue().setValue(modelId))
    }
    if (requestIds) {
      createOrUpdateRequest.setRequestIds(
        new StringList().setItemList(requestIds),
      )
    }
    if (logEntryValue) {
      createOrUpdateRequest.setLogEntryValue(
        new StringValue().setValue(logEntryValue),
      )
    }

    let response = new Control()
    try {
      response = await modelClient.createOrUpdateControl(createOrUpdateRequest)
      await mutator()
      if (requestIds) {
        await requestMutator()
      }
    } catch (e) {
      log.error(
        'error creating or updating control in useAddOrUpdateControl hook',
        e,
      )
      if (requestIds) {
        toast(ToastPrompts.LINK_CONTROL_TO_REQUEST)
      }
    }
    return response
  }
}

export const useControlStats = (): GrpcResponse<ControlStats> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getControlStats,
    new GetControlStatsRequest(),
  )
  return NewGrpcResponse(response)
}
