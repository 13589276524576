import React, { ChangeEventHandler, FormEventHandler } from 'react'
import queryString, { ParsedQuery } from 'query-string'
import FileSaver from 'file-saver'
import * as CSV from 'csv-string'
import { ModelRecord } from '@trustero/trustero-api-web/lib/model/model_pb'
import {
  ListServicesRequest,
  PrimaryInfrastructureMapping,
  ServiceRecord,
  ServiceRoleFilter,
} from '@trustero/trustero-api-web/lib/service/service_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { ModalFormId } from 'src/components/ModalForms'
import { MultiSelectFormProps } from 'src/components/Reusable/Forms/MultiSelectForm'
import { Service } from 'src/xgenerated'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { NONE_ID } from 'src/Utils/globalConstants'
import {
  ServiceCategory,
  ServiceType,
  getAllServiceTemplates,
  getServiceTemplate,
} from 'src/xgenerated/service'
import * as logos from '../../components/Icons/Dependencies'
import {
  PRIMARY_INFRASTRUCTURE_CSV_HEADERS,
  SERVICE_ROLE_LABEL_TO_ROLE,
  SERVICE_ROLE_TO_LABEL,
  ServiceRoleLabels,
  excludedServiceIds,
} from './Services.constants'
import { AddServiceGridItems } from './services.components'

export const mergeServiceData = (
  serviceRecord: ServiceRecord,
  serviceTemplate: Service,
): Service => {
  const service: Service = {
    ...serviceRecord.toObject(),
    ...serviceTemplate,
    id: serviceRecord.getId(),
    dismissed: serviceRecord.getIsDismissed(),
    service_roles: serviceRecord.getServiceRolesList(),
    instance_count: serviceRecord.getInstanceCount(),
    is_excluded: serviceRecord.getIsExcluded(),
  }
  return service
}

// [AP-6545] TODO: After feature flag is removed, update xbuild.py to clean up the Service object and remove unnecessary fields
export const buildCustomService = (serviceRecord: ServiceRecord): Service => {
  return {
    ...({} as ModelRecord.AsObject),
    name: serviceRecord.getName(),
    content: 'This service is specific to your organization.',
    custom: true,
    component_category: ServiceCategory['Business Services and Tools'],
    component_type: ServiceType['SaaS'],
    control_ids: [],
    recommended_service: false,
    detected: true,
    dismissed: serviceRecord.getIsDismissed(),
    id: serviceRecord.getId(),
    logo: logos.UnknownSaaS,
    logo_name: 'UnknownSaaS',
    service_roles: serviceRecord.getServiceRolesList(),
    modelid: serviceRecord.getModelId(),
    instance_count: serviceRecord.getInstanceCount(),
  } as Service
}

export const extractDuplicateNames = (errMessage: string): string[] => {
  const match = errMessage.match(/\[(.*?)\]/)

  if (match) {
    return match[1].split(', ').map((name) => name.replace(/"/g, ''))
  }
  return []
}

export const getService = (serviceRecord: ServiceRecord): Service => {
  const isCustom = serviceRecord.getIsCustom()
  const serviceModelId = serviceRecord.getModelId()

  return isCustom
    ? buildCustomService(serviceRecord)
    : mergeServiceData(serviceRecord, getServiceTemplate(serviceModelId))
}

const getServiceTemplateMap = (): Record<string, Service> => {
  const serviceTemplates = getAllServiceTemplates()
  const serviceTemplateMap: Record<string, Service> = {}
  serviceTemplates.forEach((serviceTemplate) => {
    serviceTemplateMap[serviceTemplate.modelid] = serviceTemplate
  })
  return serviceTemplateMap
}

export const updateTemplateServices = (
  serviceRecords: ServiceRecord[],
): Service[] => {
  const serviceTemplateMap = getServiceTemplateMap()
  const updatedTemplateServices = serviceRecords
    .filter((serviceRecord) => {
      if (serviceTemplateMap[serviceRecord.getModelId()]) {
        delete serviceTemplateMap[serviceRecord.getModelId()]
        return true
      }
    })
    .map((serviceRecord) => {
      const serviceTemplate = getServiceTemplate(serviceRecord.getModelId())
      return mergeServiceData(serviceRecord, serviceTemplate)
    })
  const remainingServiceTemplates = Object.values(serviceTemplateMap)
  return [...updatedTemplateServices, ...remainingServiceTemplates]
}

export const getCustomServices = (serviceRecords: ServiceRecord[]): Service[] =>
  serviceRecords
    .filter((serviceRecord) => serviceRecord.getIsCustom())
    .map((serviceRecord) => buildCustomService(serviceRecord))

const getAllServices = (serviceRecords: ServiceRecord[]): Service[] => {
  const updatedTemplateServices = updateTemplateServices(serviceRecords)
  const customServices = getCustomServices(serviceRecords)
  const services = [...updatedTemplateServices, ...customServices].filter(
    (serviceRecord) => !excludedServiceIds.includes(serviceRecord.id),
  )
  services.sort((a, b) => a.name.localeCompare(b.name))
  return services
}

export const getActiveServices = (
  serviceRecords: ServiceRecord[],
  locSearch: string,
): Service[] => {
  const services = getAllServices(serviceRecords).filter(
    (service) => !service.dismissed,
  )
  return applyFrontendServiceFilters(locSearch, services)
}

export const getInactiveServices = (
  serviceRecords: ServiceRecord[],
): Service[] => {
  const services = getAllServices(serviceRecords)
  return services.filter(
    (service) =>
      service.dismissed && service.component_type === ServiceType.SaaS,
  )
}

export const applyFrontendServiceFilters = (
  locSearch: string,
  services: Service[],
): Service[] => {
  const queryParams: ParsedQuery<string> = queryString.parse(locSearch, {
    arrayFormat: 'bracket',
  })
  let filteredServices = services
  // Apply all frontend filters for each parameter in URL
  Object.values(FilterParam).forEach((filterType: FilterParam) => {
    const filterParams = queryParams[filterType] as string[]

    if (!filterParams?.length) {
      return
    }
    if (filterType === FilterParam.RECEPTOR) {
      const receptorModelIds = new Set(filterParams)
      filteredServices = filteredServices.filter((service) => {
        if (receptorModelIds.has(NONE_ID)) {
          return (
            !service.receptor_id || receptorModelIds.has(service.receptor_id)
          )
        }
        return service.receptor_id && receptorModelIds.has(service.receptor_id)
      })
    }
  })
  return filteredServices
}

export const applyServicesFilters = (
  req: ListServicesRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.SERVICE_ROLE: {
      const serviceRoleFilter = new ServiceRoleFilter()
      const params = new Set(filterParams)
      if (params.has(NONE_ID)) {
        serviceRoleFilter.setIncludeNone(new BoolValue().setValue(true))
        params.delete(NONE_ID)
      }
      const roles = (Array.from(params) as ServiceRoleLabels[]).map(
        (label) => SERVICE_ROLE_LABEL_TO_ROLE[label],
      )
      roles.length > 0 && serviceRoleFilter.setServiceRolesList(roles)
      req.setServiceRoleFilter(serviceRoleFilter)
      break
    }
    default:
      break
  }
}

export const buildServicesMap = (
  services: Service[],
): Record<string, Service> =>
  services.reduce(
    (servicesMap, service) => ({ ...servicesMap, [service.id]: service }),
    {},
  )

export const getAddServicesMultiSelectProps = (
  services: Service[],
  onSubmit: FormEventHandler<HTMLFormElement>,
  onChange: ChangeEventHandler<HTMLInputElement>,
): MultiSelectFormProps => {
  return {
    loading: false,
    formId: ModalFormId.ADD_SERVICES,
    title: 'Select services you use to add to your Services list.',
    onSubmit,
    gridTemplateColumnStyling: 'min-content repeat(2, 1fr)',
    gridItems: <AddServiceGridItems services={services} onChange={onChange} />,
  }
}

const generatePrimaryInfrastructureMapping = (
  mappings: PrimaryInfrastructureMapping[],
): Blob => {
  const primaryInfraMappings: string[][] = mappings.map(
    (mapping: PrimaryInfrastructureMapping) => {
      const role = SERVICE_ROLE_TO_LABEL[mapping.getServiceRole()]
      const services = mapping.getServicesList()
      const serviceNames = services
        .map((service) =>
          // if service name is empty, use the service template name
          service.getName().length
            ? service.getName().trim()
            : getServiceTemplate(service.getModelId()).name,
        )
        .sort((a, b) => a.localeCompare(b))
        .join(', ')

      return [role, serviceNames]
    },
  )
  const csvRows = PRIMARY_INFRASTRUCTURE_CSV_HEADERS.concat(
    CSV.stringify(primaryInfraMappings),
  ).join('\r\n')
  return new Blob([csvRows], { type: 'text/csv;charset=utf-8;' })
}

export const downloadPrimaryInfrastuctureMappings = (
  mappings: PrimaryInfrastructureMapping[],
): void => {
  const csv = generatePrimaryInfrastructureMapping(mappings)
  FileSaver.saveAs(csv, 'primary-infrastructure-services.csv')
}
