import React, { useContext } from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { GetTestResultsRequest } from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { FEATURE_ACCESS } from '@trustero/trustero-api-web/lib/account/account_pb'
import { useHasFeatureAccess } from 'src/context/Gatekeeper'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { Link } from 'react-router-dom'
import { DeleteDocumentRequest } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import ContentLoader from 'react-content-loader'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import {
  PinkInfoBox,
  PinkInfoBoxBodyColumn,
  PinkInfoBoxHeader,
} from 'src/components/Reusable/Text/PinkInfoBox.styles'
import styled from 'styled-components/macro'
import { FlexAlign, FlexColumn } from 'src/components/Reusable/Flex'
import { useBulkDeleteEvidenceModal } from 'src/pages/Controls/ControlsShowPage/EvidenceGrid/evidence.hooks'
import { useConfirmationModal } from '../useConfirmationModal'
import { ConfirmationContext } from '../../../Confirmation'
import { useAuthorizedGrpcClient } from '../../../adapter'
import { Grid, GridColumn, GridRow } from '../../Reusable/Grid'
import formatDate from '../../../Utils/formatDate'
import { AsyncComponent } from '../../async'
import palette from '../../../designSystem/variables/palette'
import { useHardEvidenceInvalidation } from '../../../Utils/swrCacheInvalidation/useInvalidateEvidence'
import { ControlsAbsoluteRoutes } from '../../Reusable/RootPage/RootPage.constants'
import { DeleteEvidenceBody } from './evidence.styles'

function TestResultsGrid({ evidenceId }: { evidenceId: string }) {
  return (
    <div style={{ maxHeight: '500px', overflowY: 'auto', paddingTop: '10px' }}>
      <AsyncComponent
        asyncCall={TestingPromiseClient.prototype.getTestResults}
        placeholder={<ContentLoader />}
        request={new GetTestResultsRequest().setEvidenceId(
          new StringValue().setValue(evidenceId),
        )}
        child={({ response }) => {
          const testResults = response.getTestresultsList()
          return (
            <Grid gridTemplateColumns="1fr max-content">
              {testResults.map((testResult) => {
                return (
                  <GridRow key={testResult.getId()}>
                    <GridColumn>
                      <Link
                        target={'_blank'}
                        to={`${
                          ControlsAbsoluteRoutes.SHOW
                        }/${testResult.getModelid()}/test/${testResult.getId()}`}
                        rel="noreferrer"
                      >
                        {testResult.getName()}
                      </Link>
                    </GridColumn>
                    <GridColumn>
                      {formatDate(testResult.getCreatedat())}
                    </GridColumn>
                  </GridRow>
                )
              })}
            </Grid>
          )
        }}
      />
    </div>
  )
}

const FromRequestBodyStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  p {
    font-size: 14px;
    color: ${palette.neutral[900]};
  }
`

const DeleteStackButton = ({
  total,
  bulkDelete,
}: {
  total: number
  bulkDelete: () => void
}): JSX.Element => (
  <TextButton onClick={bulkDelete}>
    {`Delete All ${total} Pieces of Evidence`}
  </TextButton>
)

const FromControlBody = ({
  evidenceId,
  bulkDeleteSection,
}: {
  evidenceId: string
  bulkDeleteSection: JSX.Element
}): JSX.Element => {
  const hasRemoveTests = useHasFeatureAccess(
    FEATURE_ACCESS.REMOVE_CONTROL_TESTS,
  )
  return (
    <div>
      {hasRemoveTests ? (
        <DeleteEvidenceBody>
          <p>This cannot be undone.</p>
          {bulkDeleteSection}
        </DeleteEvidenceBody>
      ) : (
        <>
          <p>Note: The following tests will also be deleted.</p>
          <TestResultsGrid evidenceId={evidenceId} />
        </>
      )}
    </div>
  )
}

const FromRequestBody = ({
  bulkDeleteSection,
}: {
  bulkDeleteSection: JSX.Element
}): JSX.Element => (
  <FromRequestBodyStyledContainer>
    <p>The evidence will disappear for all linked controls.</p>
    <p>
      You should clean up bad data, but be careful not to delete evidence that
      your auditor needs to see.
      <br />
      This cannot be undone.
    </p>
    {bulkDeleteSection}
  </FromRequestBodyStyledContainer>
)

type useDeleteEvidenceTypes = {
  evidenceId: string
  caption: string
  controlId: string
  total?: number
  requestId?: string
}

export const useDeleteEvidence = ({
  evidenceId,
  requestId,
  caption,
  controlId,
  total,
}: useDeleteEvidenceTypes): (() => void) => {
  const { setConfirmationState } = useContext(ConfirmationContext)
  const bulkDeleteEvidence = useBulkDeleteEvidenceModal({
    caption,
    controlId,
    totalEvidenceCount: total || 0,
  })

  const refetchEvidence = useHardEvidenceInvalidation()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const bulkDeleteSection =
    !!total && controlId && total > 1 ? (
      <PinkInfoBox>
        <PinkInfoBoxHeader>
          Delete All Evidence In This Stack?
        </PinkInfoBoxHeader>
        <PinkInfoBoxBodyColumn>
          <FlexColumn align={FlexAlign.FLEX_START} gap={8}>
            {`You are deleting one piece of evidence from a stack that samples the same data over time. Would you rather delete all ${total} pieces of evidence in this stack?`}
            <DeleteStackButton total={total} bulkDelete={bulkDeleteEvidence} />
          </FlexColumn>
        </PinkInfoBoxBodyColumn>
      </PinkInfoBox>
    ) : (
      <></>
    )

  const deleteDocument = async () => {
    try {
      const deleteDocumentRequest = new DeleteDocumentRequest()
        .setDocumentId(evidenceId)
        .setDeleteAssociatedTests(true)
      if (requestId) {
        deleteDocumentRequest.setRequestId(
          new StringValue().setValue(requestId),
        )
      }
      await attachmentClient.deleteDocument(deleteDocumentRequest)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setConfirmationState({
        isShown: true,
        size: 'lg',
        title: 'Error Deleting Evidence',
        body: <p>{err.message}</p>,
        confirmText: 'OK',
        hideCancel: true,
        onConfirmCB: () => null,
      })
    } finally {
      refetchEvidence()
    }
  }
  const confirmationModalProps = {
    title: 'Are you sure you want to delete the evidence?',
    body: requestId ? (
      <FromRequestBody bulkDeleteSection={bulkDeleteSection} />
    ) : (
      <FromControlBody
        evidenceId={evidenceId}
        bulkDeleteSection={bulkDeleteSection}
      />
    ),
    confirmText: 'Delete Evidence',
    redirectTo: '.',
    onConfirmCB: deleteDocument,
  }

  return useConfirmationModal(confirmationModalProps)
}
