import React, { useState } from 'react'
import styled from 'styled-components/macro'
import log from 'loglevel'
import { ReactComponent as AddIcon } from '../../../../components/Icons/assets/add-icon.svg'
import { StandardButtonSize, StandardButtonVariant } from '../../Buttons'
import {
  useAssociateDLREvidence,
  useFetchDocumentBody,
} from '../../../async/document/useDocument'
import { useInvalidateEvidence } from '../../../../Utils/swrCacheInvalidation/useInvalidateEvidence'
import palette from '../../../../designSystem/variables/palette'
import { SuggestionButton } from './styles'

type Props = {
  requestId: string
  documentId: string
  caption: string
  contentId: string
  mime: string
}

const StyledAddIcon = styled(AddIcon)`
  width: 15px;
  height: 15px;
  fill: ${palette.purple['200']};
`

const StyledText = styled.div`
  color: ${palette.purple['400']};
`

export const AddSuggestedAutomatedDLREvidenceButton = ({
  requestId,
  documentId,
  caption,
  contentId,
  mime,
}: Props): JSX.Element => {
  const [clicked, setClicked] = useState<boolean>(false)
  const associator = useAssociateDLREvidence()
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const invalidator = useInvalidateEvidence()

  const handleClick = async (e: React.MouseEvent): Promise<void> => {
    try {
      e.preventDefault()
      await associator(requestId, documentId, fetchDocumentBody)
      await invalidator()
      setClicked(true)
    } catch (error) {
      log.error('Error when adding evidence.', error)
    }
  }

  return (
    <SuggestionButton
      variant={StandardButtonVariant.SECONDARY}
      buttonSize={StandardButtonSize.SMALL}
      onClick={handleClick}
      hidden={clicked}
    >
      <StyledAddIcon />
      <StyledText>Add most recent evidence from {caption}.</StyledText>
    </SuggestionButton>
  )
}
