import { FEATURE_ACCESS } from '@trustero/trustero-api-web/lib/account/account_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { DeleteControlEvidencesByCaptionRequest } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import log from 'loglevel'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { useInvalidateEvidence } from 'src/Utils/swrCacheInvalidation'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useConfirmationModal } from 'src/components/ModalForms'
import { useHasFeatureAccess } from 'src/context/Gatekeeper'

export const useBulkDeleteEvidenceModal = ({
  controlId,
  caption,
  totalEvidenceCount,
}: {
  controlId: string
  caption: string
  totalEvidenceCount: number
}): (() => void) => {
  const mutate = useInvalidateEvidence()
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const hasRemoveTests = useHasFeatureAccess(
    FEATURE_ACCESS.REMOVE_CONTROL_TESTS,
  )

  const evidenceBulkDelete = async () => {
    try {
      const request = new DeleteControlEvidencesByCaptionRequest()
        .setControlId(controlId)
        .setCaption(caption)
      await client.deleteControlEvidencesByCaption(request)
      await mutate()
    } catch (error) {
      log.error(
        `Error when bulk deleting evidence with caption ${caption} for control id ${controlId}`,
        error,
      )
    }
  }

  const confirmationModalProps = {
    title: 'Are you sure you want to delete this stack of evidence?',
    body: `This will delete all ${totalEvidenceCount} pieces of "${formatEvidenceCaption(
      caption,
      true,
    )}" evidence in this stack.${
      !hasRemoveTests
        ? ' Any tests linked to this evidence will be deleted as well.'
        : ' This cannot be undone.'
    }`,
    confirmText: 'Delete Stack of Evidence',
    onConfirmCB: evidenceBulkDelete,
  }

  return useConfirmationModal(confirmationModalProps)
}
