import React from 'react'
import {
  ActionsRowContainer,
  EvidenceBulkDeleteButton,
  EvidenceBulkDeleteIcon,
} from './evidence.styles'
import { useBulkDeleteEvidenceModal } from './evidence.hooks'

export const ExpandedEvidenceActionsRow = ({
  controlId,
  caption,
  totalEvidenceCount,
}: {
  controlId: string
  caption: string
  totalEvidenceCount: number
}): JSX.Element => {
  const bulkDeleteEvidence = useBulkDeleteEvidenceModal({
    controlId,
    caption,
    totalEvidenceCount,
  })

  return (
    <ActionsRowContainer>
      This stack of evidence samples the same data over time.
      <EvidenceBulkDeleteButton onClick={bulkDeleteEvidence}>
        <>
          <EvidenceBulkDeleteIcon />
          Delete All Evidence in This Stack
        </>
      </EvidenceBulkDeleteButton>
    </ActionsRowContainer>
  )
}
