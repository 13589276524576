import React, { useContext } from 'react'
import {
  ACTOR_TYPE,
  DeleteDocumentRequest,
  DOCUMENT_TYPE,
  Documents,
  GetPolicyAcknowledgementFormRequest,
  GetPolicyAcknowledgementFormResponse,
  GetPolicyDocumentsByPolicyIDRequest,
  LinkDocumentRequest,
  LinkDocumentsRequest,
  PolicyAcknowledgementFormValues,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { Document as DocumentMsg } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  CreateOrUpdatePolicyRequest,
  PolicyRecord,
} from '@trustero/trustero-api-web/lib/model/policy_pb'
import {
  BoolValue,
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'
import log from 'loglevel'
import { useLocation, useNavigate } from 'react-router-dom'
import { NewGrpcResponse, GrpcResponse } from 'src/components/async/hooks/types'
import { GetPolicyFrameworksRequest } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { Frameworks } from '@trustero/trustero-api-web/lib/model/framework_pb'
import { GetGoogleDrivePoliciesRequest } from '@trustero/trustero-api-web/lib/linker/linker_pb'
import { LinkerPromiseClient } from '@trustero/trustero-api-web/lib/linker/linker_grpc_web_pb'
import {
  useFetchDocumentBody,
  useGetDownloadUrl,
  useGetLinkedDocumentUrl,
} from 'src/components/async/document/useDocument'
import { openInNewTab } from 'src/Utils/globalHelpers'
import { ConfirmationContext } from 'src/Confirmation/ConfirmationContext'
import { useConfirmationModal } from 'src/components/ModalForms'
import { isMarkdown } from 'src/components/ModalForms/FileType/useFileTypeIcon'
import { PolicyAcknowledgementFormData } from 'src/context/FormContext/PolicyAcknowledgementContext'
import { useAuth } from 'src/context/authContext'
import {
  LINKER_MODEL_IDS,
  PickerConfiguration,
} from 'src/lib/Linkers/Linkers.constants'
import { useLoadGoogleApi } from 'src/lib/Linkers/Linkers.hooks'
import { useGoogleDriveContext } from 'src/lib/Linkers/context/GoogleDriveContext'
import { useSwrImmutableGrpc } from '../../components/async/useSwrImmutableGrpc'
import { ToastPrompts, showInfoToast } from '../../Utils/helpers/toast'
import { useAuthorizedGrpcClient } from '../../adapter'
import { useInvalidatePoliciesCache } from '../../components/async/policy/usePolicies'
import { useInAudit } from '../../context/AuditContext'
import { useDocumentOnClick } from './PoliciesShowPage/DocumentGrid/DocumentItem/useDocumentOnClick'
import { editDocumentMarkdown } from './Policies.helpers'

// [AP-4811] TODO: Move all policy hooks into this file

/**
 * This hook returns a function that can be used to add or update a policy.
 * We can add more parameters as needed as we continue to use it.
 */

export const useAddOrUpdatePolicy = (): (({
  modelId,
  description,
}: {
  modelId: string
  description: string
}) => Promise<PolicyRecord>) => {
  const mutator = useInvalidatePoliciesCache()
  const policyClient = useAuthorizedGrpcClient(PolicyPromiseClient)

  return async ({
    modelId,
    description,
  }: {
    modelId: string
    description: string
  }): Promise<PolicyRecord> => {
    const request = new CreateOrUpdatePolicyRequest()

    if (modelId) {
      request.setModelId(modelId)
    }
    if (description) {
      request.setDescription(new StringValue().setValue(description))
    }

    const response = await policyClient.createOrUpdate(request)
    await mutator()
    return response
  }
}

/**
 * This hook returns a function that can be used to generate a policy acknowledgement
 * form. If the return function's override parameter is not set to true, the function
 * will return a response that indicates whether any in-scope policies are missing
 * documentation in a supported format (link, markdown, pdf or docx). If no policies
 * are missing documents, or if override is set to true, the function will return a
 * policy acknowledgement form.
 * @returns {Function} A function that accepts an override parameter and returns a policy
 *                      acknowledgement form or a response indicating missing documentation.
 */

export const useGetPolicyAcknowledgementForm = (): ((
  policyIds: string[],
  override: boolean,
  includeText: boolean,
  formData: PolicyAcknowledgementFormData,
) => Promise<GetPolicyAcknowledgementFormResponse>) => {
  const { auditId } = useInAudit()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  return async (
    policyIds: string[],
    override: boolean,
    includeText: boolean,
    formData: PolicyAcknowledgementFormData,
  ) => {
    const request = new GetPolicyAcknowledgementFormRequest()
    auditId && request.setAuditId(new StringValue().setValue(auditId))
    override && request.setOverride(new BoolValue().setValue(override))
    includeText && request.setIncludeText(new BoolValue().setValue(includeText))
    request.setPolicyIdsList(policyIds)
    request.setFormData(
      new PolicyAcknowledgementFormValues()
        .setCompanyName(formData.companyName)
        .setTitle(formData.title)
        .setText(formData.text),
    )
    let response
    try {
      response = await attachmentClient.getPolicyAcknowledgementForm(request)
    } catch (err) {
      const errString = auditId
        ? `Error generating policy acknowledgement form for audit ${auditId}: `
        : 'Error generating policy acknowledgement form: '
      log.error(errString, err)
      showInfoToast(ToastPrompts.POLICY_ACKNOWLEDGEMENT_FORM_ERROR)
    }

    return response || new GetPolicyAcknowledgementFormResponse()
  }
}

export const useGetPolicyFrameworks = (
  policyId: string,
): GrpcResponse<Frameworks> => {
  const { response } = useSwrImmutableGrpc(
    PolicyPromiseClient.prototype.getFrameworks,
    new GetPolicyFrameworksRequest().setId(policyId),
  )
  return NewGrpcResponse(response)
}

export const useGetPolicyDocumentsByModelId = (
  policyModelId = '',
  policyExists = true,
): GrpcResponse<Documents> => {
  const { auditId } = useInAudit()

  const request = new GetPolicyDocumentsByPolicyIDRequest().setPolicyModelId(
    policyModelId,
  )

  if (auditId) {
    request.setAuditId(new StringValue().setValue(auditId))
  }

  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.getPolicyDocumentsByPolicyID,
    request,
    policyExists,
  )
  return NewGrpcResponse(response)
}

export const usePolicyDocumentOnClick = (
  documentId: string,
  caption: string,
  mime: string,
  contentId: string,
  actorType: ACTOR_TYPE,
  setMarkdownBody: React.Dispatch<React.SetStateAction<string>>,
  linkerModelId?: LINKER_MODEL_IDS,
): React.MouseEventHandler | undefined => {
  const navigate = useNavigate()
  const location = useLocation()

  const getDocumentBodyFromDb = useDocumentOnClick({
    documentId,
    setMarkdownBody,
  })

  const getLinkedDocumentUrl = useGetLinkedDocumentUrl(contentId)
  const getFileUrl = useGetDownloadUrl(contentId)
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)

  if (!contentId) {
    return getDocumentBodyFromDb
  }
  if (linkerModelId && linkerModelId.length > 0) {
    return async () => {
      const filePath = await getLinkedDocumentUrl()
      openInNewTab(filePath?.getUrl())
    }
  }

  return async () => {
    try {
      const body = await fetchDocumentBody()
      if (!body) {
        showInfoToast(ToastPrompts.DOC_DOWNLOAD_ERROR)
        return
      }
      if (isMarkdown(mime)) {
        const material = body.toString()
        editDocumentMarkdown(
          documentId,
          material,
          setMarkdownBody,
          navigate,
          location,
        )
      } else {
        // preview file in a new tab if its not markdown
        const filePath = await getFileUrl()
        openInNewTab(filePath?.getUrl())
      }
    } catch (err) {
      log.error(`Error viewing policy documentId: ${documentId}`, err)
    }
  }
}

export const useRevalidatePolicyDocuments = (modelId: string): (() => void) => {
  const { mutate } = useGetPolicyDocumentsByModelId(modelId)
  return mutate
}

export const useLinkPolicyDocsConfig = (
  modelId: string,
): PickerConfiguration | null => {
  const ready = useLoadGoogleApi()
  const mutate = useRevalidatePolicyDocuments(modelId)
  const { email } = useAuth()
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const { setPickerConfig } = useGoogleDriveContext()

  if (!ready) {
    return null
  }

  return {
    cbFunc: async (tokendata: {
      action: string
      docs: google.picker.DocumentObject[]
    }) => {
      const request = new LinkDocumentsRequest()
      // reset the google picker config
      setPickerConfig(null)
      if (!tokendata || !tokendata.docs) {
        return
      }
      const documents = tokendata.docs.map(
        (doc: google.picker.DocumentObject) => {
          const req = new LinkDocumentRequest()
          const document = new DocumentMsg()
            .setCaption(doc.name)
            .setMime(doc.mimeType)
            .setActor(email)
            .setActortype(ACTOR_TYPE.USER)
            .setDoctype(DOCUMENT_TYPE.POLICYDOC)
            .setBody('')
            .setSubjectmodeltype(MODEL_TYPE.POLICY)
            .setLinkerModelId(LINKER_MODEL_IDS.GOOGLE_DRIVE)
            .setSubjectmodelid(modelId)
          return req.setDocument(document).setUrl(doc.url)
        },
      )
      request.setDocumentsList(documents)
      await attachmentClient.linkDocuments(request)
      mutate()
    },
    allowMultiselect: true,
    viewId: google.picker.ViewId.DOCS,
  }
}

export const useConnectPoliciesConfig = (): PickerConfiguration | null => {
  const ready = useLoadGoogleApi()
  const { setPickerConfig } = useGoogleDriveContext()
  const client = useAuthorizedGrpcClient(LinkerPromiseClient)

  if (!ready) {
    return null
  }

  return {
    cbFunc: async (tokendata: {
      action: string
      docs: google.picker.DocumentObject[]
    }) => {
      // reset the google picker config
      setPickerConfig(null)
      if (!tokendata || !tokendata.docs) {
        return
      }
      const path = tokendata.docs[0].url
      const request = new GetGoogleDrivePoliciesRequest().setPath(path)
      await client.getGoogleDrivePolicies(request)
    },
    allowMultiselect: false,
    viewId: google.picker.ViewId.FOLDERS,
  }
}

export const useConfirmDeletePolicyDoc = ({
  policyModelId,
  documentId,
  isLinked,
  setDeleting,
}: {
  policyModelId: string
  documentId: string
  isLinked: boolean
  setDeleting: React.Dispatch<React.SetStateAction<boolean>>
}): (() => void) => {
  const { setConfirmationState } = useContext(ConfirmationContext)

  const mutate = useRevalidatePolicyDocuments(policyModelId)
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const verb = isLinked ? 'Unlink' : 'Delete'
  const errorVerb = isLinked ? 'unlinking' : 'deleting'

  const deletePolicyDoc = async () => {
    try {
      const deleteDocumentRequest = new DeleteDocumentRequest().setDocumentId(
        documentId,
      )
      await attachmentClient.deleteDocument(deleteDocumentRequest)
    } catch (err) {
      setDeleting(false)
      setConfirmationState({
        isShown: false,
        title: '',
        body: '',
        confirmText: '',
        onConfirmCB: () => null,
      })
      showInfoToast(
        `There was an error ${errorVerb} the vendor. Please try again.`,
      )
    } finally {
      mutate()
    }
  }
  const confirmationModalProps = {
    title: `Are you sure you want to ${verb.toLowerCase()} the policy document?`,
    body: 'This cannot be undone.',
    confirmText: `${verb} Policy Document`,
    redirectTo: '.',
    onConfirmCB: deletePolicyDoc,
    onCancel: () => setDeleting(false),
  }

  return useConfirmationModal(confirmationModalProps)
}
