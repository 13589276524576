import React, { ChangeEventHandler, useMemo } from 'react'
import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { StyledLabel } from 'src/components/Reusable/Inputs/TextInput/styles'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import { FieldDef } from 'src/xgenerated/receptor'
import { SelectDropdown } from 'src/components/Reusable/SelectDropdown/SelectDropdown'
import { ReceptorStaticHelper } from 'src/context/Content/statichelpers'
import { Tabs, TabType } from 'src/components/Reusable/Tabs'
import { getSelectedItems } from './ActivateReceptorForm.helpers'
import { InputType } from './ActivateReceptorForm.constants'
import {
  ReceptorFormErrorMsg,
  ReceptorFormInput,
} from './ActivateReceptorForm.styles'

export const TenantIdField = ({
  receptor,
  value,
  onChange,
  errorMsg,
  show,
  isRequired = true,
}: {
  receptor: ReceptorRecord.AsObject
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  errorMsg: string
  show: boolean
  isRequired?: boolean
}): JSX.Element => {
  /**
   * Disable the input for existing receptors
   * - Meaning: Once the `tenantid` has been set, we do not allow any edits
   */
  const disableInput = useMemo(() => Boolean(receptor.tenantid), [receptor])
  const failedTenantId = useMemo(
    () => errorMsg === 'Account nickname already exists',
    [errorMsg],
  )
  const status = useMemo(
    () => (failedTenantId ? 'error' : 'default'),
    [failedTenantId],
  )

  return show ? (
    <StyledLabel>
      <p>Nickname</p>
      <ReceptorFormInput
        required={isRequired}
        type="text"
        disabled={disableInput}
        name="tenant-id"
        value={value}
        placeholder="Nickname"
        onChange={onChange}
        status={status}
      />
      <ReceptorFormErrorMsg status={status}>
        {failedTenantId && errorMsg}
      </ReceptorFormErrorMsg>
    </StyledLabel>
  ) : (
    <></>
  )
}

export const ReceptorCredentialsField = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  errorMsg,
  type,
  isRequired = true,
}: {
  receptor: ReceptorRecord.AsObject
  type: InputType
  label: string
  name: string
  value: string
  placeholder: string
  onChange: ChangeEventHandler<HTMLInputElement>
  errorMsg: string | JSX.Element
  isRequired?: boolean
}): JSX.Element => {
  const status = useMemo(() => (errorMsg ? 'error' : 'default'), [errorMsg])
  if (name === 'oauth') {
    return <></>
  }

  return (
    <StyledLabel>
      <p>{label}</p>
      <ReceptorFormInput
        required={isRequired}
        disabled={false}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        status={status}
        type={type}
      />
      <ReceptorFormErrorMsg status={status}>{errorMsg}</ReceptorFormErrorMsg>
    </StyledLabel>
  )
}

export const ActivateReceptorFormFields = ({
  receptor,
  fields,
  credentials,
  errorMsg,
  updateCredentials,
  authMethod,
  isShown = true,
}: {
  receptor: ReceptorRecord.AsObject
  fields: FieldDef[]
  credentials: Record<string, string>
  errorMsg: string | JSX.Element
  updateCredentials: (field: string, newVal: string) => void
  authMethod?: string
  isShown?: boolean
}): JSX.Element => {
  const invalidCredentialsMsg =
    ReceptorStaticHelper.isAccredited(receptor) &&
    receptor.isenabled &&
    !receptor.iscredvalid
      ? 'Invalid credentials'
      : ''

  const onChangeCredentials: ChangeEventHandler<HTMLInputElement> = (e) => {
    const field = e.currentTarget.name
    const newVal = e.currentTarget.value
    updateCredentials(field, newVal)
  }

  const getOnSelect = (field: string) => {
    return (items: SelectItem[]) => {
      const values = items.map((item) => item.value).join(',')
      updateCredentials(field, values)
    }
  }
  const displayFields = fields.filter(({ method }) => {
    if (authMethod) {
      return method === authMethod
    }
    return true
  })

  return (
    <>
      {displayFields.map(({ display, field, placeholder }, index) => {
        const type = (fields[index].input_type as InputType) || InputType.TEXT
        switch (type) {
          case InputType.SELECT: {
            const options = fields[index].options || []
            const selectedItems = getSelectedItems(
              options,
              credentials[field] || '',
            )
            return (
              <SelectDropdown
                key={field}
                label={display}
                defaultValues={selectedItems}
                items={fields[index].options}
                onSelectCb={getOnSelect(field)}
                placeholder={placeholder}
                errMsg={errorMsg || invalidCredentialsMsg}
              />
            )
          }
          case InputType.TEXT:
          case InputType.PASSWORD:
          default:
            return (
              <ReceptorCredentialsField
                key={field}
                receptor={receptor}
                type={type}
                label={display}
                name={field}
                value={credentials[field.toLocaleLowerCase()] || ''}
                placeholder={placeholder}
                onChange={onChangeCredentials}
                errorMsg={errorMsg || invalidCredentialsMsg}
                isRequired={isShown}
              />
            )
        }
      })}
    </>
  )
}

export const ActivateReceptorFormTabs = ({
  tabs,
  updateMethod,
  defaultActiveKey,
}: {
  tabs: TabType[]
  updateMethod: (idx: number) => void
  defaultActiveKey: number
}): JSX.Element => {
  const onTabSelect = (idxString: string | null) => {
    if (!idxString) {
      return
    }
    updateMethod(Number(idxString))
  }

  return (
    <Tabs
      tabs={tabs}
      onSelect={onTabSelect}
      defaultActiveKey={defaultActiveKey}
    />
  )
}
