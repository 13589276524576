import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  DOCUMENT_TYPE,
  Document,
  Documents,
  BulkAddEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import log from 'loglevel'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../adapter/grpcClient'
import { showInfoToast, ToastPrompts } from '../../../Utils/helpers/toast'
import { useInvalidateEvidence } from '../../../Utils/swrCacheInvalidation'
import { EvidenceFormData } from '../../ModalForms/Evidence/AddEvidence/AddEvidenceForm'
import { useAnalytics } from '../../../analytics/useAnalytics'

type BulkCreateEvidenceArgs = {
  controlId?: string
  modelId?: string
  requestId?: string
}

export const useBulkCreateEvidence = ({
  controlId,
  modelId,
  requestId,
}: BulkCreateEvidenceArgs): ((
  files: EvidenceFormData[],
  caption: string,
  date: Timestamp,
  uploadSize: number,
) => Promise<void>) => {
  const invalidateEvidence = useInvalidateEvidence()
  const attachmentClient = useAuthorizedGrpcClientWithContentUpdate(
    AttachmentPromiseClient,
  )
  const { track, events } = useAnalytics()
  return async (
    files: EvidenceFormData[],
    caption: string,
    date: Timestamp,
    uploadSize: number,
  ) => {
    const filesCount = files.length
    const evidence = new Documents()

    for (let i = 0; i < filesCount; i++) {
      const file = files[i]
      const newCaption =
        filesCount > 1 ? `${caption.trim()} ${i + 1}` : `${caption.trim()}`
      const bodyBytes = new Uint8Array(
        await new Response(file.body).arrayBuffer(),
      )
      const newEvidence = new Document()
        .setDoctype(DOCUMENT_TYPE.EVIDENCE)
        .setMime(file.mime)
        .setBody(bodyBytes)
        .setCaption(newCaption)
        .setRelevantdate(date)
        .setSubjectmodeltype(MODEL_TYPE.CONTROL)
      if (controlId) {
        newEvidence.setSubjectid(controlId)
      }
      if (requestId) {
        newEvidence.setRequestid(requestId)
      }
      evidence.addDocuments(newEvidence)
    }

    try {
      track(events.S_EVIDENCE_UPLOAD, { count: filesCount, uploadSize })
      const request = new BulkAddEvidenceRequest()
        .setDocuments(evidence)
        .setUploadSize(uploadSize)
      if (controlId) {
        request.setIdentifier(
          new Identifier()
            .setModelid(modelId || '')
            .setModeltype(MODEL_TYPE.CONTROL),
        )
      }
      if (requestId) {
        request.setRequestId(new StringValue().setValue(requestId))
      }
      await attachmentClient.bulkAddEvidence(request)
      await invalidateEvidence()
    } catch (error) {
      log.error(
        `Error when adding new evidence in useBulkCreateEvidence hook for ${
          controlId ? `control id: ${controlId}` : `request id: ${requestId}`
        }`,
        error,
      )
      showInfoToast(ToastPrompts.EVIDENCE_UPLOAD_FAILED)
    }
  }
}
