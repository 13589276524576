import { UserRecord } from '@trustero/trustero-api-web/lib/account/account_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { Model } from '../context/Content/defs'
import { Overview, Receptor, Service } from '../xgenerated'

export const isOverview = (model: {
  id: string | undefined
}): model is Overview => {
  if (typeof model !== 'object' || model === null || !('id' in model)) {
    return false
  }
  return (model as { id: string }).id.startsWith('tro')
}

export const isControl = (model: unknown): model is Control.AsObject => {
  if (typeof model !== 'object' || model === null || !('id' in model)) {
    return false
  }
  return (model as { id: string }).id.startsWith('trc')
}

export const isService = (model: unknown): model is Service => {
  if (typeof model !== 'object' || model === null || !('id' in model)) {
    return false
  }
  return (model as { id: string }).id.startsWith('trs')
}

export const isReceptor = (model: unknown): model is Receptor => {
  if (typeof model !== 'object' || model === null || !('id' in model)) {
    return false
  }
  return (model as { id: string }).id.startsWith('trr')
}

export const isUser = (model: unknown): model is UserRecord.AsObject => {
  return typeof model === 'object' && model !== null && 'email' in model
}

export const getModelType = (model: Model): MODEL_TYPE | Error => {
  if (isOverview(model)) return MODEL_TYPE.OVERVIEW
  if (isControl(model)) return MODEL_TYPE.CONTROL
  if (isService(model)) return MODEL_TYPE.SERVICE
  if (isReceptor(model)) return MODEL_TYPE.RECEPTOROBJ
  if (isUser(model)) return MODEL_TYPE.USEROBJ

  throw 'Unkown Model Type'
}

export const modelIdToType = (modelId: string): MODEL_TYPE => {
  if (modelId.startsWith('tro')) return MODEL_TYPE.OVERVIEW
  if (modelId.startsWith('trp')) return MODEL_TYPE.POLICY
  if (modelId.startsWith('trc')) return MODEL_TYPE.CONTROL
  if (modelId.startsWith('trs')) return MODEL_TYPE.SERVICE
  if (modelId.startsWith('trr')) return MODEL_TYPE.OVERVIEW
  if (modelId.includes('@')) return MODEL_TYPE.USEROBJ
  return MODEL_TYPE.CONTROL
}

export const getModelTypeLabel = (modelType: MODEL_TYPE): string => {
  switch (modelType) {
    case MODEL_TYPE.OVERVIEW:
      return 'overview'
    case MODEL_TYPE.POLICY:
      return 'policy'
    case MODEL_TYPE.CONTROL:
      return 'control'
    default:
      return 'unknown'
  }
}
