import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import log from 'loglevel'
import queryString from 'query-string'
import ContentLoader from 'react-content-loader'
import { ACTOR_TYPE } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { useControl } from 'src/components/async/model/control/useControl'
import { generateEvidencePermalink } from 'src/components/PageLayout/Permalink/generatePermalink'
import { DownloadIcon } from 'src/components/Icons'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { documentBodyAsFile } from 'src/adapter/AttachmentAdapter'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import FileSaver from 'file-saver'
import { PERMISSIONS } from 'src/config/roleConfig'
import { ModalForm, ModalFormId } from '../../ModalForm'
import {
  useDocument,
  useFetchDocumentBody,
} from '../../../async/document/useDocument'
import {
  ViewEvidenceQueryParams,
  generateEvidencePdf,
  generatePdfBlob,
} from './ViewEvidenceForm.utils'
import { EvidenceModal } from './EvidenceModal'
import { FileDownloadBtn, StyledPermalinkIcon } from './ViewEvidenceForm.styles'
import { useDownloadEvidenceTable } from './ViewEvidenceForm.hooks'

export const ViewEvidenceForm = ({
  titleName,
}: {
  titleName: string
}): JSX.Element => {
  const navigate = useNavigate()
  const { controlModelId, pageContext } = useParams()
  const { evidenceId } = useCurrentEvidenceId()
  const { data: control, isLoading: ctrlIsLoading } = useControl(
    controlModelId || '',
  )
  const {
    data: document,
    isLoading: docIsLoading,
    error,
  } = useDocument({
    documentId: evidenceId,
  })
  const contentId = document?.getContentid() || ''
  const mime = document?.getMime() || ''
  const downloadEvidenceTable = useDownloadEvidenceTable({
    contentId,
    mime,
  })
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const show = useIsShowModal(ModalFormId.VIEW_EVIDENCE)
  const hide = useHideModal({
    modalId: ModalFormId.VIEW_EVIDENCE,
    onHide: () => {
      navigate(
        queryString.exclude(`${location.pathname}${location.search}`, [
          ViewEvidenceQueryParams.MODAL_FORM_ID,
          ViewEvidenceQueryParams.EVIDENCE_ID,
        ]),
      )
    },
  })

  useEffect(() => {
    error && hide()
  }, [error, hide])

  if (!document || docIsLoading) {
    return <></>
  }

  const isManual = document.getActortype() === ACTOR_TYPE.USER
  const caption = formatEvidenceCaption(document.getCaption(), !isManual)
  const isLink = mime === MIME_TYPE.TEXT_URI_LIST
  const modalTitle = `${
    isManual ? 'Manual' : 'Automatic'
  } Evidence for ${titleName}`

  const copyToClipboard = async (): Promise<void> => {
    await navigator.clipboard.writeText(
      generateEvidencePermalink({
        evidenceId,
        controlId: control?.getId(),
        pageContext: pageContext || '',
      }),
    )
  }

  const downloadFile = async (): Promise<void> => {
    let body = null
    try {
      body = await fetchDocumentBody()
      if (!body) {
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
        return
      }
      const material = documentBodyAsFile(body, caption, mime)
      FileSaver.saveAs(material)
    } catch (err) {
      log.error(`Error downloading evidence - contentId: ${contentId}`, err)
    }
  }

  const downloadEvidencePdf = async (): Promise<void> => {
    let body = null
    try {
      body = await fetchDocumentBody()
      if (!body) {
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
        return
      }
      const pdfContent = await generateEvidencePdf(document, body, caption)
      const pdfBlob = await generatePdfBlob(pdfContent)
      FileSaver.saveAs(pdfBlob, `${caption}.pdf`)
    } catch (err) {
      log.error(`Error downloading evidence - contentId: ${contentId}`, err)
    }
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.VIEW_EVIDENCE}
      title={modalTitle}
      submitText={isManual ? 'Save' : 'Done'}
      enforceFocus={false}
      customCancelButton={
        <>
          {controlModelId && (ctrlIsLoading || !control) ? (
            <ContentLoader />
          ) : (
            <StandardButton
              onClick={copyToClipboard}
              buttonSize={StandardButtonSize.MEDIUM}
              variant={StandardButtonVariant.SECONDARY}
            >
              <StyledPermalinkIcon />
              Copy Permalink
            </StandardButton>
          )}
        </>
      }
      footerContent={
        <>
          {isManual ? (
            <>
              {isLink ? (
                <></>
              ) : (
                <FileDownloadBtn
                  onClick={downloadFile}
                  requiredPermissions={[PERMISSIONS.EXPORT]}
                >
                  <DownloadIcon height={14} width={14} fontSize={14} />
                  <span>Download File</span>
                </FileDownloadBtn>
              )}
            </>
          ) : (
            <>
              <FileDownloadBtn
                onClick={downloadEvidenceTable}
                requiredPermissions={[PERMISSIONS.EXPORT]}
              >
                <DownloadIcon height={14} width={14} fontSize={14} />
                <span>Download Table</span>
              </FileDownloadBtn>
              <FileDownloadBtn
                onClick={downloadEvidencePdf}
                requiredPermissions={[PERMISSIONS.EXPORT]}
              >
                <DownloadIcon height={14} width={14} fontSize={14} />
                <span>Download All Evidence</span>
              </FileDownloadBtn>
            </>
          )}
        </>
      }
    >
      <EvidenceModal document={document} onHide={hide} isManual={isManual} />
    </ModalForm>
  )
}
