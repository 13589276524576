import { DebouncedFunc } from 'lodash'
import { Dispatch, SetStateAction } from 'react'
import { RESPONSIBILITY } from '@trustero/trustero-api-web/lib/common/model_pb'
import palette from 'src/designSystem/variables/palette'
import { USER_ROLE } from '@trustero/trustero-api-web/lib/account/account_pb'
import { StringMap } from '../../../../Utils/ComplianceFramework/complianceFrameworkUtils'

export type ControlDropdownProps = {
  modelId: string
  frameworkState: StringMap
  setFrameworkState: Dispatch<SetStateAction<StringMap>>
  updateControl: DebouncedFunc<
    (newFrameworkState: StringMap, logEntryValue?: string) => Promise<void>
  >
}

export type ResponsibilityDropdownOption = {
  key: RESPONSIBILITY
  label: string
  colors: {
    text: string
    textHover: string
    background: string
    backgroundHover: string
  }
}

export enum UserRole {
  ADMIN = 'Admin',
  USER = 'User',
  READONLY = 'Read Only',
  AUDITOR = 'Auditor',
}

export const UserRoleOptions: Record<USER_ROLE, string> = Object.freeze({
  [USER_ROLE.ADMIN]: UserRole.ADMIN,
  [USER_ROLE.USER]: UserRole.USER,
  [USER_ROLE.READONLY]: UserRole.READONLY,
  [USER_ROLE.AUDITOR]: UserRole.AUDITOR,
})

export const UserRoleDropdownOptions = [
  {
    key: USER_ROLE.ADMIN,
    label: UserRoleOptions[USER_ROLE.ADMIN],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: USER_ROLE.USER,
    label: UserRoleOptions[USER_ROLE.USER],
    colors: {
      text: palette.neutral.white,
      textHover: palette.blue['50'],
      background: palette.neutral['400'],
      backgroundHover: palette.neutral['900'],
    },
  },
  {
    key: USER_ROLE.READONLY,
    label: UserRoleOptions[USER_ROLE.READONLY],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: USER_ROLE.AUDITOR,
    label: UserRoleOptions[USER_ROLE.AUDITOR],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
]

export enum Responsibility {
  DIRECT_RESPONSIBILITY = 'Direct Responsibility',
  OUTSOURCED_NO_ATTESTATION = 'Outsourced - No Attestation',
  OUTSOURCED_ATTESTATION = 'Outsourced - Attestation',
  INHERITED = 'Inherited',
  NOT_APPLICABLE = 'Not Applicable',
  TMP_OUTSOURCED = 'Outsourced',
}

export const ResponsibilityOptions: Record<RESPONSIBILITY, string> =
  Object.freeze({
    [RESPONSIBILITY.DIRECT_RESPONSIBILITY]:
      Responsibility.DIRECT_RESPONSIBILITY,
    [RESPONSIBILITY.INHERITED]: Responsibility.INHERITED,
    [RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION]:
      Responsibility.OUTSOURCED_NO_ATTESTATION,
    [RESPONSIBILITY.OUTSOURCED_ATTESTATION]:
      Responsibility.OUTSOURCED_ATTESTATION,
    [RESPONSIBILITY.NOT_APPLICABLE]: Responsibility.NOT_APPLICABLE,
    [RESPONSIBILITY.TMP_OUTSOURCED]: Responsibility.TMP_OUTSOURCED,
  })

export const ResponsibilityCopy: Record<RESPONSIBILITY, string> = Object.freeze(
  {
    [RESPONSIBILITY.DIRECT_RESPONSIBILITY]: 'a direct responsibility',
    [RESPONSIBILITY.INHERITED]: 'an inherited responsibility',
    [RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION]:
      'outsourced to a vendor with no attestation',
    [RESPONSIBILITY.OUTSOURCED_ATTESTATION]:
      'outsourced to a vendor with an attestation',
    [RESPONSIBILITY.NOT_APPLICABLE]: 'not applicable',
    [RESPONSIBILITY.TMP_OUTSOURCED]: 'outsourced',
  },
)

export const ResponsibilityToOptions: Record<string, RESPONSIBILITY> =
  Object.freeze({
    [Responsibility.DIRECT_RESPONSIBILITY]:
      RESPONSIBILITY.DIRECT_RESPONSIBILITY,
    [Responsibility.OUTSOURCED_NO_ATTESTATION]:
      RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION,
    [Responsibility.OUTSOURCED_ATTESTATION]:
      RESPONSIBILITY.OUTSOURCED_ATTESTATION,
    [Responsibility.INHERITED]: RESPONSIBILITY.INHERITED,
    [Responsibility.NOT_APPLICABLE]: RESPONSIBILITY.NOT_APPLICABLE,
  })

export const ResponsibilityDropdownOptions: ResponsibilityDropdownOption[] = [
  {
    key: RESPONSIBILITY.DIRECT_RESPONSIBILITY,
    label: ResponsibilityOptions[RESPONSIBILITY.DIRECT_RESPONSIBILITY],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION,
    label: ResponsibilityOptions[RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: RESPONSIBILITY.OUTSOURCED_ATTESTATION,
    label: ResponsibilityOptions[RESPONSIBILITY.OUTSOURCED_ATTESTATION],
    colors: {
      text: palette.neutral.white,
      textHover: palette.blue['50'],
      background: palette.neutral['400'],
      backgroundHover: palette.neutral['900'],
    },
  },
  {
    key: RESPONSIBILITY.INHERITED,
    label: ResponsibilityOptions[RESPONSIBILITY.INHERITED],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: RESPONSIBILITY.NOT_APPLICABLE,
    label: ResponsibilityOptions[RESPONSIBILITY.NOT_APPLICABLE],
    colors: {
      text: palette.neutral.white,
      textHover: palette.neutral['900'],
      background: palette.neutral['400'],
      backgroundHover: palette.blue['50'],
    },
  },
  {
    key: RESPONSIBILITY.TMP_OUTSOURCED,
    label: ResponsibilityOptions[RESPONSIBILITY.TMP_OUTSOURCED],
    colors: {
      text: palette.neutral['900'],
      textHover: palette.neutral['900'],
      background: palette.neutral['50'],
      backgroundHover: palette.blue['50'],
    },
  },
]

export const PolicyResponsibilityDropdownOptions =
  ResponsibilityDropdownOptions.filter(
    (option) =>
      option.key !== RESPONSIBILITY.OUTSOURCED_ATTESTATION &&
      option.key !== RESPONSIBILITY.OUTSOURCED_NO_ATTESTATION &&
      option.key !== RESPONSIBILITY.TMP_OUTSOURCED &&
      option.key !== RESPONSIBILITY.INHERITED,
  )
