// source: model/control.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var account_account_pb = require('../account/account_pb.js');
goog.object.extend(proto, account_account_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var model_department_pb = require('../model/department_pb.js');
goog.object.extend(proto, model_department_pb);
var model_policy_pb = require('../model/policy_pb.js');
goog.object.extend(proto, model_policy_pb);
goog.exportSymbol('proto.model.CONTROL_STATUS', null, global);
goog.exportSymbol('proto.model.Control', null, global);
goog.exportSymbol('proto.model.ControlSort', null, global);
goog.exportSymbol('proto.model.ControlSort.control_sort_column', null, global);
goog.exportSymbol('proto.model.ControlSort.control_sort_order', null, global);
goog.exportSymbol('proto.model.ControlStatusValue', null, global);
goog.exportSymbol('proto.model.Controls', null, global);
goog.exportSymbol('proto.model.CreateUpdateControlRequest', null, global);
goog.exportSymbol('proto.model.DepartmentFilter', null, global);
goog.exportSymbol('proto.model.FrameworkFilter', null, global);
goog.exportSymbol('proto.model.GetControlRequest', null, global);
goog.exportSymbol('proto.model.GetControlsForComplianceFrameworkRequest', null, global);
goog.exportSymbol('proto.model.GetControlsInAccountForComplianceFrameworkRequest', null, global);
goog.exportSymbol('proto.model.GetHasNoControlsResponse', null, global);
goog.exportSymbol('proto.model.GetPreparedControlsBreakdownRequest', null, global);
goog.exportSymbol('proto.model.GetPreparedControlsBreakdownResponse', null, global);
goog.exportSymbol('proto.model.GetStatusBreakdownRequest', null, global);
goog.exportSymbol('proto.model.GetStatusBreakdownRequest.BreakdownType', null, global);
goog.exportSymbol('proto.model.GetStatusBreakdownResponse', null, global);
goog.exportSymbol('proto.model.GetStatusBreakdownResponse.StatusBreakdown', null, global);
goog.exportSymbol('proto.model.GetStatusBreakdownResponse.StatusBreakdown.BreakdownValueCase', null, global);
goog.exportSymbol('proto.model.ListControlsRequest', null, global);
goog.exportSymbol('proto.model.PolicyFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetStatusBreakdownRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetStatusBreakdownRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetStatusBreakdownRequest.displayName = 'proto.model.GetStatusBreakdownRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetStatusBreakdownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.GetStatusBreakdownResponse.repeatedFields_, null);
};
goog.inherits(proto.model.GetStatusBreakdownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetStatusBreakdownResponse.displayName = 'proto.model.GetStatusBreakdownResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.model.GetStatusBreakdownResponse.StatusBreakdown.oneofGroups_);
};
goog.inherits(proto.model.GetStatusBreakdownResponse.StatusBreakdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetStatusBreakdownResponse.StatusBreakdown.displayName = 'proto.model.GetStatusBreakdownResponse.StatusBreakdown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Control = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Control.repeatedFields_, null);
};
goog.inherits(proto.model.Control, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Control.displayName = 'proto.model.Control';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CreateUpdateControlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.CreateUpdateControlRequest.repeatedFields_, null);
};
goog.inherits(proto.model.CreateUpdateControlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CreateUpdateControlRequest.displayName = 'proto.model.CreateUpdateControlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DepartmentFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DepartmentFilter.repeatedFields_, null);
};
goog.inherits(proto.model.DepartmentFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DepartmentFilter.displayName = 'proto.model.DepartmentFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PolicyFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.PolicyFilter.repeatedFields_, null);
};
goog.inherits(proto.model.PolicyFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PolicyFilter.displayName = 'proto.model.PolicyFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.FrameworkFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.FrameworkFilter.repeatedFields_, null);
};
goog.inherits(proto.model.FrameworkFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.FrameworkFilter.displayName = 'proto.model.FrameworkFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ControlSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ControlSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ControlSort.displayName = 'proto.model.ControlSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ListControlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.ListControlsRequest.repeatedFields_, null);
};
goog.inherits(proto.model.ListControlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ListControlsRequest.displayName = 'proto.model.ListControlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Controls = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Controls.repeatedFields_, null);
};
goog.inherits(proto.model.Controls, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Controls.displayName = 'proto.model.Controls';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ControlStatusValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ControlStatusValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ControlStatusValue.displayName = 'proto.model.ControlStatusValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetControlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetControlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetControlRequest.displayName = 'proto.model.GetControlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetControlsForComplianceFrameworkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetControlsForComplianceFrameworkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetControlsForComplianceFrameworkRequest.displayName = 'proto.model.GetControlsForComplianceFrameworkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetControlsInAccountForComplianceFrameworkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetControlsInAccountForComplianceFrameworkRequest.displayName = 'proto.model.GetControlsInAccountForComplianceFrameworkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetHasNoControlsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetHasNoControlsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetHasNoControlsResponse.displayName = 'proto.model.GetHasNoControlsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPreparedControlsBreakdownRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPreparedControlsBreakdownRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPreparedControlsBreakdownRequest.displayName = 'proto.model.GetPreparedControlsBreakdownRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPreparedControlsBreakdownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPreparedControlsBreakdownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPreparedControlsBreakdownResponse.displayName = 'proto.model.GetPreparedControlsBreakdownResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetStatusBreakdownRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetStatusBreakdownRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetStatusBreakdownRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetStatusBreakdownRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    breakdownType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetStatusBreakdownRequest}
 */
proto.model.GetStatusBreakdownRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetStatusBreakdownRequest;
  return proto.model.GetStatusBreakdownRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetStatusBreakdownRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetStatusBreakdownRequest}
 */
proto.model.GetStatusBreakdownRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.model.GetStatusBreakdownRequest.BreakdownType} */ (reader.readEnum());
      msg.setBreakdownType(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetStatusBreakdownRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetStatusBreakdownRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetStatusBreakdownRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetStatusBreakdownRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBreakdownType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.model.GetStatusBreakdownRequest.BreakdownType = {
  DEPARTMENT: 0,
  OWNER: 1,
  POLICY: 2
};

/**
 * optional BreakdownType breakdown_type = 1;
 * @return {!proto.model.GetStatusBreakdownRequest.BreakdownType}
 */
proto.model.GetStatusBreakdownRequest.prototype.getBreakdownType = function() {
  return /** @type {!proto.model.GetStatusBreakdownRequest.BreakdownType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.model.GetStatusBreakdownRequest.BreakdownType} value
 * @return {!proto.model.GetStatusBreakdownRequest} returns this
 */
proto.model.GetStatusBreakdownRequest.prototype.setBreakdownType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.GetStatusBreakdownRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.GetStatusBreakdownRequest} returns this
*/
proto.model.GetStatusBreakdownRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetStatusBreakdownRequest} returns this
 */
proto.model.GetStatusBreakdownRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetStatusBreakdownRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.GetStatusBreakdownResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetStatusBreakdownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetStatusBreakdownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetStatusBreakdownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetStatusBreakdownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusBreakdownsList: jspb.Message.toObjectList(msg.getStatusBreakdownsList(),
    proto.model.GetStatusBreakdownResponse.StatusBreakdown.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetStatusBreakdownResponse}
 */
proto.model.GetStatusBreakdownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetStatusBreakdownResponse;
  return proto.model.GetStatusBreakdownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetStatusBreakdownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetStatusBreakdownResponse}
 */
proto.model.GetStatusBreakdownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.GetStatusBreakdownResponse.StatusBreakdown;
      reader.readMessage(value,proto.model.GetStatusBreakdownResponse.StatusBreakdown.deserializeBinaryFromReader);
      msg.addStatusBreakdowns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetStatusBreakdownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetStatusBreakdownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetStatusBreakdownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetStatusBreakdownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusBreakdownsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.GetStatusBreakdownResponse.StatusBreakdown.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.BreakdownValueCase = {
  BREAKDOWN_VALUE_NOT_SET: 0,
  DEPARTMENT: 1,
  USER: 2,
  POLICY: 3
};

/**
 * @return {proto.model.GetStatusBreakdownResponse.StatusBreakdown.BreakdownValueCase}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.getBreakdownValueCase = function() {
  return /** @type {proto.model.GetStatusBreakdownResponse.StatusBreakdown.BreakdownValueCase} */(jspb.Message.computeOneofCase(this, proto.model.GetStatusBreakdownResponse.StatusBreakdown.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetStatusBreakdownResponse.StatusBreakdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && model_department_pb.Department.toObject(includeInstance, f),
    user: (f = msg.getUser()) && account_account_pb.UserRecord.toObject(includeInstance, f),
    policy: (f = msg.getPolicy()) && model_policy_pb.PolicyRecord.toObject(includeInstance, f),
    breakdownCountsMap: (f = msg.getBreakdownCountsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetStatusBreakdownResponse.StatusBreakdown;
  return proto.model.GetStatusBreakdownResponse.StatusBreakdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new model_department_pb.Department;
      reader.readMessage(value,model_department_pb.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    case 2:
      var value = new account_account_pb.UserRecord;
      reader.readMessage(value,account_account_pb.UserRecord.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new model_policy_pb.PolicyRecord;
      reader.readMessage(value,model_policy_pb.PolicyRecord.deserializeBinaryFromReader);
      msg.setPolicy(value);
      break;
    case 4:
      var value = msg.getBreakdownCountsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetStatusBreakdownResponse.StatusBreakdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      model_department_pb.Department.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      account_account_pb.UserRecord.serializeBinaryToWriter
    );
  }
  f = message.getPolicy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      model_policy_pb.PolicyRecord.serializeBinaryToWriter
    );
  }
  f = message.getBreakdownCountsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.model.Department}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.getDepartment = function() {
  return /** @type{?proto.model.Department} */ (
    jspb.Message.getWrapperField(this, model_department_pb.Department, 1));
};


/**
 * @param {?proto.model.Department|undefined} value
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
*/
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.setDepartment = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.model.GetStatusBreakdownResponse.StatusBreakdown.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional account.UserRecord user = 2;
 * @return {?proto.account.UserRecord}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.getUser = function() {
  return /** @type{?proto.account.UserRecord} */ (
    jspb.Message.getWrapperField(this, account_account_pb.UserRecord, 2));
};


/**
 * @param {?proto.account.UserRecord|undefined} value
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
*/
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.model.GetStatusBreakdownResponse.StatusBreakdown.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PolicyRecord policy = 3;
 * @return {?proto.model.PolicyRecord}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.getPolicy = function() {
  return /** @type{?proto.model.PolicyRecord} */ (
    jspb.Message.getWrapperField(this, model_policy_pb.PolicyRecord, 3));
};


/**
 * @param {?proto.model.PolicyRecord|undefined} value
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
*/
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.setPolicy = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.model.GetStatusBreakdownResponse.StatusBreakdown.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.clearPolicy = function() {
  return this.setPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.hasPolicy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<int32, int32> breakdown_counts = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.getBreakdownCountsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown} returns this
 */
proto.model.GetStatusBreakdownResponse.StatusBreakdown.prototype.clearBreakdownCountsMap = function() {
  this.getBreakdownCountsMap().clear();
  return this;};


/**
 * repeated StatusBreakdown status_breakdowns = 1;
 * @return {!Array<!proto.model.GetStatusBreakdownResponse.StatusBreakdown>}
 */
proto.model.GetStatusBreakdownResponse.prototype.getStatusBreakdownsList = function() {
  return /** @type{!Array<!proto.model.GetStatusBreakdownResponse.StatusBreakdown>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.GetStatusBreakdownResponse.StatusBreakdown, 1));
};


/**
 * @param {!Array<!proto.model.GetStatusBreakdownResponse.StatusBreakdown>} value
 * @return {!proto.model.GetStatusBreakdownResponse} returns this
*/
proto.model.GetStatusBreakdownResponse.prototype.setStatusBreakdownsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.GetStatusBreakdownResponse.StatusBreakdown=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.GetStatusBreakdownResponse.StatusBreakdown}
 */
proto.model.GetStatusBreakdownResponse.prototype.addStatusBreakdowns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.GetStatusBreakdownResponse.StatusBreakdown, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.GetStatusBreakdownResponse} returns this
 */
proto.model.GetStatusBreakdownResponse.prototype.clearStatusBreakdownsList = function() {
  return this.setStatusBreakdownsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Control.repeatedFields_ = [17,18,19,20,24,25,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Control.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Control.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Control} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Control.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isTemplate: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    objective: jspb.Message.getFieldWithDefault(msg, 8, ""),
    description: jspb.Message.getFieldWithDefault(msg, 9, ""),
    suggestedEvidence: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ownerEmail: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isDismissed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    status: jspb.Message.getFieldWithDefault(msg, 13, 0),
    responsibility: jspb.Message.getFieldWithDefault(msg, 14, 0),
    parentTemplateId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    frameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    recommendedServiceIdsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    relatedServiceIdsList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    receptorIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    policyId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    maturity: jspb.Message.getFieldWithDefault(msg, 22, 0),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    auditIdsList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    howToHandleIt: jspb.Message.getFieldWithDefault(msg, 26, ""),
    policyName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    requestIdsList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
    isCustomObjective: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    note: jspb.Message.getFieldWithDefault(msg, 30, ""),
    notApplicableReason: jspb.Message.getFieldWithDefault(msg, 31, ""),
    vendorId: jspb.Message.getFieldWithDefault(msg, 32, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Control}
 */
proto.model.Control.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Control;
  return proto.model.Control.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Control} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Control}
 */
proto.model.Control.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTemplate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuggestedEvidence(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerEmail(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDismissed(value);
      break;
    case 13:
      var value = /** @type {!proto.model.CONTROL_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {!proto.common.RESPONSIBILITY} */ (reader.readEnum());
      msg.setResponsibility(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentTemplateId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addFrameworkIds(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecommendedServiceIds(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelatedServiceIds(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addReceptorIds(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaturity(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditIds(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setHowToHandleIt(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyName(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequestIds(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustomObjective(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotApplicableReason(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Control.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Control.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Control} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Control.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsTemplate();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSuggestedEvidence();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOwnerEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsDismissed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getResponsibility();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getParentTemplateId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getRecommendedServiceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getRelatedServiceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getReceptorIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMaturity();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getAuditIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getHowToHandleIt();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getPolicyName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getRequestIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      28,
      f
    );
  }
  f = message.getIsCustomObjective();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getNotApplicableReason();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getVendorId();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Control.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Control} returns this
*/
proto.model.Control.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Control.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.Control.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.Control} returns this
*/
proto.model.Control.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Control.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.model.Control.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.model.Control.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model_id = 5;
 * @return {string}
 */
proto.model.Control.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_template = 6;
 * @return {boolean}
 */
proto.model.Control.prototype.getIsTemplate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setIsTemplate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.model.Control.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string objective = 8;
 * @return {string}
 */
proto.model.Control.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.model.Control.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string suggested_evidence = 10;
 * @return {string}
 */
proto.model.Control.prototype.getSuggestedEvidence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setSuggestedEvidence = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string owner_email = 11;
 * @return {string}
 */
proto.model.Control.prototype.getOwnerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_dismissed = 12;
 * @return {boolean}
 */
proto.model.Control.prototype.getIsDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setIsDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional CONTROL_STATUS status = 13;
 * @return {!proto.model.CONTROL_STATUS}
 */
proto.model.Control.prototype.getStatus = function() {
  return /** @type {!proto.model.CONTROL_STATUS} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.model.CONTROL_STATUS} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional common.RESPONSIBILITY responsibility = 14;
 * @return {!proto.common.RESPONSIBILITY}
 */
proto.model.Control.prototype.getResponsibility = function() {
  return /** @type {!proto.common.RESPONSIBILITY} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.common.RESPONSIBILITY} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setResponsibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string parent_template_id = 15;
 * @return {string}
 */
proto.model.Control.prototype.getParentTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setParentTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string department_id = 16;
 * @return {string}
 */
proto.model.Control.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated string framework_ids = 17;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearFrameworkIdsList = function() {
  return this.setFrameworkIdsList([]);
};


/**
 * repeated string recommended_service_ids = 18;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getRecommendedServiceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setRecommendedServiceIdsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addRecommendedServiceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearRecommendedServiceIdsList = function() {
  return this.setRecommendedServiceIdsList([]);
};


/**
 * repeated string related_service_ids = 19;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getRelatedServiceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setRelatedServiceIdsList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addRelatedServiceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearRelatedServiceIdsList = function() {
  return this.setRelatedServiceIdsList([]);
};


/**
 * repeated string receptor_ids = 20;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getReceptorIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setReceptorIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addReceptorIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearReceptorIdsList = function() {
  return this.setReceptorIdsList([]);
};


/**
 * optional string policy_id = 21;
 * @return {string}
 */
proto.model.Control.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 maturity = 22;
 * @return {number}
 */
proto.model.Control.prototype.getMaturity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setMaturity = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool is_custom = 23;
 * @return {boolean}
 */
proto.model.Control.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * repeated string audit_ids = 24;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getAuditIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setAuditIdsList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addAuditIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearAuditIdsList = function() {
  return this.setAuditIdsList([]);
};


/**
 * repeated string compliance_framework_ids = 25;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};


/**
 * optional string how_to_handle_it = 26;
 * @return {string}
 */
proto.model.Control.prototype.getHowToHandleIt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setHowToHandleIt = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string policy_name = 27;
 * @return {string}
 */
proto.model.Control.prototype.getPolicyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setPolicyName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * repeated string request_ids = 28;
 * @return {!Array<string>}
 */
proto.model.Control.prototype.getRequestIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setRequestIdsList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.addRequestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.clearRequestIdsList = function() {
  return this.setRequestIdsList([]);
};


/**
 * optional bool is_custom_objective = 29;
 * @return {boolean}
 */
proto.model.Control.prototype.getIsCustomObjective = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setIsCustomObjective = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional string note = 30;
 * @return {string}
 */
proto.model.Control.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string not_applicable_reason = 31;
 * @return {string}
 */
proto.model.Control.prototype.getNotApplicableReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setNotApplicableReason = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string vendor_id = 32;
 * @return {string}
 */
proto.model.Control.prototype.getVendorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Control} returns this
 */
proto.model.Control.prototype.setVendorId = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.CreateUpdateControlRequest.repeatedFields_ = [22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CreateUpdateControlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CreateUpdateControlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CreateUpdateControlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CreateUpdateControlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: (f = msg.getModelId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isTemplate: (f = msg.getIsTemplate()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    objective: (f = msg.getObjective()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    suggestedEvidence: (f = msg.getSuggestedEvidence()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    ownerEmail: (f = msg.getOwnerEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.model.ControlStatusValue.toObject(includeInstance, f),
    responsibility: (f = msg.getResponsibility()) && common_model_pb.ResponsibilityValue.toObject(includeInstance, f),
    parentTemplateId: (f = msg.getParentTemplateId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    departmentId: (f = msg.getDepartmentId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    frameworkIds: (f = msg.getFrameworkIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    recommendedServiceIds: (f = msg.getRecommendedServiceIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    relatedServiceIds: (f = msg.getRelatedServiceIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    receptorIds: (f = msg.getReceptorIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    policyId: (f = msg.getPolicyId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    maturity: (f = msg.getMaturity()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isCustom: (f = msg.getIsCustom()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    complianceFrameworkIds: (f = msg.getComplianceFrameworkIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    logEntryValue: (f = msg.getLogEntryValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    howToHandleIt: (f = msg.getHowToHandleIt()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    observedTestsList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    requestIds: (f = msg.getRequestIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    note: (f = msg.getNote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 25, ""),
    notApplicableReason: (f = msg.getNotApplicableReason()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    requiredEvidence: (f = msg.getRequiredEvidence()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    testProcedures: (f = msg.getTestProcedures()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    vendorId: (f = msg.getVendorId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CreateUpdateControlRequest}
 */
proto.model.CreateUpdateControlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CreateUpdateControlRequest;
  return proto.model.CreateUpdateControlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CreateUpdateControlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CreateUpdateControlRequest}
 */
proto.model.CreateUpdateControlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setModelId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsTemplate(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setObjective(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSuggestedEvidence(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOwnerEmail(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 9:
      var value = new proto.model.ControlStatusValue;
      reader.readMessage(value,proto.model.ControlStatusValue.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 29:
      var value = new common_model_pb.ResponsibilityValue;
      reader.readMessage(value,common_model_pb.ResponsibilityValue.deserializeBinaryFromReader);
      msg.setResponsibility(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setParentTemplateId(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDepartmentId(value);
      break;
    case 12:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setFrameworkIds(value);
      break;
    case 13:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setRecommendedServiceIds(value);
      break;
    case 14:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setRelatedServiceIds(value);
      break;
    case 15:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setReceptorIds(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPolicyId(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaturity(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsCustom(value);
      break;
    case 19:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setComplianceFrameworkIds(value);
      break;
    case 20:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLogEntryValue(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setHowToHandleIt(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addObservedTests(value);
      break;
    case 23:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setRequestIds(value);
      break;
    case 24:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 26:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNotApplicableReason(value);
      break;
    case 27:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequiredEvidence(value);
      break;
    case 28:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTestProcedures(value);
      break;
    case 30:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVendorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CreateUpdateControlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CreateUpdateControlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CreateUpdateControlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CreateUpdateControlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsTemplate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getObjective();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSuggestedEvidence();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOwnerEmail();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.model.ControlStatusValue.serializeBinaryToWriter
    );
  }
  f = message.getResponsibility();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      common_model_pb.ResponsibilityValue.serializeBinaryToWriter
    );
  }
  f = message.getParentTemplateId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentId();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFrameworkIds();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getRecommendedServiceIds();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getRelatedServiceIds();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getReceptorIds();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getPolicyId();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMaturity();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsCustom();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getComplianceFrameworkIds();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getLogEntryValue();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getHowToHandleIt();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getObservedTestsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getRequestIds();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getNotApplicableReason();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRequiredEvidence();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTestProcedures();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getVendorId();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue model_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getModelId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setModelId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearModelId = function() {
  return this.setModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasModelId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue is_template = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getIsTemplate = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setIsTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearIsTemplate = function() {
  return this.setIsTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasIsTemplate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue objective = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getObjective = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setObjective = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearObjective = function() {
  return this.setObjective(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasObjective = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue description = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue suggested_evidence = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getSuggestedEvidence = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setSuggestedEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearSuggestedEvidence = function() {
  return this.setSuggestedEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasSuggestedEvidence = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue owner_email = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getOwnerEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearOwnerEmail = function() {
  return this.setOwnerEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasOwnerEmail = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ControlStatusValue status = 9;
 * @return {?proto.model.ControlStatusValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getStatus = function() {
  return /** @type{?proto.model.ControlStatusValue} */ (
    jspb.Message.getWrapperField(this, proto.model.ControlStatusValue, 9));
};


/**
 * @param {?proto.model.ControlStatusValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ResponsibilityValue responsibility = 29;
 * @return {?proto.common.ResponsibilityValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getResponsibility = function() {
  return /** @type{?proto.common.ResponsibilityValue} */ (
    jspb.Message.getWrapperField(this, common_model_pb.ResponsibilityValue, 29));
};


/**
 * @param {?proto.common.ResponsibilityValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setResponsibility = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearResponsibility = function() {
  return this.setResponsibility(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasResponsibility = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional google.protobuf.StringValue parent_template_id = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getParentTemplateId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setParentTemplateId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearParentTemplateId = function() {
  return this.setParentTemplateId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasParentTemplateId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue department_id = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getDepartmentId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearDepartmentId = function() {
  return this.setDepartmentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasDepartmentId = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.StringList framework_ids = 12;
 * @return {?proto.common.StringList}
 */
proto.model.CreateUpdateControlRequest.prototype.getFrameworkIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 12));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setFrameworkIds = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearFrameworkIds = function() {
  return this.setFrameworkIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasFrameworkIds = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.StringList recommended_service_ids = 13;
 * @return {?proto.common.StringList}
 */
proto.model.CreateUpdateControlRequest.prototype.getRecommendedServiceIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 13));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setRecommendedServiceIds = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearRecommendedServiceIds = function() {
  return this.setRecommendedServiceIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasRecommendedServiceIds = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.StringList related_service_ids = 14;
 * @return {?proto.common.StringList}
 */
proto.model.CreateUpdateControlRequest.prototype.getRelatedServiceIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 14));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setRelatedServiceIds = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearRelatedServiceIds = function() {
  return this.setRelatedServiceIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasRelatedServiceIds = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional common.StringList receptor_ids = 15;
 * @return {?proto.common.StringList}
 */
proto.model.CreateUpdateControlRequest.prototype.getReceptorIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 15));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setReceptorIds = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearReceptorIds = function() {
  return this.setReceptorIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasReceptorIds = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue policy_id = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getPolicyId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setPolicyId = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearPolicyId = function() {
  return this.setPolicyId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasPolicyId = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Int32Value maturity = 17;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.model.CreateUpdateControlRequest.prototype.getMaturity = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 17));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setMaturity = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearMaturity = function() {
  return this.setMaturity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasMaturity = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.BoolValue is_custom = 18;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getIsCustom = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 18));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setIsCustom = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearIsCustom = function() {
  return this.setIsCustom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasIsCustom = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional common.StringList compliance_framework_ids = 19;
 * @return {?proto.common.StringList}
 */
proto.model.CreateUpdateControlRequest.prototype.getComplianceFrameworkIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 19));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setComplianceFrameworkIds = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearComplianceFrameworkIds = function() {
  return this.setComplianceFrameworkIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasComplianceFrameworkIds = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional google.protobuf.StringValue log_entry_value = 20;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getLogEntryValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 20));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setLogEntryValue = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearLogEntryValue = function() {
  return this.setLogEntryValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasLogEntryValue = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.StringValue how_to_handle_it = 21;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getHowToHandleIt = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 21));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setHowToHandleIt = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearHowToHandleIt = function() {
  return this.setHowToHandleIt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasHowToHandleIt = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * repeated string observed_tests = 22;
 * @return {!Array<string>}
 */
proto.model.CreateUpdateControlRequest.prototype.getObservedTestsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.setObservedTestsList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.addObservedTests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearObservedTestsList = function() {
  return this.setObservedTestsList([]);
};


/**
 * optional common.StringList request_ids = 23;
 * @return {?proto.common.StringList}
 */
proto.model.CreateUpdateControlRequest.prototype.getRequestIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 23));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setRequestIds = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearRequestIds = function() {
  return this.setRequestIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasRequestIds = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.StringValue note = 24;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getNote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 24));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasNote = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string id = 25;
 * @return {string}
 */
proto.model.CreateUpdateControlRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional google.protobuf.StringValue not_applicable_reason = 26;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getNotApplicableReason = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 26));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setNotApplicableReason = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearNotApplicableReason = function() {
  return this.setNotApplicableReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasNotApplicableReason = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional google.protobuf.StringValue required_evidence = 27;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getRequiredEvidence = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 27));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setRequiredEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearRequiredEvidence = function() {
  return this.setRequiredEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasRequiredEvidence = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.StringValue test_procedures = 28;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getTestProcedures = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 28));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setTestProcedures = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearTestProcedures = function() {
  return this.setTestProcedures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasTestProcedures = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional google.protobuf.StringValue vendor_id = 30;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateUpdateControlRequest.prototype.getVendorId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 30));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateUpdateControlRequest} returns this
*/
proto.model.CreateUpdateControlRequest.prototype.setVendorId = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateUpdateControlRequest} returns this
 */
proto.model.CreateUpdateControlRequest.prototype.clearVendorId = function() {
  return this.setVendorId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateUpdateControlRequest.prototype.hasVendorId = function() {
  return jspb.Message.getField(this, 30) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DepartmentFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DepartmentFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DepartmentFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DepartmentFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DepartmentFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    departmentIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    includeNoDepartment: (f = msg.getIncludeNoDepartment()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DepartmentFilter}
 */
proto.model.DepartmentFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DepartmentFilter;
  return proto.model.DepartmentFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DepartmentFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DepartmentFilter}
 */
proto.model.DepartmentFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartmentIds(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNoDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DepartmentFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DepartmentFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DepartmentFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DepartmentFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartmentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIncludeNoDepartment();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string department_ids = 1;
 * @return {!Array<string>}
 */
proto.model.DepartmentFilter.prototype.getDepartmentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.DepartmentFilter} returns this
 */
proto.model.DepartmentFilter.prototype.setDepartmentIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.DepartmentFilter} returns this
 */
proto.model.DepartmentFilter.prototype.addDepartmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DepartmentFilter} returns this
 */
proto.model.DepartmentFilter.prototype.clearDepartmentIdsList = function() {
  return this.setDepartmentIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_no_department = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.DepartmentFilter.prototype.getIncludeNoDepartment = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.DepartmentFilter} returns this
*/
proto.model.DepartmentFilter.prototype.setIncludeNoDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.DepartmentFilter} returns this
 */
proto.model.DepartmentFilter.prototype.clearIncludeNoDepartment = function() {
  return this.setIncludeNoDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.DepartmentFilter.prototype.hasIncludeNoDepartment = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.PolicyFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PolicyFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PolicyFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PolicyFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyModelIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    includeNoPolicy: (f = msg.getIncludeNoPolicy()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PolicyFilter}
 */
proto.model.PolicyFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PolicyFilter;
  return proto.model.PolicyFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PolicyFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PolicyFilter}
 */
proto.model.PolicyFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addPolicyModelIds(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNoPolicy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PolicyFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PolicyFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PolicyFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIncludeNoPolicy();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string policy_model_ids = 1;
 * @return {!Array<string>}
 */
proto.model.PolicyFilter.prototype.getPolicyModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.PolicyFilter} returns this
 */
proto.model.PolicyFilter.prototype.setPolicyModelIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyFilter} returns this
 */
proto.model.PolicyFilter.prototype.addPolicyModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PolicyFilter} returns this
 */
proto.model.PolicyFilter.prototype.clearPolicyModelIdsList = function() {
  return this.setPolicyModelIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_no_policy = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.PolicyFilter.prototype.getIncludeNoPolicy = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.PolicyFilter} returns this
*/
proto.model.PolicyFilter.prototype.setIncludeNoPolicy = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyFilter} returns this
 */
proto.model.PolicyFilter.prototype.clearIncludeNoPolicy = function() {
  return this.setIncludeNoPolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyFilter.prototype.hasIncludeNoPolicy = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.FrameworkFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.FrameworkFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.model.FrameworkFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.FrameworkFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.FrameworkFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    frameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    includeNoFramework: (f = msg.getIncludeNoFramework()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.FrameworkFilter}
 */
proto.model.FrameworkFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.FrameworkFilter;
  return proto.model.FrameworkFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.FrameworkFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.FrameworkFilter}
 */
proto.model.FrameworkFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFrameworkIds(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNoFramework(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.FrameworkFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.FrameworkFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.FrameworkFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.FrameworkFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getIncludeNoFramework();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string compliance_framework_id = 1;
 * @return {string}
 */
proto.model.FrameworkFilter.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.FrameworkFilter} returns this
 */
proto.model.FrameworkFilter.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string framework_ids = 2;
 * @return {!Array<string>}
 */
proto.model.FrameworkFilter.prototype.getFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.FrameworkFilter} returns this
 */
proto.model.FrameworkFilter.prototype.setFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.FrameworkFilter} returns this
 */
proto.model.FrameworkFilter.prototype.addFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.FrameworkFilter} returns this
 */
proto.model.FrameworkFilter.prototype.clearFrameworkIdsList = function() {
  return this.setFrameworkIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_no_framework = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.FrameworkFilter.prototype.getIncludeNoFramework = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.FrameworkFilter} returns this
*/
proto.model.FrameworkFilter.prototype.setIncludeNoFramework = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.FrameworkFilter} returns this
 */
proto.model.FrameworkFilter.prototype.clearIncludeNoFramework = function() {
  return this.setIncludeNoFramework(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.FrameworkFilter.prototype.hasIncludeNoFramework = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ControlSort.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ControlSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ControlSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOrder: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ControlSort}
 */
proto.model.ControlSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ControlSort;
  return proto.model.ControlSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ControlSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ControlSort}
 */
proto.model.ControlSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.model.ControlSort.control_sort_order} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.model.ControlSort.control_sort_column} */ (reader.readEnum());
      msg.setSortColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ControlSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ControlSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ControlSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortColumn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.model.ControlSort.control_sort_order = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.model.ControlSort.control_sort_column = {
  NAME: 0,
  ID: 1,
  DEPARTMENT: 2,
  AUTOMATED_EVIDENCE: 3,
  MANUAL_EVIDENCE: 4,
  STATUS: 5,
  ASSIGNEE: 6,
  POLICY_MATCH: 7,
  COMPLETENESS: 8,
  SPOT_CHECK: 9
};

/**
 * optional control_sort_order sort_order = 1;
 * @return {!proto.model.ControlSort.control_sort_order}
 */
proto.model.ControlSort.prototype.getSortOrder = function() {
  return /** @type {!proto.model.ControlSort.control_sort_order} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.model.ControlSort.control_sort_order} value
 * @return {!proto.model.ControlSort} returns this
 */
proto.model.ControlSort.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional control_sort_column sort_column = 2;
 * @return {!proto.model.ControlSort.control_sort_column}
 */
proto.model.ControlSort.prototype.getSortColumn = function() {
  return /** @type {!proto.model.ControlSort.control_sort_column} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.ControlSort.control_sort_column} value
 * @return {!proto.model.ControlSort} returns this
 */
proto.model.ControlSort.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.ListControlsRequest.repeatedFields_ = [2,3,4,7,13,14,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ListControlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ListControlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ListControlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ListControlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    ownersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    statusesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    departmentFilter: (f = msg.getDepartmentFilter()) && proto.model.DepartmentFilter.toObject(includeInstance, f),
    policyFilter: (f = msg.getPolicyFilter()) && proto.model.PolicyFilter.toObject(includeInstance, f),
    serviceModelIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    includeUnassigned: (f = msg.getIncludeUnassigned()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    excludedFromAudit: (f = msg.getExcludedFromAudit()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hasNoDepartment: (f = msg.getHasNoDepartment()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    auditIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    documentRequestId: (f = msg.getDocumentRequestId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sortControls: (f = msg.getSortControls()) && proto.model.ControlSort.toObject(includeInstance, f),
    excludeNotApplicable: (f = msg.getExcludeNotApplicable()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    frameworkFiltersList: jspb.Message.toObjectList(msg.getFrameworkFiltersList(),
    proto.model.FrameworkFilter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ListControlsRequest}
 */
proto.model.ListControlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ListControlsRequest;
  return proto.model.ListControlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ListControlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ListControlsRequest}
 */
proto.model.ListControlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOwners(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.model.CONTROL_STATUS>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 5:
      var value = new proto.model.DepartmentFilter;
      reader.readMessage(value,proto.model.DepartmentFilter.deserializeBinaryFromReader);
      msg.setDepartmentFilter(value);
      break;
    case 6:
      var value = new proto.model.PolicyFilter;
      reader.readMessage(value,proto.model.PolicyFilter.deserializeBinaryFromReader);
      msg.setPolicyFilter(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addServiceModelIds(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeUnassigned(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setExcludedFromAudit(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasNoDepartment(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditIds(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentRequestId(value);
      break;
    case 16:
      var value = new proto.model.ControlSort;
      reader.readMessage(value,proto.model.ControlSort.deserializeBinaryFromReader);
      msg.setSortControls(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setExcludeNotApplicable(value);
      break;
    case 18:
      var value = new proto.model.FrameworkFilter;
      reader.readMessage(value,proto.model.FrameworkFilter.deserializeBinaryFromReader);
      msg.addFrameworkFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ListControlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ListControlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ListControlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ListControlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getDepartmentFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.model.DepartmentFilter.serializeBinaryToWriter
    );
  }
  f = message.getPolicyFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.model.PolicyFilter.serializeBinaryToWriter
    );
  }
  f = message.getServiceModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getIncludeUnassigned();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExcludedFromAudit();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasNoDepartment();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getDocumentRequestId();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSortControls();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.model.ControlSort.serializeBinaryToWriter
    );
  }
  f = message.getExcludeNotApplicable();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getFrameworkFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.model.FrameworkFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListControlsRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string control_ids = 2;
 * @return {!Array<string>}
 */
proto.model.ListControlsRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * repeated string owners = 3;
 * @return {!Array<string>}
 */
proto.model.ListControlsRequest.prototype.getOwnersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.setOwnersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.addOwners = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearOwnersList = function() {
  return this.setOwnersList([]);
};


/**
 * repeated CONTROL_STATUS statuses = 4;
 * @return {!Array<!proto.model.CONTROL_STATUS>}
 */
proto.model.ListControlsRequest.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.model.CONTROL_STATUS>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.model.CONTROL_STATUS>} value
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.model.CONTROL_STATUS} value
 * @param {number=} opt_index
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional DepartmentFilter department_filter = 5;
 * @return {?proto.model.DepartmentFilter}
 */
proto.model.ListControlsRequest.prototype.getDepartmentFilter = function() {
  return /** @type{?proto.model.DepartmentFilter} */ (
    jspb.Message.getWrapperField(this, proto.model.DepartmentFilter, 5));
};


/**
 * @param {?proto.model.DepartmentFilter|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setDepartmentFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearDepartmentFilter = function() {
  return this.setDepartmentFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasDepartmentFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PolicyFilter policy_filter = 6;
 * @return {?proto.model.PolicyFilter}
 */
proto.model.ListControlsRequest.prototype.getPolicyFilter = function() {
  return /** @type{?proto.model.PolicyFilter} */ (
    jspb.Message.getWrapperField(this, proto.model.PolicyFilter, 6));
};


/**
 * @param {?proto.model.PolicyFilter|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setPolicyFilter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearPolicyFilter = function() {
  return this.setPolicyFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasPolicyFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string service_model_ids = 7;
 * @return {!Array<string>}
 */
proto.model.ListControlsRequest.prototype.getServiceModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.setServiceModelIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.addServiceModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearServiceModelIdsList = function() {
  return this.setServiceModelIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_unassigned = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListControlsRequest.prototype.getIncludeUnassigned = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setIncludeUnassigned = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearIncludeUnassigned = function() {
  return this.setIncludeUnassigned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasIncludeUnassigned = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.ListControlsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.BoolValue excluded_from_audit = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListControlsRequest.prototype.getExcludedFromAudit = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setExcludedFromAudit = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearExcludedFromAudit = function() {
  return this.setExcludedFromAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasExcludedFromAudit = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.BoolValue has_no_department = 12;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListControlsRequest.prototype.getHasNoDepartment = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 12));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setHasNoDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearHasNoDepartment = function() {
  return this.setHasNoDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasHasNoDepartment = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated string audit_ids = 13;
 * @return {!Array<string>}
 */
proto.model.ListControlsRequest.prototype.getAuditIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.setAuditIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.addAuditIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearAuditIdsList = function() {
  return this.setAuditIdsList([]);
};


/**
 * repeated string compliance_framework_ids = 14;
 * @return {!Array<string>}
 */
proto.model.ListControlsRequest.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};


/**
 * optional google.protobuf.StringValue document_request_id = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.ListControlsRequest.prototype.getDocumentRequestId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setDocumentRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearDocumentRequestId = function() {
  return this.setDocumentRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasDocumentRequestId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ControlSort sort_controls = 16;
 * @return {?proto.model.ControlSort}
 */
proto.model.ListControlsRequest.prototype.getSortControls = function() {
  return /** @type{?proto.model.ControlSort} */ (
    jspb.Message.getWrapperField(this, proto.model.ControlSort, 16));
};


/**
 * @param {?proto.model.ControlSort|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setSortControls = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearSortControls = function() {
  return this.setSortControls(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasSortControls = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.BoolValue exclude_not_applicable = 17;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListControlsRequest.prototype.getExcludeNotApplicable = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 17));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setExcludeNotApplicable = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearExcludeNotApplicable = function() {
  return this.setExcludeNotApplicable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListControlsRequest.prototype.hasExcludeNotApplicable = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * repeated FrameworkFilter framework_filters = 18;
 * @return {!Array<!proto.model.FrameworkFilter>}
 */
proto.model.ListControlsRequest.prototype.getFrameworkFiltersList = function() {
  return /** @type{!Array<!proto.model.FrameworkFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.FrameworkFilter, 18));
};


/**
 * @param {!Array<!proto.model.FrameworkFilter>} value
 * @return {!proto.model.ListControlsRequest} returns this
*/
proto.model.ListControlsRequest.prototype.setFrameworkFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.model.FrameworkFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.FrameworkFilter}
 */
proto.model.ListControlsRequest.prototype.addFrameworkFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.model.FrameworkFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListControlsRequest} returns this
 */
proto.model.ListControlsRequest.prototype.clearFrameworkFiltersList = function() {
  return this.setFrameworkFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Controls.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Controls.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Controls.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Controls} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Controls.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.model.Control.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Controls}
 */
proto.model.Controls.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Controls;
  return proto.model.Controls.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Controls} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Controls}
 */
proto.model.Controls.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.Control;
      reader.readMessage(value,proto.model.Control.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Controls.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Controls.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Controls} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Controls.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.Control.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Control items = 1;
 * @return {!Array<!proto.model.Control>}
 */
proto.model.Controls.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.Control>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Control, 1));
};


/**
 * @param {!Array<!proto.model.Control>} value
 * @return {!proto.model.Controls} returns this
*/
proto.model.Controls.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.Control=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Control}
 */
proto.model.Controls.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.Control, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Controls} returns this
 */
proto.model.Controls.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ControlStatusValue.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ControlStatusValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ControlStatusValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStatusValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ControlStatusValue}
 */
proto.model.ControlStatusValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ControlStatusValue;
  return proto.model.ControlStatusValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ControlStatusValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ControlStatusValue}
 */
proto.model.ControlStatusValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.model.CONTROL_STATUS} */ (reader.readEnum());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ControlStatusValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ControlStatusValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ControlStatusValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStatusValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional CONTROL_STATUS value = 1;
 * @return {!proto.model.CONTROL_STATUS}
 */
proto.model.ControlStatusValue.prototype.getValue = function() {
  return /** @type {!proto.model.CONTROL_STATUS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.model.CONTROL_STATUS} value
 * @return {!proto.model.ControlStatusValue} returns this
 */
proto.model.ControlStatusValue.prototype.setValue = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetControlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetControlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetControlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetControlRequest}
 */
proto.model.GetControlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetControlRequest;
  return proto.model.GetControlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetControlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetControlRequest}
 */
proto.model.GetControlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetControlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetControlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetControlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.model.GetControlRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetControlRequest} returns this
 */
proto.model.GetControlRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetControlsForComplianceFrameworkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetControlsForComplianceFrameworkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetControlsForComplianceFrameworkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlsForComplianceFrameworkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetControlsForComplianceFrameworkRequest}
 */
proto.model.GetControlsForComplianceFrameworkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetControlsForComplianceFrameworkRequest;
  return proto.model.GetControlsForComplianceFrameworkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetControlsForComplianceFrameworkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetControlsForComplianceFrameworkRequest}
 */
proto.model.GetControlsForComplianceFrameworkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetControlsForComplianceFrameworkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetControlsForComplianceFrameworkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetControlsForComplianceFrameworkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlsForComplianceFrameworkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string compliance_framework_id = 1;
 * @return {string}
 */
proto.model.GetControlsForComplianceFrameworkRequest.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetControlsForComplianceFrameworkRequest} returns this
 */
proto.model.GetControlsForComplianceFrameworkRequest.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetControlsInAccountForComplianceFrameworkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetControlsInAccountForComplianceFrameworkRequest}
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetControlsInAccountForComplianceFrameworkRequest;
  return proto.model.GetControlsInAccountForComplianceFrameworkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetControlsInAccountForComplianceFrameworkRequest}
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetControlsInAccountForComplianceFrameworkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string compliance_framework_id = 1;
 * @return {string}
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} returns this
 */
proto.model.GetControlsInAccountForComplianceFrameworkRequest.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetHasNoControlsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetHasNoControlsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetHasNoControlsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetHasNoControlsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasNone: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetHasNoControlsResponse}
 */
proto.model.GetHasNoControlsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetHasNoControlsResponse;
  return proto.model.GetHasNoControlsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetHasNoControlsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetHasNoControlsResponse}
 */
proto.model.GetHasNoControlsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasNone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetHasNoControlsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetHasNoControlsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetHasNoControlsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetHasNoControlsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasNone();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool has_none = 1;
 * @return {boolean}
 */
proto.model.GetHasNoControlsResponse.prototype.getHasNone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.GetHasNoControlsResponse} returns this
 */
proto.model.GetHasNoControlsResponse.prototype.setHasNone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPreparedControlsBreakdownRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPreparedControlsBreakdownRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedControlsBreakdownRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlModelIds: (f = msg.getControlModelIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPreparedControlsBreakdownRequest}
 */
proto.model.GetPreparedControlsBreakdownRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPreparedControlsBreakdownRequest;
  return proto.model.GetPreparedControlsBreakdownRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPreparedControlsBreakdownRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPreparedControlsBreakdownRequest}
 */
proto.model.GetPreparedControlsBreakdownRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setControlModelIds(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPreparedControlsBreakdownRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPreparedControlsBreakdownRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedControlsBreakdownRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlModelIds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.StringList control_model_ids = 1;
 * @return {?proto.common.StringList}
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.getControlModelIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 1));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.GetPreparedControlsBreakdownRequest} returns this
*/
proto.model.GetPreparedControlsBreakdownRequest.prototype.setControlModelIds = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetPreparedControlsBreakdownRequest} returns this
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.clearControlModelIds = function() {
  return this.setControlModelIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.hasControlModelIds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.GetPreparedControlsBreakdownRequest} returns this
*/
proto.model.GetPreparedControlsBreakdownRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetPreparedControlsBreakdownRequest} returns this
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetPreparedControlsBreakdownRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPreparedControlsBreakdownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPreparedControlsBreakdownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedControlsBreakdownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    noEvidenceCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    noPolicyCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    preparedCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPreparedControlsBreakdownResponse}
 */
proto.model.GetPreparedControlsBreakdownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPreparedControlsBreakdownResponse;
  return proto.model.GetPreparedControlsBreakdownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPreparedControlsBreakdownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPreparedControlsBreakdownResponse}
 */
proto.model.GetPreparedControlsBreakdownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoEvidenceCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoPolicyCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatusCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreparedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPreparedControlsBreakdownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPreparedControlsBreakdownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedControlsBreakdownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoEvidenceCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNoPolicyCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStatusCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPreparedCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 no_evidence_count = 1;
 * @return {number}
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.getNoEvidenceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedControlsBreakdownResponse} returns this
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.setNoEvidenceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 no_policy_count = 2;
 * @return {number}
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.getNoPolicyCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedControlsBreakdownResponse} returns this
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.setNoPolicyCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 status_count = 3;
 * @return {number}
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.getStatusCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedControlsBreakdownResponse} returns this
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.setStatusCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 prepared_count = 4;
 * @return {number}
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.getPreparedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedControlsBreakdownResponse} returns this
 */
proto.model.GetPreparedControlsBreakdownResponse.prototype.setPreparedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.model.CONTROL_STATUS = {
  NEEDSATTENTION: 0,
  AUDITREADY: 1,
  ACCEPTED: 2,
  READYFORREVIEW: 3,
  FAILEDTEST: 4,
  NOTAPPLICABLE: 5
};

goog.object.extend(exports, proto.model);
