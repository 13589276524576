export const NAV_ITEM_TITLES = Object.freeze({
  DASHBOARD: 'Dashboard',
  HELP: 'Help',
  SERVICES: 'Services',
  RECEPTORS: 'Receptors',
  COMPANY_INFO: 'Company Info',
  POLICIES: 'Policies',
  CONTROLS: 'Controls',
  RISKS: 'Risks',
  AUDITS: 'Audits',
  REQUESTS: 'Requests',
  REPORT_SCAN: 'Report Scan',
  USERS: 'Users',
  ACTIVITY_LOG: 'Activity Log',
  ACCOUNT_SWITCHER: 'Switch Acct.',
  AUDIT_BOT: 'Audit Scan',
  ROADMAP: 'Roadmap',
  QUESTIONNAIRE: 'Questionnaire',
  KNOWLEDGE_BASE: 'Knowledge Base',
  VENDORS: 'Vendors',
  SCOPE: 'Scope',
  ACCOUNT_SETTINGS: 'Account',
  MANAGE_DOCUMENTS: 'Manage Documents',
})

export enum NAV_ITEM_KEYS {
  DASHBOARD = 'dashboard',
  HELP = 'help',
  SERVICES = 'services',
  RECEPTORS = 'receptors',
  COMPANY_INFO = 'company_info',
  POLICIES = 'policies',
  CONTROLS = 'controls',
  RISKS = 'risks',
  AUDITS = 'audits',
  REQUESTS = 'requests',
  REPORT_SCAN = 'report_scan',
  USERS = 'users',
  ACCOUNTS = 'accounts',
  AUDIT_BOT = 'auditbot',
  ROADMAP = 'roadmap',
  QUESTIONNAIRE = 'security_questionnaire',
  KNOWLEDGE_BASE = 'knowledge_base',
  VENDORS = 'vendors',
  SCOPE = 'scope',
  ACCOUNT_SETTINGS = 'account_settings',
}

export type NavbarState = {
  [value in NAV_ITEM_KEYS]: boolean
}

// Be sure to also update app/src/components/Reusable/Accordions/NavBar/NavBarAccordions.constants.tsx
export const NavbarInitialState: NavbarState = {
  [NAV_ITEM_KEYS.DASHBOARD]: false,
  [NAV_ITEM_KEYS.HELP]: false,
  [NAV_ITEM_KEYS.SERVICES]: false,
  [NAV_ITEM_KEYS.RECEPTORS]: false,
  [NAV_ITEM_KEYS.COMPANY_INFO]: false,
  [NAV_ITEM_KEYS.POLICIES]: false,
  [NAV_ITEM_KEYS.CONTROLS]: false,
  [NAV_ITEM_KEYS.RISKS]: false,
  [NAV_ITEM_KEYS.AUDITS]: false,
  [NAV_ITEM_KEYS.REQUESTS]: false,
  [NAV_ITEM_KEYS.REPORT_SCAN]: false,
  [NAV_ITEM_KEYS.USERS]: false,
  [NAV_ITEM_KEYS.ACCOUNTS]: false,
  [NAV_ITEM_KEYS.AUDIT_BOT]: false,
  [NAV_ITEM_KEYS.ROADMAP]: false,
  [NAV_ITEM_KEYS.QUESTIONNAIRE]: false,
  [NAV_ITEM_KEYS.KNOWLEDGE_BASE]: false,
  [NAV_ITEM_KEYS.VENDORS]: false,
  [NAV_ITEM_KEYS.SCOPE]: false,
  [NAV_ITEM_KEYS.ACCOUNT_SETTINGS]: false,
}

export const AUDIT_ICON_COLOR = 'white'
export const BREAK_HEIGHT = 900
export const LOGO_HEIGHT = 40
