import React, {
  ChangeEventHandler,
  FormEventHandler,
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReceptorPromiseClient } from '@trustero/trustero-api-web/lib/agent/receptor_grpc_web_pb'
import {
  ReceptorID,
  ReceptorRecord,
  StringMessage,
} from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import {
  useHideModal,
  useIsShowModal,
  useModalState,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import { useCurrentReceptor } from 'src/context/FormContext/CurrentReceptorContext'
import { ReceptorsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { FieldDef, MethodDef, SectionDef } from 'src/xgenerated/receptor'
import {
  useReceptor,
  useScanReceptor,
} from 'src/pages/Receptors/receptors.hooks'
import { getReceptorTemplate } from '../../../../xgenerated'
import { ModalForm, ModalFormId, ModalFormIdQueryParam } from '../../ModalForm'
import { Spinner, ThrobberContext } from '../../../../Throbber'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../../adapter/grpcClient'
import { isGrpcError } from '../../../../Utils/isGrpcError'
import { ReceptorStaticHelper } from '../../../../context/Content/statichelpers'
import { useInvalidateReceptorsCache } from '../../../async/model/receptor/useReceptors'
import { Markup } from '../../../Reusable/Text/Markup'
import {
  ActivateReceptorFormFields,
  ActivateReceptorFormTabs,
  TenantIdField,
} from './ActivateReceptorForm.components'
import { ReceptorFormErrorMsg } from './ActivateReceptorForm.styles'
import { getMethodDefaultActiveKey } from './ActivateReceptorForm.helpers'

export const ActivateReceptorQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  NEW_RECEPTOR: 'new_receptor',
  RECEPTOR_ID: 'receptor_id',
  RECEPTOR_MODEL_ID: 'receptor_model_id',
  ERROR_MESSAGE: 'error_message',
}

export type ReceptorTemplate = ReceptorRecord.AsObject & {
  name: string
  content: string
  field_template: FieldDef[]
  service_ids: string[]
  control_ids: string[]
  logo:
    | ((
        props: React.DetailedHTMLProps<
          React.ImgHTMLAttributes<HTMLImageElement>,
          HTMLImageElement
        >,
      ) => JSX.Element)
    | null
  category: string
  config_template?: SectionDef[]
  methods?: MethodDef[]
}

export const ActivateReceptorForm = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isOauth, setIsOauth] = useState(false)
  const { receptor, setReceptor } = useCurrentReceptor()
  const params = queryString.parse(location.search)
  const paramReceptorId = params[
    ActivateReceptorQueryParams.RECEPTOR_ID
  ] as string
  const { data: receptorFromParams } = useReceptor({
    receptorId: paramReceptorId,
  })
  const { activeModal } = useModalState()
  const isEdit = activeModal === ModalFormId.EDIT_RECEPTOR
  const { setThrobberState } = useContext(ThrobberContext)
  const invalidateReceptorCache = useInvalidateReceptorsCache()
  const scanReceptor = useScanReceptor()
  const receptorTemplate: ReceptorTemplate = useMemo(
    () =>
      receptor.modelid
        ? getReceptorTemplate(receptor.modelid)
        : {
            ...new ReceptorRecord().toObject(),
            field_template: [],
            name: '',
            content: '',
            logo: null,
            service_ids: [],
            control_ids: [],
            category: '',
            methods: [],
          },
    [receptor.modelid],
  )
  const hasConfig =
    receptorTemplate.config_template &&
    receptorTemplate.config_template.length > 0
  const currentUrl = useMemo(() => {
    const searchParams = queryString.parse(location.search)
    return hasConfig
      ? queryString.stringifyUrl(
          {
            url: window.origin + location.pathname,
            query: {
              ...searchParams,
              [ActivateReceptorQueryParams.MODAL_FORM_ID]:
                ModalFormId.CONFIGURE_RECEPTOR,
            },
          },
          { arrayFormat: 'bracket' },
        )
      : window.location.href
  }, [location, hasConfig])
  const errorRedirect = useMemo(() => {
    const searchParams = queryString.parse(location.search)
    return queryString.stringifyUrl(
      {
        url: window.origin + location.pathname,
        query: {
          ...searchParams,
          [ActivateReceptorQueryParams.MODAL_FORM_ID]:
            ModalFormId.ACTIVATE_RECEPTOR,
        },
      },
      { arrayFormat: 'bracket' },
    )
  }, [location])
  // [AP-6642] TODO: Revisit how state is managed in this component
  useEffect(() => {
    setIsOauth(() => {
      return receptorTemplate?.field_template.some(
        ({ field }: { field: string }) => field === 'oauth',
      )
    })
  }, [receptorTemplate])

  // Initialize tenant id
  const [tenantId, setTenantId] = useState(receptor.tenantid || '')
  // Initialize credentials with current credentials, or empty string
  const getInitialCredentials = () => {
    const initFormData: { [key: string]: string } = {}

    const initCredentials: Record<string, string> = receptor.credentials
      ? JSON.parse(receptor.credentials)
      : {}
    receptorTemplate.field_template.forEach(({ field }) => {
      initFormData[field] = initCredentials[field] || ''
    })

    return initFormData
  }
  const [credentials, setCredentials] = useState(getInitialCredentials)

  // Initialize error message
  const [errorMsg, setErrorMsg] = useState('')

  const [activeKey, setActiveKey] = useState<number>(0)

  const isFromShowPage = location.pathname.includes(
    ReceptorsAbsoluteRoutes.SHOW,
  )

  // update the state when the current receptor changes
  useEffect(() => {
    setTenantId(receptor.tenantid || '')
    const creds = receptor.credentials ? JSON.parse(receptor.credentials) : {}
    setCredentials(creds)
  }, [receptor, receptorTemplate])

  const defaultActiveKey = useMemo(() => {
    const key = getMethodDefaultActiveKey(receptor, receptorTemplate)
    setActiveKey(key)
    return key
  }, [receptor, receptorTemplate])

  useEffect(() => {
    if (params[ActivateReceptorQueryParams.RECEPTOR_MODEL_ID]) {
      setReceptor(
        getReceptorTemplate(
          params[ActivateReceptorQueryParams.RECEPTOR_MODEL_ID] as string,
        ),
      )
    } else if (
      receptorFromParams &&
      receptorFromParams.getId() !== receptor.id
    ) {
      setReceptor(receptorFromParams.toObject())
    }
    if (params[ActivateReceptorQueryParams.ERROR_MESSAGE]) {
      setErrorMsg(params[ActivateReceptorQueryParams.ERROR_MESSAGE] as string)
    } else if (receptor.exceptions.length > 0) {
      setErrorMsg(receptor.exceptions)
    }
  }, [params, setReceptor, setErrorMsg, receptor, receptorFromParams])

  const onHide = () => {
    // Clear the tenantId
    setTenantId(receptor.tenantid || '')

    // Clear the credentials
    setCredentials((currFormData) => {
      const resetFormData = { ...currFormData }
      const credentials: Record<string, string> = receptor.credentials
        ? JSON.parse(receptor.credentials)
        : {}
      for (const field in resetFormData) {
        resetFormData[field] = credentials[field] || ''
      }
      return resetFormData
    })
    setReceptor(new ReceptorRecord().toObject())
    setErrorMsg('')
    navigate(
      queryString.exclude(
        `${location.pathname}${location.search}`,
        [
          ActivateReceptorQueryParams.MODAL_FORM_ID,
          ActivateReceptorQueryParams.RECEPTOR_MODEL_ID,
          ActivateReceptorQueryParams.RECEPTOR_ID,
          ActivateReceptorQueryParams.ERROR_MESSAGE,
        ],

        {
          arrayFormat: 'bracket',
        },
      ),
    )
  }

  const navigateToConfigReceptorModal = useSetActiveModal(
    ModalFormId.CONFIGURE_RECEPTOR,
  )
  const navigateToSelectReceptorModal = useSetActiveModal(
    ModalFormId.SELECT_RECEPTOR,
  )
  const showBackButton = !isFromShowPage && !isEdit
  const onBack = showBackButton ? navigateToSelectReceptorModal : undefined
  const show =
    (useIsShowModal([
      ModalFormId.ACTIVATE_RECEPTOR,
      ModalFormId.EDIT_RECEPTOR,
    ]) ||
      params.modalFormId === ModalFormId.ACTIVATE_RECEPTOR) &&
    !!receptor
  const hide = useHideModal({ onHide })
  const receptorClient = useAuthorizedGrpcClientWithContentUpdate(
    ReceptorPromiseClient,
  )
  /**
   * Deactivate the receptor
   * - Pass receptorId to the `deactivate` API
   *   - It will deactivate the receptor
   *     - The `credentials` will not be cleared
   *     - The `iscredvalid` field will be set to `false`
   * - Await the result of the API
   * - Clear error message
   */
  const deactivate = async () => {
    // Deactivate the receptor
    await receptorClient.deactivate(new ReceptorID().setOid(receptor.id))

    // receptorMutate()
    await invalidateReceptorCache()

    // Clear the error message
    setErrorMsg('')

    // Close the modal
    hide()
  }

  /**
   * Attempt to activate the receptor
   * - Credentials must be passed to the `setCredentials` API
   *   - This tells it that we want to attempt to activate the receptor
   *   - Then it will synchronously trigger a receptor verify
   *   - Credentials status:
   *     - VALID
   *       - The `credentials` will be set
   *       - The `iscredvalid` field will be set to `true`
   *     - INVALID
   *       - API throws an error
   * - Await the result of the API
   *   - SUCCESS
   *     - Asynchronously trigger a receptor discovery
   *     - Hide the throbber
   *     - Clear error message
   *     - Close modal
   *   - FAIL
   *     - Set error message
   */
  const attemptActivate = async () => {
    // Display throbber
    setThrobberState({
      isShown: true,
      Display: <Spinner size="xl" color="primary" />,
    })

    try {
      const receptorIdMsg = new ReceptorID()
        .setModelid(receptor.modelid)
        .setOid(receptor.id)
        .setTenantid(tenantId.trim())
        .setCurrenturl(currentUrl)
        .setErrorredirect(errorRedirect)

      if (isOauth) {
        credentials['oauth'] = 'true'
      }

      // Attempt to activate the receptor
      const receptorRecord = await receptorClient.setCredentials(
        new StringMessage()
          .setId(receptorIdMsg)
          .setValue(JSON.stringify(credentials)),
      )

      if (receptorRecord.getRedirect() && receptorRecord.getRedirect() !== '') {
        window.location.href = receptorRecord.getRedirect()
        return
      }
      if (hasConfig) {
        navigateToConfigReceptorModal()
        return
      }
      scanReceptor({
        /**
         * Use id coming from the database record because
         * - `receptor.id` will be undefined if we are adding a new
         * receptor
         */
        receptorId: receptorRecord.getId(),
        receptorModelId: receptor.modelid,
      })

      // Clear the error message
      setErrorMsg('')

      // Wait for relevant cache invalidation
      // receptorMutate()
      await invalidateReceptorCache()
      // Hide throbber
      setThrobberState({
        isShown: false,
        Display: <Fragment />,
      })

      // Close the modal
      hide()
    } catch (e) {
      // Hide throbber
      setThrobberState({
        isShown: false,
        Display: <Fragment />,
      })

      if (isGrpcError(e)) {
        // Set the error message
        setErrorMsg(e.message)
      }
    }
  }

  const attemptUpdate = async () => {
    await attemptActivate()
  }

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const methods = receptorTemplate.methods
    // if there are multiple methods, only send the credentials for the selected method
    if (methods && methods.length > 0) {
      setCredentials((prevState: { [key: string]: string }) => {
        const newCredentials: { [key: string]: string } = {}
        // only add the credentials for the selected method
        for (const key in prevState) {
          const field = receptorTemplate.field_template.find(
            (f) => f.field === key,
          )

          if (field?.method === methods[activeKey].value) {
            newCredentials[key] = prevState[key]
          }
        }
        return newCredentials
      })
    }

    // Are there existing and valid credentials?
    ReceptorStaticHelper.isAccredited(receptor) && receptor.iscredvalid
      ? // YES: Deactivate the receptor
        await attemptUpdate()
      : // NO:  Verify the entered credentials
        await attemptActivate()
  }
  /**
   * Change event handler used to change the receptor's `tenantid`.
   *
   * ! NOTE
   * - We do not allow the `tenantid` to be changed for existing receptors
   *   - Meaning: Once the `tenantid` has been set, we do not allow any edits
   */
  const onChangeTenantId: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (receptor.tenantid.trim()) return
    setTenantId(e.currentTarget.value)
  }

  const updateCredentials = (field: string, value: string) => {
    setCredentials((state: { [key: string]: string }) => ({
      ...state,
      [field.toLocaleLowerCase()]: value,
    }))
  }

  const tenantIdField = (
    <TenantIdField
      receptor={receptor}
      value={tenantId}
      onChange={onChangeTenantId}
      errorMsg={errorMsg}
      show={!isFromShowPage}
    />
  )

  const permissionsError = useMemo(
    () =>
      params[ActivateReceptorQueryParams.ERROR_MESSAGE] ||
      receptor.exceptions.length > 0,
    [params, receptor],
  )
  const status = useMemo(
    () => (permissionsError ? 'error' : 'default'),
    [permissionsError],
  )

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.ACTIVATE_RECEPTOR}
      title={
        <Fragment>
          <span style={{ marginRight: '15px' }}>
            Activate {receptorTemplate.name}
          </span>
          {receptorTemplate.logo && (
            <receptorTemplate.logo width="24px" height="24px" />
          )}
        </Fragment>
      }
      submitText={
        ReceptorStaticHelper.isAccredited(receptor) && receptor.isenabled
          ? 'Update'
          : 'Activate'
      }
      onBack={onBack}
      onDeactivate={deactivate}
      hideDeactivate={
        !(ReceptorStaticHelper.isAccredited(receptor) && receptor.isenabled)
      }
    >
      <form id={ModalFormId.ACTIVATE_RECEPTOR} onSubmit={onSubmit}>
        <Markup
          rawHtml={receptorTemplate.content}
          sectionProps={{
            height: '320px',
            my: 'm',
            p: 'l',
            border: '1px solid',
            borderColor: 'border.neutral.light',
            borderRadius: '2px',
            bg: 'white',
            textStyle: 'body.default',
            overflow: 'auto',
            display: 'block',
          }}
        />
        <ReceptorFormErrorMsg status={status}>{errorMsg}</ReceptorFormErrorMsg>
        {receptorTemplate.methods && receptorTemplate.methods.length > 0 ? (
          <>
            <ActivateReceptorFormTabs
              tabs={receptorTemplate.methods.map((method) => ({
                title: method.display,
                body: (
                  <>
                    <TenantIdField
                      receptor={receptor}
                      value={tenantId}
                      onChange={onChangeTenantId}
                      errorMsg={errorMsg}
                      show={!isFromShowPage}
                      isRequired={
                        receptorTemplate.methods &&
                        receptorTemplate.methods[activeKey].value ===
                          method.value
                      }
                    />
                    <ActivateReceptorFormFields
                      receptor={receptor}
                      fields={receptorTemplate.field_template}
                      credentials={credentials}
                      errorMsg={errorMsg}
                      updateCredentials={updateCredentials}
                      authMethod={method.value}
                      isShown={
                        receptorTemplate.methods &&
                        receptorTemplate.methods[activeKey].value ===
                          method.value
                      }
                    />
                  </>
                ),
              }))}
              updateMethod={(val: number) => setActiveKey(val)}
              defaultActiveKey={defaultActiveKey || 0}
            />
          </>
        ) : (
          <>
            {tenantIdField}
            <ActivateReceptorFormFields
              receptor={receptor}
              fields={receptorTemplate.field_template}
              credentials={credentials}
              errorMsg={errorMsg}
              updateCredentials={updateCredentials}
            />
          </>
        )}
      </form>
    </ModalForm>
  )
}
