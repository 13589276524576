import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import { FieldDef } from 'src/xgenerated/receptor'
import { ReceptorTemplate } from './ActivateReceptorForm'

export const getSelectedItems = (
  options: SelectItem[],
  selected: string,
): SelectItem[] => {
  const selectedArr = selected.split(',')
  return selectedArr.map((selected) => {
    const selectedOption = options.find((option) => option.value === selected)
    return {
      name: selectedOption ? selectedOption.name : '',
      value: selected,
    } as SelectItem
  })
}

export const getMethodDefaultActiveKey = (
  receptor: ReceptorRecord.AsObject,
  receptorTemplate: ReceptorTemplate,
): number => {
  let key = 0
  if (receptorTemplate.methods && receptorTemplate.methods.length > 0) {
    const methods = receptorTemplate.methods
    const creds = receptor.credentials ? JSON.parse(receptor.credentials) : {}
    // if credentials are already set, set the active tab to the method that has credentials
    receptorTemplate.field_template.forEach((field: FieldDef) => {
      if (field.method && creds[field.field.toLocaleLowerCase()]?.length > 0) {
        const currentIdx = methods.findIndex(
          (method) => method.value === field.method,
        )
        key = currentIdx > -1 ? currentIdx : 0
      }
    })
  }
  return key
}
