import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Dropdown } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import log from 'loglevel'
import debounce from 'lodash/debounce'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { SERVICE_ROLE } from '@trustero/trustero-api-web/lib/service/service_pb'
import { Instance } from '@trustero/trustero-api-web/lib/model/service_instance_pb'
import { StyledDropdownToggle } from 'src/components/Reusable/StandardDropdown/StandardDropdown.styles'
import {
  MultiSelectDropdownCheckBox,
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
} from 'src/components/Reusable/MultiSelectDropdown'
import { Grid, GridColumn, GridRow } from 'src/components/Reusable/Grid'
import { CheckBoxInput } from 'src/components/Reusable/Inputs/CheckBox'
import { Service } from 'src/xgenerated'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { ModalFormId } from 'src/components/ModalForms'
import {
  DeleteButton,
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { useContent } from 'src/context/hooks'
import { PrevNextButtons } from 'src/components/Reusable/prevNext'
import { BASE_ROUTES } from 'src/components/Reusable/prevNext/usePrevNext'
import {
  ROOT_ID_PARAM_TYPE,
  ReceptorsAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { PrevNextContainer } from 'src/components/Reusable/prevNext/prevNext.styles'
import { Spinner } from 'src/Throbber'
import {
  StyledSectionContainer,
  StyledSectionHeader,
} from 'src/components/PageLayout/ShowPage/showpage.styles'
import { useModalState } from 'src/Modal/ModalStateContext'
import { openInNewTab } from 'src/Utils/globalHelpers'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import {
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { CenteredGridColumn } from 'src/components/Reusable/Grid/Grid.styles'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { useEvidenceGenerators } from 'src/components/async/attachment/useEvidenceGenerators'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { TextInput } from 'src/components/Reusable/Inputs'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { StyledInputError } from 'src/components/Reusable/Inputs/TextInput/styles'
import { BasicChip } from '../../components/Reusable/Chips/BasicChip/BasicChip'
import { PageHeader } from '../../components/PageLayout/PageHeader'
import { PageContainer } from '../../components/PageLayout/PageLayout.styles'
import { getActiveReceptors } from '../Receptors/receptors.helpers'
import { useReceptors } from '../Receptors/receptors.hooks'
import { PROVIDER_TO_LABEL } from '../Providers/providers.constants'
import { EvidenceGeneratorGrid } from '../Controls/ControlsShowPage/ConnectedGrid/EvidenceGeneratorsGrid'
import { QuestionnaireSecondaryButtonSmall } from '../SecurityQuestionnaire/show/securityQuestionnaireShowPage.components'
import { SERVICE_ACCOUNT_RECEPTOR_MODEL_IDS } from '../Receptors/receptors.constants'
import {
  AddMoreServiceRolesContainer,
  AddMoreServiceRolesIcon,
  AddServiceRolesContainer,
  AddServiceRolesHeader,
  AddServiceRolesOptions,
  AddServicesGridRow,
  ServiceTitle,
  AddServiceRolesOptionsContainer,
  ReceptorInfoContainer,
  ScopingContent,
  ServiceRolesDropdownContainer,
  ServiceRolesDropdownText,
  InstancesGridColumn,
  InstancesGridHeader,
  ReceptorNameContainer,
  ReceptorListContainer,
  ViewReceptorButton,
  ViewReceptorIcon,
  StyledServiceRolesDropdownMenu,
  ServiceRolesDropdownToggle,
  ExcludeServiceModalContainer,
  ServiceRowContainer,
  ServiceRow,
  ButtonDiv,
} from './services.styles'
import { useServicesContext } from './context/ServicesContext'
import {
  CustomServiceRoleRow,
  RECEPTORS_SECTION_MAX_LENGTH,
  SERVICE_INTANCES_HEADERS,
  SERVICE_ROLE_LABEL_TO_ROLE,
  SERVICE_ROLE_TO_LABEL,
  ServiceRoleLabels,
  UPDATE_SERVICE_DELAY,
} from './Services.constants'
import { ServicesSubNav } from './ServicesSubNav'
import { ReceptorsPage } from './ReceptorsPage'
import { ServicesPage } from './ServicesPage'
import { ServiceSubRoutes } from './ServicesRootPage'
import {
  useSetDismissedInstance,
  useInstancesForService,
  useServices,
  useServicesRequest,
  useSetDismissedInstanceByService,
} from './Services.hooks'
import { getActiveServices } from './services.helpers'
import { ViewReceptorsModal } from './modals/ViewReceptorsModal'

export const ServiceRoleChip = ({ role }: { role: string }): JSX.Element => (
  <BasicChip>{role}</BasicChip>
)

const ServiceRolesDropdownMenu = ({
  onRoleSelected,
  serviceRoles,
}: {
  onRoleSelected: ChangeEventHandler<HTMLElement>
  serviceRoles: ServiceRoleLabels[]
}): JSX.Element => {
  const hasUpdatePermission = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  return (
    <StyledServiceRolesDropdownMenu>
      {serviceRoles.map((label: ServiceRoleLabels) => {
        return (
          <Dropdown.Item
            key={`service-roles-dropdown-${label}`}
            eventKey={`${SERVICE_ROLE_LABEL_TO_ROLE[label]}`}
            as={MultiSelectDropdownItem}
            disabled={!hasUpdatePermission}
          >
            <MultiSelectDropdownCheckBoxLabel>
              <MultiSelectDropdownCheckBox
                id={label}
                onChange={onRoleSelected}
                value={label}
              />
              {label}
            </MultiSelectDropdownCheckBoxLabel>
          </Dropdown.Item>
        )
      })}
    </StyledServiceRolesDropdownMenu>
  )
}

export const ServiceRolesDropdown = ({
  onRoleSelected,
  defaultText,
  dropdownRoles,
}: {
  onRoleSelected: ChangeEventHandler<HTMLElement>
  defaultText?: string
  dropdownRoles?: ServiceRoleLabels[]
}): JSX.Element => {
  const placeholderText = defaultText || 'Select a Service Role'
  const serviceRoles = dropdownRoles || Object.values(ServiceRoleLabels)

  return (
    <ServiceRolesDropdownContainer id="service-roles-dropdown" drop="down">
      <Dropdown.Toggle as={StyledDropdownToggle} width="100%">
        <ServiceRolesDropdownToggle>
          <ServiceRolesDropdownText>{placeholderText}</ServiceRolesDropdownText>
        </ServiceRolesDropdownToggle>
      </Dropdown.Toggle>
      <ServiceRolesDropdownMenu
        onRoleSelected={onRoleSelected}
        serviceRoles={serviceRoles}
      />
    </ServiceRolesDropdownContainer>
  )
}

export const AddServicesRowItem = ({
  service,
  onChange,
}: {
  service: Service
  onChange: React.ChangeEventHandler<HTMLInputElement>
}): JSX.Element => {
  const { servicesMap } = useServicesContext()

  return (
    <AddServicesGridRow key={service.id} as="label" htmlFor={service.id}>
      <GridColumn>
        <CheckBoxInput
          id={service.id}
          value={service.modelid}
          onChange={onChange}
          checked={servicesMap[service.modelid].isSelected}
        />
      </GridColumn>
      <GridColumn>
        <ServiceTitle>
          <service.logo width="32px" height="32px" />
          {service.name}
        </ServiceTitle>
      </GridColumn>
      <GridColumn>{service.component_category}</GridColumn>
    </AddServicesGridRow>
  )
}

export const AddCustomServiceButtonComponent = (): JSX.Element => (
  <StandardOpenModalButton
    text="Add Custom Service"
    variant={StandardButtonVariant.SECONDARY}
    size={StandardButtonSize.MEDIUM}
    modalId={ModalFormId.CREATE_CUSTOM_SERVICE}
  />
)

const ServiceRoleCheckbox = ({
  role,
  modelId,
  isDefault,
}: {
  role: ServiceRoleLabels
  modelId: string
  isDefault: boolean
}): JSX.Element => {
  const { getSelectedRoles, getOnChange } = useServicesContext()
  const selectedRoles = getSelectedRoles(modelId, isDefault)
  const isRoleChecked = selectedRoles.has(role)
  const onServiceRoleChange = getOnChange(modelId)

  return (
    <CheckBoxInput
      key={role}
      value={role}
      id={`service-roles-checkboxes-${role}`}
      label={role}
      checked={isRoleChecked}
      onChange={onServiceRoleChange}
    />
  )
}

export const ServiceRoleCheckBoxes = ({
  modelId,
}: {
  modelId: string
}): JSX.Element => {
  const { getCurrentService, getSelectedRoles } = useServicesContext()
  const service = getCurrentService(modelId)

  if (!service) {
    return <></>
  }

  const defaultRoles = service.default_roles || []
  const selectedNonDefaultRoles = getSelectedRoles(modelId, false)
  const sortedNonDefaultRoles = Array.from(selectedNonDefaultRoles).sort(
    (a, b) => a.localeCompare(b),
  )

  return (
    <>
      {Object.values(defaultRoles).map((role: SERVICE_ROLE) => (
        <ServiceRoleCheckbox
          key={SERVICE_ROLE_TO_LABEL[role]}
          role={SERVICE_ROLE_TO_LABEL[role]}
          modelId={modelId}
          isDefault
        />
      ))}
      {sortedNonDefaultRoles.map((role: ServiceRoleLabels) => (
        <ServiceRoleCheckbox
          key={role}
          role={role}
          modelId={modelId}
          isDefault={false}
        />
      ))}
    </>
  )
}

export const ServiceRoleOptions = ({
  modelId,
}: {
  modelId: string
}): JSX.Element => (
  <AddServiceRolesOptions>
    <ServiceRoleCheckBoxes modelId={modelId} />
    <AddMoreServiceRoles modelId={modelId} />
  </AddServiceRolesOptions>
)

export const AddServiceRolesItem = ({
  service,
}: {
  service: Service
}): JSX.Element => (
  <AddServiceRolesContainer>
    <AddServiceRolesHeader>
      <service.logo width="36px" height="36px" />
      {service.name}
    </AddServiceRolesHeader>
    <AddServiceRolesOptionsContainer>
      <ServiceRoleOptions modelId={service.modelid} />
    </AddServiceRolesOptionsContainer>
  </AddServiceRolesContainer>
)

export const AddServiceRolesSubmitButton = ({
  onSubmit,
}: {
  onSubmit: MouseEventHandler<HTMLElement>
}): JSX.Element => (
  <StandardButton
    type="submit"
    form={ModalFormId.ADD_SERVICE_ROLES}
    variant={StandardButtonVariant.PRIMARY}
    buttonSize={StandardButtonSize.MEDIUM}
    onClick={onSubmit}
  >
    Confirm
  </StandardButton>
)

export const AddMoreServiceRoles = ({
  modelId,
}: {
  modelId: string
}): JSX.Element => {
  const [isDropdown, setIsDropdown] = useState(false)
  const { getCurrentService, getOnChange, getDropdownRoles } =
    useServicesContext()
  const service = getCurrentService(modelId)

  if (!service) {
    return <></>
  }

  const onRoleSelected = getOnChange(modelId)
  const dropdownRoles = getDropdownRoles(modelId)
  const defaultRoles = service.default_roles || []
  const showDropdown = isDropdown || !defaultRoles.length

  return showDropdown ? (
    <ServiceRolesDropdown
      dropdownRoles={dropdownRoles}
      onRoleSelected={onRoleSelected}
      defaultText="Select a Service Role"
    />
  ) : (
    <AddMoreServiceRolesContainer>
      <AddMoreServiceRolesIcon />
      <TextButton onClick={() => setIsDropdown(true)}>
        Optionally, add another way you use it
      </TextButton>
    </AddMoreServiceRolesContainer>
  )
}

export const OldServicesRootPage = (): JSX.Element => {
  const content = useContent()

  return !content.loaded ? (
    <ContentLoader />
  ) : (
    <PageContainer>
      <PageHeader
        title="Services and Receptors"
        subHeader={<ServicesSubNav />}
      />
      <ScopingContent>
        <Routes>
          <Route
            path={`${ServiceSubRoutes.RECEPTORS}/*`}
            element={<ReceptorsPage />}
          />
          <Route
            path={`${ServiceSubRoutes.SERVICES}`}
            element={<ServicesPage />}
          />
          <Route
            path="*"
            element={<Navigate replace to={`${ServiceSubRoutes.RECEPTORS}`} />}
          />
        </Routes>
      </ScopingContent>
    </PageContainer>
  )
}

export const AddServiceGridItems = ({
  services,
  onChange,
}: {
  services: Service[]
  onChange: ChangeEventHandler<HTMLInputElement>
}): JSX.Element => (
  <>
    {services.map((service) => (
      <AddServicesRowItem
        key={service.id}
        service={service}
        onChange={onChange}
      />
    ))}
  </>
)

export const ServicesPrevNext = (): JSX.Element => {
  const location = useLocation()
  const request = useServicesRequest()
  const { data, isLoading, error } = useServices(request)

  if (isLoading) {
    return <Spinner size="s" color="primary" />
  } else if (error) {
    log.error('Error fetching services in the PrevNext component', error)
    return <></>
  } else if (!data) {
    return <></>
  }

  const services = data.getServicesList()
  const activeServices = getActiveServices(services, location.search)
  const serviceIds = activeServices.map((service) => service.id)

  return (
    <PrevNextContainer>
      <PrevNextButtons
        baseRoute={BASE_ROUTES.SERVICES}
        idArray={serviceIds}
        param={ROOT_ID_PARAM_TYPE.SERVICE_ID}
      />
    </PrevNextContainer>
  )
}

export const ServiceRoleTooltip = (): JSX.Element => (
  <Tooltip
    id="services-show-role-tooltip"
    placement={TooltipPositions.right}
    overlayType={TooltipOverlayType.popover}
    tooltipBody={`Knowing how you use a service impacts how auditors will look at it, whether you'll need evidence about it, and how it relates to risks.`}
  >
    <InfoButton as="div" ml="xxxs" />
  </Tooltip>
)

export const ReceptorTooltip = (): JSX.Element => (
  <Tooltip
    id="services-show-receptor-tooltip"
    placement={TooltipPositions.right}
    overlayType={TooltipOverlayType.popover}
    tooltipBody={`Receptors discover services and add them to your service list. You mark how they're used or if they should be excluded.`}
  >
    <InfoButton as="div" ml="xxxs" />
  </Tooltip>
)

export const ReceptorSection = (): JSX.Element => {
  const { service } = useServicesContext()
  const receptorId = service?.receptor_id || ''
  const { openModal } = useModalState()
  const { pageContext } = useParams()
  const { data, isLoading, error } = useReceptors(receptorId, !!receptorId)

  if (isLoading) {
    return <Spinner size="m" color="primary" />
  } else if (error) {
    showInfoToast(
      'Unable to load receptors for service. Please refresh and try again.',
    )
    log.error(
      `Error fetching receptors on services show page for receptor id ${receptorId}`,
      error,
    )
    return <></>
  } else if (!data || !service) {
    return <></>
  }
  const receptorRecords = data.getReceptorsList()
  const receptors = getActiveReceptors(receptorRecords)
  const isLongList = receptors.length > RECEPTORS_SECTION_MAX_LENGTH
  const listedReceptors = isLongList
    ? receptors.slice(0, RECEPTORS_SECTION_MAX_LENGTH)
    : receptors

  const { name } = service

  return (
    <>
      {listedReceptors.length > 0 && (
        <>
          <StyledSectionContainer>
            <StyledSectionHeader>
              Discovered By Receptor
              <ReceptorTooltip />
            </StyledSectionHeader>
            <ReceptorListContainer>
              {listedReceptors.map((receptor) => (
                <ReceptorInfoContainer key={receptor.id}>
                  <ReceptorNameContainer>
                    <receptor.logo height="20px" width="20px" />
                    {receptor.name} - {receptor.tenantid}
                  </ReceptorNameContainer>
                  <ViewReceptorButton
                    onClick={() => {
                      openInNewTab(
                        `${window.location.origin}/${pageContext}/${ReceptorsAbsoluteRoutes.SHOW}/${receptor.id}`,
                      )
                    }}
                    requiredPermissions={[PERMISSIONS.READ]}
                  >
                    <>
                      View Receptor
                      <ViewReceptorIcon />
                    </>
                  </ViewReceptorButton>
                </ReceptorInfoContainer>
              ))}
            </ReceptorListContainer>
            {isLongList && (
              <ViewReceptorButton
                onClick={() => openModal(ModalFormId.VIEW_RECEPTORS)}
              >
                View All
              </ViewReceptorButton>
            )}
          </StyledSectionContainer>
        </>
      )}
      <ViewReceptorsModal serviceName={name} receptors={receptors} />
    </>
  )
}

export const ProviderSection = (): JSX.Element => {
  const { service } = useServicesContext()

  if (!service) {
    return <></>
  }

  const { provider } = service
  const providerLabel = provider ? PROVIDER_TO_LABEL[provider] : ''

  return providerLabel ? (
    <StyledSectionContainer>
      <StyledSectionHeader>Provider</StyledSectionHeader>
      {providerLabel}
    </StyledSectionContainer>
  ) : (
    <></>
  )
}

export const ServiceInstancesGridHeader = ({
  headers,
}: {
  headers: string[]
}): JSX.Element => (
  <GridRow>
    {headers.map((header: string) => (
      <InstancesGridHeader key={header}>{header}</InstancesGridHeader>
    ))}
  </GridRow>
)

export const ServiceInstancesGridRow = ({
  instance,
  showServiceAccountColumn,
}: {
  instance: Instance
  showServiceAccountColumn: boolean
}): JSX.Element => {
  const setDismissedInstance = useSetDismissedInstance()
  const [isDismissed, setIsDismissed] = useState<boolean>(false)

  useEffect(() => {
    setIsDismissed(instance.getDismissed())
  }, [instance])

  const instanceId = instance.getId()

  const updateServiceInstance = useMemo(
    () =>
      debounce(
        async ({
          instanceId,
          isDismissed,
        }: {
          instanceId: string
          isDismissed: boolean
        }) => {
          try {
            await setDismissedInstance({
              instanceId,
              isDismissed,
            })
          } catch (e) {
            log.error(
              `Error updating dismissed status for service instance with id ${instanceId}`,
              e,
            )
          }
        },
        UPDATE_SERVICE_DELAY,
      ),
    [setDismissedInstance],
  )

  const onSetDismissedInstance: ChangeEventHandler<HTMLInputElement> = async (
    e,
  ) => {
    try {
      const isDismissed = e.target.value === 'true'
      setIsDismissed(!isDismissed)
      await updateServiceInstance({
        instanceId,
        isDismissed: !isDismissed,
      })
    } catch (e) {
      log.error(
        `Error updating dismissed status for service instance with id ${instanceId}`,
        e,
      )
    }
  }

  return (
    <GridRow>
      <InstancesGridColumn>{instance.getName()}</InstancesGridColumn>
      <InstancesGridColumn>
        {instance.getReceptorNickname()}
      </InstancesGridColumn>
      {showServiceAccountColumn && (
        <InstancesGridColumn>
          {instance.getServiceAccountId() || 'N/A'}
        </InstancesGridColumn>
      )}
      <CenteredGridColumn>
        <CheckBoxInput
          key={instanceId}
          value={`${isDismissed}`}
          id={`service-instances-checkboxes-${instanceId}`}
          checked={!isDismissed}
          onChange={onSetDismissedInstance}
        />
      </CenteredGridColumn>
    </GridRow>
  )
}

export const ServiceInstancesGridRows = ({
  instances,
  showServiceAccountColumn,
}: {
  instances: Instance[]
  showServiceAccountColumn: boolean
}): JSX.Element => (
  <>
    {instances.map((instance: Instance) => (
      <ServiceInstancesGridRow
        key={instance.getId()}
        instance={instance}
        showServiceAccountColumn={showServiceAccountColumn}
      />
    ))}
  </>
)

export const ServiceInstancesSection = (): JSX.Element => {
  const [bulkOpLoading, setBulkOpLoading] = useState(false)
  const { service } = useServicesContext()
  const modelId = service?.modelid || ''
  const { data, isLoading, error } = useInstancesForService({
    serviceId: modelId,
  })
  const setDismissedInstances = useSetDismissedInstanceByService()

  if (isLoading) {
    return <ContentLoader />
  } else if (error) {
    log.error(
      `Error fetching service instances for service model id ${modelId}`,
      error,
    )
    return <></>
  } else if (!data) {
    return <></>
  }

  const instances = data.getInstancesList()
  const hasInstances = instances.length > 0

  const isServiceAccountEnabled = IsGatekeeperEnabled(
    GatekeeperFlag.SERVICE_ACCOUNTS,
  )
  const receptorModelId = service?.receptor_id || ''
  const hasServiceAccounts =
    SERVICE_ACCOUNT_RECEPTOR_MODEL_IDS.includes(receptorModelId)
  const showServiceAccountColumn = isServiceAccountEnabled && hasServiceAccounts
  const gridTemplateColumns = `repeat(${
    showServiceAccountColumn ? '3' : '2'
  }, 1fr) max-content`
  const headerValues = Object.values(SERVICE_INTANCES_HEADERS)
  const headers = showServiceAccountColumn
    ? headerValues
    : headerValues.filter(
        (header) => header !== SERVICE_INTANCES_HEADERS.SERVICE_ACCOUNT,
      )

  const areAllEnabled = instances.every((ele) => !ele.getDismissed())
  const areAllDismissed = instances.every((ele) => ele.getDismissed())

  const handleBulkEnable = async (isEnable: boolean) => {
    setBulkOpLoading(true)

    try {
      await setDismissedInstances({
        serviceId: modelId,
        isDismissed: !isEnable,
      })
    } catch (e) {
      log.error(
        `Error updating dismissed status for service instances with service id ${modelId}`,
        e,
      )
    }

    setBulkOpLoading(false)
  }

  return hasInstances ? (
    <StyledSectionContainer>
      <FlexRow justify={FlexAlign.SPACE_BETWEEN} $mb={5}>
        <StyledSectionHeader>Service Instances</StyledSectionHeader>
        <FlexRow gap={5}>
          <QuestionnaireSecondaryButtonSmall
            text="Include All"
            isDisabled={areAllEnabled}
            onClickFunc={() => handleBulkEnable(true)}
          />
          <QuestionnaireSecondaryButtonSmall
            text="Exclude All"
            isDisabled={areAllDismissed}
            onClickFunc={() => handleBulkEnable(false)}
          />
        </FlexRow>
      </FlexRow>
      <Grid gridTemplateColumns={gridTemplateColumns}>
        <ServiceInstancesGridHeader headers={headers} />
        {!bulkOpLoading && (
          <ServiceInstancesGridRows
            instances={instances}
            showServiceAccountColumn={showServiceAccountColumn}
          />
        )}
      </Grid>
    </StyledSectionContainer>
  ) : (
    <></>
  )
}

export const ServiceRolesSection = (): JSX.Element => {
  const { service } = useServicesContext()

  if (!service) {
    return <></>
  }

  const { modelid } = service

  return (
    <StyledSectionContainer>
      <StyledSectionHeader>
        How You Use It
        <ServiceRoleTooltip />
      </StyledSectionHeader>
      <ServiceRoleOptions modelId={modelid} />
    </StyledSectionContainer>
  )
}

export const ConfirmExcludeServiceModalBody = ({
  service,
}: {
  service: Service
}): JSX.Element => {
  const { data, isLoading, error } = useEvidenceGenerators(
    MODEL_TYPE.SERVICE,
    service.modelid,
    true,
  )
  if (isLoading) {
    return <Spinner size="m" color="primary" />
  } else if (error) {
    log.error(
      `Error fetching evidence generators for service model id ${service.modelid}`,
      error,
    )
    return <></>
  } else if (!data) {
    return <></>
  }

  const evidenceGenerators = data.getItemsList()

  return (
    <ExcludeServiceModalContainer>
      This service will no longer be available as a choice in the scoping page
      and it will be removed from answers you may have provided there.
      {evidenceGenerators.length > 0 && (
        <div>
          The following automated evidence will no longer be posted to controls:
        </div>
      )}
      <EvidenceGeneratorGrid evidenceGenerators={evidenceGenerators} />
    </ExcludeServiceModalContainer>
  )
}

export const ConfirmIncludeServiceModalBody = ({
  service,
}: {
  service: Service
}): JSX.Element => {
  const { data, isLoading, error } = useEvidenceGenerators(
    MODEL_TYPE.SERVICE,
    service.modelid,
    false,
  )
  if (isLoading) {
    return <Spinner size="m" color="primary" />
  } else if (error) {
    log.error(
      `Error fetching evidence generators for service model id ${service.modelid}`,
      error,
    )
    return <></>
  } else if (!data) {
    return <></>
  }

  const evidenceGenerators = data.getItemsList()

  return (
    <ExcludeServiceModalContainer>
      {evidenceGenerators.length > 0 && (
        <div>
          The following automated evidence will resume posting to controls:
        </div>
      )}
      <EvidenceGeneratorGrid evidenceGenerators={evidenceGenerators} />
    </ExcludeServiceModalContainer>
  )
}

export const ScopingServiceRow = ({
  row,
  index,
  onUpdate,
  onDelete,
}: {
  row: CustomServiceRoleRow
  index: number
  onUpdate: (idx: number, name: string) => void
  onDelete: (idx: number) => void
}): JSX.Element => (
  <ServiceRowContainer key={`custom-service-${index}`}>
    <ServiceRow align={FlexAlign.CENTER} justify={FlexAlign.CENTER}>
      <TextInput
        initVal={row.name}
        name="serviceName"
        label=""
        placeholder="Add a service name"
        onChangeCb={(newName) => onUpdate(index, newName)}
      />
      <ButtonDiv>
        <DeleteButton onClick={() => onDelete(index)} />
      </ButtonDiv>
    </ServiceRow>
    <StyledInputError showError={!!row.errorMessage}>
      {row.errorMessage}
    </StyledInputError>
  </ServiceRowContainer>
)
