import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Range } from 'react-date-range'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import styled from 'styled-components/macro'
import {
  AutomatedEvidence,
  SuggestedAutomatedEvidence,
} from '@trustero/trustero-api-web/lib/recommend/recommend_pb'
import { SWRResponse } from 'swr'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { ToggleWatchingButton } from 'src/components/Reusable/NotificationComponents/notification.components'
import {
  useAddSubscription,
  useGetSubscriptionBySubjectId,
  useRemoveSubscription,
} from 'src/global.hooks/notification.hooks'
import { ShowPageHeader } from 'src/components/PageLayout/ShowPage/showpage.components'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useCurrentAudit } from '../../../components/async/model/audit'
import {
  RouteTitle,
  ShowPageBackArrow,
  ShowPageContainer,
  ShowPageHeaderButtons,
  ShowPageHeaderContainer,
  ShowPageHeaderTitles,
  ShowPageItem,
  ShowPageItemBody,
} from '../../../components/PageLayout/ShowPage'
import {
  ChangelogButton,
  PermalinkButton,
  StandardButtonSize,
} from '../../../components/Reusable/Buttons'
import { Tabs } from '../../../components/Reusable/Tabs'
import { ShowLatestDateFilter } from '../../../components/Reusable/TrusteroDateRange/ShowLatestDateFilter'
import { RequestsAbsoluteRoutes } from '../RequestsRootPage'
import { EvidenceTab } from '../../../components/Reusable/Tabs/EvidenceTab'
import { ModalFormId } from '../../../components/ModalForms'
import {
  TabNoteContent,
  TabPanelButtonGroup,
  TabPanelNoteItem,
} from '../../../components/Reusable/Tabs/TabNotes/styles'
import { AddEvidenceForm } from '../../../components/ModalForms/Evidence/AddEvidence'
import { RequestsItem } from '../components'
import { Grid } from '../../../components/Reusable/Grid'
import palette from '../../../designSystem/variables/palette'
import formatDate from '../../../Utils/formatDate'
import { ControlsIndexGrid } from '../../Controls/ControlsIndexPage/ControlsIndexGrid'
import { AddControlsToRequestModal } from '../../Controls/ControlsIndexPage/AddControl/AddControlsToRequest/AddControlsToRequestModal'
import { generatePermalinkFromCurrentLocation } from '../../../components/PageLayout/Permalink'
import { MarkupSection } from '../../../components/Reusable/Text/Markup'
import { AddSuggestedAutomatedDLREvidenceButton } from '../../../components/Reusable/Tabs/TabNotes/AddSuggestedAutomatedDLREvidenceButton'
import { useSuggestedDLRReceptor } from '../../../components/async/suggestions/useSuggestedReceptor'
import { Gatekeeper, GatekeeperFlag } from '../../../context/Gatekeeper'
import { Spinner } from '../../../Throbber'
import {
  SuggestedEvidenceButtons,
  SuggestedEvidenceContainer,
  SuggestedEvidenceEroIcon,
} from '../requests.styles'
import { useRequestControlIds } from './RequestShowPage.utils'

const StyledP = styled.p`
  color: ${palette.neutral['600']};
  font-size: 14px;
`

const RequestComponentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  gap: 16px;
  width: 100%;
`

const FlexShowPageHeaderButtons = styled(ShowPageHeaderButtons)`
  height: 100%;
  display: flex;
  align-items: center;
`

interface AddButtonProps {
  modalId: ModalFormId
  modalComponent: JSX.Element
  text: string
  requiredPermissions?: PERMISSIONS[]
}

type SuggestedComponentProps = {
  requestId: string
  suggestedResponse: SWRResponse<SuggestedAutomatedEvidence>
  suggestedAutomatedEvidence: AutomatedEvidence[]
  suggestedHeader: string
}

const EvidenceHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`

const SUGGESTED_EVIDENCE_HEADER = 'Suggested Evidence for this Request'

export const SuggestedComponent = ({
  requestId,
  suggestedResponse,
  suggestedAutomatedEvidence,
  suggestedHeader,
}: SuggestedComponentProps): JSX.Element => {
  return suggestedResponse.isValidating ||
    suggestedAutomatedEvidence.length === 0 ? (
    <></>
  ) : (
    <Gatekeeper authorizedFlags={[GatekeeperFlag.SUGGESTED_DLR_EVIDENCE]}>
      <TabNoteContent>
        <MarkupSection>
          <h2>{suggestedHeader}</h2>
        </MarkupSection>
        <SuggestedEvidenceContainer>
          <SuggestedEvidenceEroIcon />
          <SuggestedEvidenceButtons>
            {suggestedAutomatedEvidence.map((evidence) => (
              <AddSuggestedAutomatedDLREvidenceButton
                key={evidence.getDocumentid()}
                caption={evidence.getEvidencename()}
                requestId={requestId}
                documentId={evidence.getDocumentid()}
                contentId={evidence.getContentid()}
                mime={evidence.getMime()}
              />
            ))}
          </SuggestedEvidenceButtons>
        </SuggestedEvidenceContainer>
      </TabNoteContent>
    </Gatekeeper>
  )
}

const AddButton = ({
  modalId,
  modalComponent,
  text,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
}: AddButtonProps): JSX.Element => {
  return (
    <TabPanelNoteItem>
      <TabPanelButtonGroup>
        {modalComponent}
        <StandardOpenModalButton
          modalId={modalId}
          size={StandardButtonSize.SMALL}
          text={text}
          requiredPermissions={requiredPermissions}
        />
      </TabPanelButtonGroup>
    </TabPanelNoteItem>
  )
}

export const RequestShowPage = ({
  request,
}: {
  request: DocumentRequest
}): JSX.Element => {
  const [isWatching, setIsWatching] = useState<boolean>(false)
  const { data: audit, isLoading } = useCurrentAudit()
  const requestId = request.getId()
  const controlIdsArray = useRequestControlIds(requestId)
  const permalinkUrl = generatePermalinkFromCurrentLocation()
  const suggestedAutomatedEvidenceResponse = useSuggestedDLRReceptor(requestId)
  const suggestedAutomatedEvidence =
    suggestedAutomatedEvidenceResponse.data?.getSuggestionsList() || []
  const [showLatest, setShowLatest] = useState(true),
    [evidenceCount, setEvidenceCount] = useState(0),
    [selectedTab, setSelectedTab] = useState(0),
    [dateRange, setDateRange] = useState<Range>({
      startDate: undefined,
      endDate: undefined,
    })
  const createdAt = formatDate(request.getCreatedAt()?.toDate())
  const updatedAt = formatDate(request.getUpdatedAt()?.toDate())
  const addSubscription = useAddSubscription()
  const removeSubscription = useRemoveSubscription()
  const { data: subscriptionData } = useGetSubscriptionBySubjectId({
    subjectId: requestId,
  })

  useEffect(() => {
    setIsWatching(!!subscriptionData?.getId())
  }, [subscriptionData])

  const timeRange = useMemo(() => {
    const innerTimeRange = new TimeRange()
    dateRange.startDate &&
      innerTimeRange.setSince(Timestamp.fromDate(dateRange.startDate))
    dateRange.endDate &&
      innerTimeRange.setUntil(Timestamp.fromDate(dateRange.endDate))
    return innerTimeRange
  }, [dateRange])

  const onSelect = (e: string | null): void => setSelectedTab(Number(e))

  useEffect(() => {
    setDateRange({
      startDate: audit?.getStartDate()?.toDate(),
      endDate: audit?.getEndDate()?.toDate(),
    })
  }, [audit])

  const evidenceTabProps = {
    showLatest,
    setEvidenceCount,
    requestId,
    isDismissed: false,
    timeRange,
  }

  const addEvidenceButtonProps = {
    modalId: ModalFormId.ADD_EVIDENCE,
    modalComponent: <AddEvidenceForm requestId={requestId} name="Request" />,
    text: 'Add Evidence',
  }
  const addControlButtonProps = {
    modalId: ModalFormId.ADD_CONTROLS_TO_REQUEST,
    modalComponent: (
      <AddControlsToRequestModal
        requestId={requestId}
        existingControlIds={controlIdsArray}
      />
    ),
    text: 'Link to Controls',
    requiredPermissions: [PERMISSIONS.AUDITOR_ACTION],
  }

  const tabsArray = [
    {
      title: 'Evidence',
      count: evidenceCount,
      body: <EvidenceTab {...evidenceTabProps} />,
      note: (
        <EvidenceHeaderContainer>
          <SuggestedComponent
            requestId={requestId}
            suggestedResponse={suggestedAutomatedEvidenceResponse}
            suggestedHeader={SUGGESTED_EVIDENCE_HEADER}
            suggestedAutomatedEvidence={suggestedAutomatedEvidence}
          />
          <AddButton {...addEvidenceButtonProps} />
        </EvidenceHeaderContainer>
      ),
    },
    {
      title: 'Controls',
      count: controlIdsArray.length,
      body: <ControlsIndexGrid controlIds={controlIdsArray} audit={audit} />,
      note: <AddButton {...addControlButtonProps} />,
    },
  ]

  const toggleIsWatching = async (): Promise<void> => {
    if (isWatching) {
      await removeSubscription({
        subscriptionId: subscriptionData?.getId() || '',
      })
    } else {
      await addSubscription({ subjectId: requestId })
    }
  }

  return isLoading ? (
    <Spinner size="l" color="primary" />
  ) : (
    <ShowPageContainer>
      <ShowPageHeaderContainer>
        <ShowPageHeader>
          <ShowPageHeaderTitles>
            <RouteTitle>
              <Link to={`../../${RequestsAbsoluteRoutes.INDEX}`}>
                <ShowPageBackArrow icon={faArrowLeft} />
                Requests
              </Link>
            </RouteTitle>
            <RequestComponentContainer>
              <Grid
                gridTemplateColumns="max-content 1fr repeat(3, auto)"
                gridRowGap="8px"
                width={'100%'}
              >
                <RequestsItem fromShowPage request={request} />
              </Grid>
              <FlexShowPageHeaderButtons>
                <ChangelogButton subjectModelIds={[requestId]} />
                <PermalinkButton permalinkUrl={permalinkUrl} />
                <ToggleWatchingButton
                  isWatching={isWatching}
                  toggleWatching={toggleIsWatching}
                />
              </FlexShowPageHeaderButtons>
            </RequestComponentContainer>
            <StyledP>Created: {`${createdAt}`}</StyledP>
            <StyledP>Last Updated: {`${updatedAt}`}</StyledP>
          </ShowPageHeaderTitles>
        </ShowPageHeader>
      </ShowPageHeaderContainer>
      <ShowPageItem>
        {selectedTab === 0 && (
          <ShowLatestDateFilter
            showLatest={showLatest}
            setShowLatest={setShowLatest}
            currentAudit={audit?.getId()}
            dateRange={dateRange}
            setDateRange={setDateRange}
            tooltipBody="Show the latest evidence gathered organized by their respective captions (within the audit period)."
          />
        )}
        <ShowPageItemBody>
          <Tabs tabs={tabsArray} onSelect={onSelect} />
        </ShowPageItemBody>
      </ShowPageItem>
    </ShowPageContainer>
  )
}
